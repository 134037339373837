import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import SoftBadge from "../../../../../components/common/SoftBadge";
import React, { useState } from "react";

import {
  Button,
  Card,
  CloseButton,
  Col,
  Row,
  Table,
  Form,
  Dropdown,
} from "react-bootstrap";
import CardDropdown from "../../../../../components/common/CardDropdown";

import { useNavigate, useParams } from "react-router-dom";
import MailDetailsModal from "./MailDetailsModal";
import Loader from "../../../../../components/common/Loader";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName, formatLogInfo } from "../../../../../helpers/utils";
import { handleCreatePDF } from "../../../../../helpers/utils-pdf";
import CustomFilterbar from "../../../../../components/common/CustomFilterbar";
import CustomSearchbar from "../../../../../components/common/CustomSearchbar";

import { Avatar } from "@mui/material";
import { startCase } from "lodash";

export default function MailOutDetail() {
  let hostname = getHostName();
  const navigate = useNavigate();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, mail_id, property_name } = useParams();

  const [budgetsData, setBudgetsData] = React.useState([]);

  const [mailDetailObj, setMailDetailObj] = React.useState({});

  const [companyLetterHead, setCompanyLetterHead] = useState("");
  const [propertyLetterHead, setPropertyLetterHead] = useState("");

  const [mailData, setMailData] = React.useState([]);
  const [mailDataTmp, setMailDataTmp] = React.useState([]);

  const [updatedLetterHead, setUpdatedLetterHead] = useState("");

  const [bulkMails, setBulkMails] = React.useState([]);

  const [show, setShow] = React.useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [loader, setLoader] = useState(false);

  const [payload, setPayload] = React.useState({});

  React.useEffect(() => {
    getAllDataOfSingleMail();
    getAllDataOfSingleMailData();
    //getBudgetsData();
    getCompanyLetterHead();
    getPropertyLetterHead();
  }, []);

  const getBudgetsData = (budget_id = "") => {
    axios
      .post(
        `${hostname}/api/property/ReadAllBudgets`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let arr = res.data.Data?.map((v) => {
          let obj = { ...v };
          obj.start_of_fiscal_year = formatLogInfo(
            obj.start_of_fiscal_year,
            true
          );
          obj.end_of_fiscal_year = formatLogInfo(obj.end_of_fiscal_year, true);
          return obj;
        });

        //let budget_id = mailDetailObj.budget_id;

        console.log(budget_id);

        arr = arr.filter((v) => v._id === budget_id);
        console.log(arr);
        setBudgetsData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDataOfSingleMail = () => {
    setLoader(true);

    axios
      .post(
        `${hostname}/api/property/GetMailoutStatus`,
        {
          mailout_id: mail_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let arr = [...res.data.Data];

        setMailData(arr);
        setMailDataTmp(arr);
        setUpdatedLetterHead(res.data.letterhead);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDataOfSingleMailData = () => {
    // setLoader(true);

    axios
      .post(
        `${hostname}/api/property/GetMailoutInfo`,
        {
          mailout_id: mail_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let obj = res.data.Data;

        if (obj.status === "In Draft")
          obj.status = { type: "info", icon: "redo", title: "Draft" };
        if (obj.status === "Sent")
          obj.status = { type: "success", icon: "check", title: "Sent" };
        if (obj.status === "Not Sent")
          obj.status = { type: "danger", icon: "ban", title: "Not Sent" };

        getBudgetsData(obj.budget_id);

        console.log(obj);
        

        setMailDetailObj(obj);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyLetterHead = () => {
    axios
      .post(
        `${hostname}/api/property/GetLetterHeadCompany`,
        {
          c_id: userData.compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setCompanyLetterHead(res.data.Data || "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPropertyLetterHead = () => {
    axios
      .post(
        `${hostname}/api/property/GetLetterHead`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setPropertyLetterHead(res.data.Data || "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ###################################################

  const addBulkAction = (v) => {
    console.log(v);
    if (bulkMails.includes(v)) {
      let state = bulkMails?.filter((j) => j !== v);
      setBulkMails(state);
    } else {
      setBulkMails(bulkMails.concat(v));
    }
  };

  const applyBulkAction = async (actionType = "") => {
    //setLoader(true);

    console.log(bulkMails);

    if (bulkMails.length > 0) {
      console.log("call api here..");
      let arr = mailData?.filter((v) => bulkMails.includes(v._id));

      console.log(arr);
      // return;

      if (actionType === "mail") {
        let mails = [];
        arr?.forEach((v, i) => {
          console.log(v);
          let obj = {};
          obj.pdf_content = v.mailout_id.mail_content;
          obj.file_name = "sample name-" + i;

          mails.push(obj);
        });

        console.log(mails);

        await handleCreatePDF(
          "BulkPDF",
          "download",
          "",
          "",
          mails,
          property_name || "property"
        );

        return;
      }
      if (actionType === "letter") {
        let letters = [];
        arr?.forEach((v, i) => {
          console.log(v);
          let obj = {};
          obj.pdf_content = v.mailout_id.letter_content;
          obj.file_name = "sample letter name-" + i;

          letters.push(obj);
        });

        console.log(letters);

        console.log(updatedLetterHead);
        

        if (updatedLetterHead === "")
          await handleCreatePDF(
            "BulkPDF",
            "download",
            "",
            "",
            letters,
            property_name || "property"
          );

        if (updatedLetterHead !== "")
          await handleCreatePDF(
            "BulkPDFWithLetterhead",
            "download",
            "",
            updatedLetterHead,
            letters,
            property_name || "property"
          );

        return;
      }
      if (actionType === "delete") {
        console.log("delete");
        let arr = [];
        let arrToDelete = [];
        arr = mailData?.filter((v) => !bulkMails.includes(v.id));
        arrToDelete = mailData?.filter((v) => bulkMails.includes(v.id));
        console.log(arr);
        setLoader(false);

        // arrToDelete?.forEach((v) => {
        //   callGivenOption("delete", true, v);
        // });
      }
      setBulkMails([]);
    } else {
      console.log("no files to convert");
    }
  };

  const previewAndPrint = async (type = "", htmlContent) => {
    console.log(type);

    if (type === "mail") {
      await handleCreatePDF("singlePDF", "previewAndPrint", htmlContent);

      return;
    }
    if (type === "letter" && !updatedLetterHead) {
      await handleCreatePDF("singlePDF", "previewAndPrint", htmlContent);
      return;
    }
    if (type === "letter" && updatedLetterHead) {
      await handleCreatePDF(
        "singlePDFWithLetterhead",
        "previewAndPrint",
        htmlContent,
        updatedLetterHead
      );

      return;
    }
  };

  const previewMail = () => {
    let obj = {};
    obj.mail_content = mailDetailObj.mail_content;
    obj.loadType = "Preview Mail";
    setPayload(obj);
    handleShow();
  };

  const previewLetter = () => {
    let obj = {};
    obj.letter_content = mailDetailObj.letter_content;
    obj.loadType = "Preview Letter";
    setPayload(obj);
    handleShow();
  };

  // ###################################################

  if (loader) return <Loader />;
  return (
    <div>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center">
              <h5>{mailDetailObj?.mail_subject}</h5>
              <CloseButton onClick={() => navigate(-1)} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={7}>
          <Card>
            <FalconCardHeader title="Mail Details" />
            <Card.Body style={{ minHeight: "350px" }} className="bg-light">
              <Table>
                <tbody>
                  <tr>
                    <td className=" fw-bold">Mail Type:</td>
                    <td>{mailDetailObj?.mail_type}</td>
                  </tr>
                  {mailDetailObj?.mail_type === "Budget Letter" && (
                    <tr>
                      <td className=" fw-bold">Budget Year:</td>
                      <td>
                        {budgetsData[0]?.start_of_fiscal_year} -{" "}
                        {budgetsData[0]?.end_of_fiscal_year}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className=" fw-bold">Sent To:</td>
                    <td>
                      {mailDetailObj?.groups?.map((v, i) => (
                        <span
                          style={{
                            fontSize:
                              mailDetailObj?.groups?.length > 1 ? "14px" : "",
                          }}
                          key={v}
                        >
                          {v}
                          {mailDetailObj?.groups?.length > 1 && i === 0
                            ? ", "
                            : ""}
                        </span>
                      ))}
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="fw-bold">Total Emails Delivered:</td>
                    <td>
                      {loader && (
                        <div className="d-flex align-items-end">
                          <Loader noHeight />
                          <span className="ms-3">loading...</span>
                        </div>
                      )}
                      {!loader && (
                        <Button
                          className="p-0"
                          style={{ textDecoration: "underline" }}
                          variant="link"
                          onClick={showMailDetailsTable}
                        >
                          View Details
                        </Button>
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td className=" fw-bold">Status:</td>
                    <td>
                      <SoftBadge pill bg={mailDetailObj?.status?.type}>
                        {mailDetailObj?.status?.title}
                        <FontAwesomeIcon
                          icon={mailDetailObj?.status?.icon}
                          className="ms-2"
                        />
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td className=" fw-bold">Created On:</td>
                    <td>{formatLogInfo(mailDetailObj?.createdAt)}</td>
                  </tr>
                  <tr>
                    <td className=" fw-bold">Letter Drafted:</td>
                    <td>{mailDetailObj?.draft_letter ? "Yes" : "No"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Card>
            <FalconCardHeader title="Print Paper Copies" />
            <Card.Body style={{ minHeight: "350px" }} className="bg-light">
              <br />
              <br />
              <br />

              <div className="d-flex justify-content-center align-items-center flex-column">
                {mailDetailObj?.mail_content && (
                  <Button variant="falcon-primary" onClick={previewMail}>
                    Preview Mail
                  </Button>
                )}
                <br />
                <br />
                {mailDetailObj?.draft_letter && (
                  <Button variant="falcon-primary" onClick={previewLetter}>
                    Preview Letter
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <FalconCardHeader title="Emails Delivered" />

            <Card.Body style={{ minHeight: "500px" }} className="bg-light">
              <div>
                <Row className="flex-end-center mb-1">
                  <Col className=" d-flex justify-content-sm-end">
                    {bulkMails.length > 1 && (
                      <div className="me-3 hide-pdf-div">
                        <Button
                          variant="falcon-success"
                          onClick={() => applyBulkAction("mail")}
                          // disabled={bulkMails.length === 1}
                        >
                          Download Mail Copies
                        </Button>
                        {mailDetailObj.draft_letter && (
                          <Button
                            variant="falcon-success"
                            onClick={() => applyBulkAction("letter")}
                            // disabled={bulkMails.length === 1}
                            className="ms-3"
                          >
                            Download Letter Copies
                          </Button>
                        )}
                      </div>
                    )}
                    <CustomFilterbar
                      filterFlags={["Status Sent", "Status No-Consent"]}
                      dataListTemp={mailDataTmp}
                      stateUpdator={setMailData}
                      from="Mail Details Inner Table"
                    />
                    <CustomSearchbar
                      searchFlags={[
                        "user_id.firstname",
                        "user_id.email",
                        "unit_id.unit_number",
                        "group",
                        "status",
                      ]}
                      dataListTemp={mailDataTmp}
                      stateUpdator={setMailData}
                      placeholders={["resident", "unit #", "email", "group"]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {bulkMails.length > 1 && (
                      <div className="me-3 show-pdf-div">
                        <Button
                          variant="falcon-success"
                          onClick={() => applyBulkAction(false)}
                          disabled={bulkMails.length === 1}
                        >
                          Download Mail Copies
                        </Button>
                        {mailDetailObj?.draft_letter && (
                          <Button
                            variant="falcon-success"
                            onClick={() => applyBulkAction(true)}
                            disabled={bulkMails.length === 1}
                            className="my-3"
                          >
                            Download Letter Copies
                          </Button>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>Unit #</th>
                    <th>Groups</th>
                    <th>Time</th>
                    <th>Recipient</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mailData?.map((v, i) => (
                    <tr
                      key={v?._id}
                      style={{
                        height: mailData?.length === 1 ? "130px" : "",
                      }}
                    >
                      <td>
                        <Form.Check
                          id={v?._id}
                          onChange={() => addBulkAction(v?._id)}
                          checked={bulkMails?.includes(v?._id)}
                        />
                      </td>
                      <td>{v?.unit_id?.unit_number}</td>

                      <td>{v?.group}</td>
                      <td style={{ minWidth: "130px" }}>
                        {formatLogInfo(v?.createdAt)}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="me-2">
                            <Avatar
                              sx={{
                                bgcolor: v?.user_id?.bgColor,
                                fontSize: "16px",
                              }}
                              className="img-thumbnail"
                            >
                              {startCase(v?.user_id?.firstname[0]) +
                                startCase(v?.user_id?.lastname[0])}
                            </Avatar>
                          </div>
                          <div>
                            <div className="ms-1">
                              {v?.user_id?.firstname || "First Name"}{" "}
                              {v?.user_id?.lastname || "Last Name"}
                            </div>
                            <div className="ms-1">{v?.user_id?.email}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {v?.status === "Sent" && (
                          <SoftBadge bg="success">Sent</SoftBadge>
                        )}
                        {v?.status === "Processing" && (
                          <SoftBadge bg="info">Processing</SoftBadge>
                        )}
                        {v?.status === "E-consent Inactive" && (
                          <SoftBadge bg="secondary">
                            E-consent Inactive
                          </SoftBadge>
                        )}
                      </td>
                      <td>
                        <CardDropdown>
                          <div className="py-2">
                            <Dropdown.Item
                              onClick={() =>
                                previewAndPrint(
                                  "mail",
                                  v?.mailout_id.mail_content
                                )
                              }
                            >
                              Print Mail
                            </Dropdown.Item>
                            {v?.mailout_id?.draft_letter && (
                              <Dropdown.Item
                                onClick={() =>
                                  previewAndPrint(
                                    "letter",
                                    v?.mailout_id.letter_content
                                  )
                                }
                              >
                                Print Letter
                              </Dropdown.Item>
                            )}
                          </div>
                        </CardDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <MailDetailsModal
        show={show}
        handleClose={handleClose}
        payload={payload}
        setPayload={setPayload}
        companyLetterHead={companyLetterHead}
        propertyLetterHead={propertyLetterHead}
        updatedLetterHead={updatedLetterHead}
      />
    </div>
  );
}
