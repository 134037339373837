import React from "react";

import Flex from "../../../../../components/common/Flex";
import { Button, Card, Col, Row } from "react-bootstrap";

import ServiceRequestTable from "./ServiceRequestsTable";
import ServiceRequestsModel from "../../../../../components/pages/sharedUI/ServiceRequestModel/ServiceRequestsModel";
import { v4 as uuidv4 } from "uuid";

import axios from "axios";

import { Link, useParams } from "react-router-dom";
import Loader from "../../../../../components/common/Loader";
import CustomFilterbar from "../../../../../components/common/CustomFilterbar";
import CustomSearchbar from "../../../../../components/common/CustomSearchbar";
import PaginateComponentCustom from "../../../../../components/common/PaginateComponentCustom";
import { formatLogInfo } from "../../../../../helpers/utils";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../helpers/utils";

import CustomBreadCrumbs from "../../../../../components/common/CustomBreadCrumbs";
import { startCase } from "lodash";

const ServiceRequests = ({ from = "" }) => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, unit_id, unit_number } = useParams();

  const [propertiesData, setPropertiesData] = React.useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = React.useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = React.useState(false);

  const [showModel, setShowModel] = React.useState(false);

  const handleClose = () => {
    setPayload({});
    getAllReqsData();
    setShowModel(false);
  };
  const handleShow = () => setShowModel(true);

  const [payload, setPayload] = React.useState({});

  const createRequest = () => {
    setPayload({});
    let obj = {};
    obj.unit_id = unit_id;

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      obj.property_id = property_id;
    }

    if (
      userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant"
    ) {
      obj.property_id = userData.propid;
    }

    obj.loadType = "Create Request";
    obj.userData = { ...userData };
    setPayload({ ...obj });
    handleShow();
  };

  const showRequestDetail = (req) => {
    setPayload({});
    console.log(req);
    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      req.loadType = "View Request Manager";
      req.property_id = property_id;
    }

    if (
      userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant"
    ) {
      req.loadType = "View Request Owner";
      req.property_id = userData.propid;
    }
    req.unit_id = unit_id;
    req.userData = { ...userData };
    setPayload({ ...req });
    handleShow();
  };

  React.useEffect(() => {
    userData.role !== "unit_owner" && getAllReqsData();
    (userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant") &&
      getAllReqsData();
  }, []);

  const getAllReqsData = (showLoader = true) => {
    if (showLoader) setLoader(true);

    let link = "";

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    )
      link = `${hostname}/api/unit/ReadAllMaintenance`;
    if (
      userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant"
    )
      link = `${hostname}/api/unit/ReadMaintenance`;

    let reqInput = {
      generated_by_id: "",
      p_id: "",
    };
    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      reqInput.p_id = property_id;
    }
    if (
      userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant"
    ) {
      reqInput.generated_by_id = userData.data;
      reqInput.p_id = userData.propid;
    }

    // console.log(reqInput);

    // return;

    if (reqInput.p_id !== "") {
      axios
        .post(
          link,
          {
            ...reqInput,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);

          let arr = res.data.Data?.map((v) => {
            let obj = { ...v };
            if (obj.priority_level === "Low")
              obj.priority_level = {
                level: "Low",
                type: { color: "#CCF6E4", variant: "success" },
              };
            if (obj.priority_level === "Medium")
              obj.priority_level = {
                level: "Medium",
                type: { color: "#FDE6D8", variant: "warning" },
              };
            if (obj.priority_level === "High")
              obj.priority_level = {
                level: "High",
                type: { color: "#FAD7DD", variant: "danger" },
              };

            // obj.status = {
            //   type: "warning",
            //   title: "Open",
            //   icon: "check",
            // };

            // status
            if (obj.status === "Open")
              obj.status = {
                type: "warning",
                title: "Open",
                icon: "check",
                variant: "#FDE6D8",
              };
            if (obj.status === "Processing")
              obj.status = {
                type: "info",
                title: "Processing",
                icon: "redo",
                variant: "#D4F2FF",
              };
            if (obj.status === "Closed")
              obj.status = {
                type: "success",
                title: "Closed",
                icon: "check",
                variant: "#CCF6E4",
              };

            let images = obj.attached_images?.map((v) => {
              let inObj = {};
              inObj.tmp_id = uuidv4();
              inObj.type = "link";
              inObj.file = v;
              return inObj;
            });

            obj.attached_images = images;

            let comments = obj.comments?.map((v) => {
              let inObj = {};
              inObj.text = v.text;
              inObj.comment_id = v._id;
              inObj.user_id = v.user_id._id;
              inObj.firstname = v.user_id.firstname;
              inObj.lastname = v.user_id.lastname;
              inObj.bgColor = v.user_id.bgColor;
              inObj.time = formatLogInfo(v.updatedAt);
              inObj.isEdited = v.isEdited;

              return inObj;
            });

            obj.comments = comments;

            return obj;
          });
          if (from === "singleUnit") {
            arr = arr?.filter((v) => v.unit_id.unit_number === unit_number);
          }
          setPropertiesData(arr);
          setPropertiesDataTemp(arr);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        {(userData.role === "Site-Administrators" ||
          userData.role === "Condozak-Basic-Users") &&
          from === "singleUnit" && (
            <Row className="g-3 mb-3">
              <Col>
                <CustomBreadCrumbs
                  links={[
                    { path: "/dashboard", label: "Home" },
                    {
                      path: "/property/management/buildings",
                      label: "Properties",
                    },
                    {
                      path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                      label: startCase(property_name),
                    },
                    {
                      path: `/property/management/buildings/${property_id}/${property_name}/units/1`,
                      label: "Units",
                    },
                    {
                      path: `/${property_id}/${property_name}/${unit_number}/${unit_id}/attached-units`,
                      label: `${unit_number}`,
                    },
                    { path: "", label: "Maintenance Requests" },
                  ]}
                />
                {/* <Card>
                  <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                    <Link className="me-2 text-secondary" to="/dashboard">
                      Home
                    </Link>
                    {"/"}
                    <Link
                      className="me-2 ms-2 text-secondary"
                      to="/property/management/buildings"
                    >
                      Properties
                    </Link>
                    {"/"}
                    <Link
                      className="me-2 ms-2 text-secondary"
                      to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                    >
                      {property_name}
                    </Link>
                    {"/"}
                    <Link
                      className="me-2 ms-2 text-secondary"
                      to={`/property/management/buildings/${property_id}/${property_name}/units`}
                    >
                      Units
                    </Link>
                    {"/"}
                    <Link
                      className="me-2 ms-2 text-secondary"
                      to="#!"
                      // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/attached-units`}
                    >
                      {unit_number}
                    </Link>
                    {"/"}
                    <Link
                      className="me-2 ms-2 text-primary"
                      // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                      to={"#!"}
                    >
                      Maintenance Requests
                    </Link>
                  </Card.Body>
                </Card> */}
              </Col>
            </Row>
          )}
        {(userData.role === "Site-Administrators" ||
          userData.role === "Condozak-Basic-Users") &&
          from !== "singleUnit" && (
            <Row className="g-3 mb-3">
              <Col>
                <CustomBreadCrumbs
                  links={[
                    { path: "/dashboard", label: "Home" },
                    {
                      path: "/property/management/buildings",
                      label: "Properties",
                    },
                    {
                      path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                      label: startCase(property_name),
                    },

                    { path: "", label: "Maintenance Requests" },
                  ]}
                />
                {/* <Card>
                  <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                    <Link className="me-2 text-secondary" to="/dashboard">
                      Home
                    </Link>
                    {"/"}
                    <Link
                      className="me-2 ms-2 text-secondary"
                      to="/property/management/buildings"
                    >
                      Properties
                    </Link>
                    {"/"}
                    <Link
                      className="me-2 ms-2 text-secondary"
                      to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                    >
                      {property_name}
                    </Link>
                    {"/"}

                    <Link
                      className="me-2 ms-2 text-primary"
                      // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                      to={"#!"}
                    >
                      Maintenance Requests
                    </Link>
                  </Card.Body>
                </Card> */}
              </Col>
            </Row>
          )}

        {(userData.role === "unit_owner" ||
          userData.role === "supp_resident" ||
          userData.role === "supp_tenant") && (
          <Row className="g-3 mb-3">
            <Col>
              <CustomBreadCrumbs
                links={[
                  { path: "/dashboard", label: "Home" },

                  { path: "", label: "Maintenance Requests" },
                ]}
              />
              {/* <Card>
                <Card.Body className="">
                  <Link className="text-secondary" to={"/"}>
                    Home
                  </Link>
                  {" / "}
                  <Link to={"#!"}>Maintenance Requests</Link>
                </Card.Body>
              </Card> */}
            </Col>
          </Row>
        )}
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>Maintenance Requests</h4>
              {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              {(userData.role === "unit_owner" ||
                userData.role === "supp_resident" ||
                userData.role === "supp_tenant") && (
                <Button variant="primary" size="sm" onClick={createRequest}>
                  + Create Request
                </Button>
              )}
            </Flex>
            <br />

            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomFilterbar
                  filterFlags={[
                    "Status Open",
                    "Status Processing",
                    "Status Closed",
                  ]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  from="Maintenance Table"
                />

                <CustomSearchbar
                  searchFlags={[
                    "_id",
                    "generated_by_id.firstname",
                    "unit_id.unit_number",
                    "request_type",
                    "subject",
                    "status.title",
                  ]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  placeholders={[
                    "id",
                    "resident",
                    "unit #",
                    "request type",
                    "subject",
                  ]}
                />
              </Col>
            </Row>

            <PaginateComponentCustom
              dataList={propertiesData}
              TableElement={ServiceRequestTable}
              itemsPerPage={20}
              showRequestDetail={showRequestDetail}
            />
            <br />

            <ServiceRequestsModel
              show={showModel}
              handleClose={handleClose}
              payload={payload}
              setPayload={setPayload}
              getAllData={getAllReqsData}
              hostname={hostname}
              jwtToken={jwtToken}
              userData={userData}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default ServiceRequests;
