import React from "react";

import { Button, Card, Col, Row } from "react-bootstrap";
import axios from "axios";

import { useParams } from "react-router-dom";

import Flex from "../../../../../../../components/common/Flex";
import Loader from "../../../../../../../components/common/Loader";
import CustomSearchbar from "../../../../../../../components/common/CustomSearchbar";
import UnitsManagementModel from "../../../../../../../components/pages/sharedUI/UnitsManagementModel";
import UnitDetailsComp from "./UnitDetailsComp";
import FalconCardHeader from "../../../../../../../components/common/FalconCardHeader";
import CustomBreadCrumbs from "../../../../../../common/CustomBreadCrumbs";

import AttachedUnitsTable from "./AttachedUnitsTable";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../../../helpers/utils";
import { startCase } from "lodash";

const AttachedUnits = ({ fromOwner, unitData = [] }) => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, unit_number, unit_id } = useParams();

  const [unitDetailsObj, setUnitDetailsObj] = React.useState({});

  const [attachedUnitsData, setAttachedUnitsData] = React.useState([]);
  const [attachedUnitsDataTemp, setAttachedUnitsDataTemp] = React.useState([]);

  const [availabeFacility, setAvailablefacility] = React.useState([]);

  const [loader, setLoader] = React.useState(true);

  const [payload, setPayload] = React.useState({});

  // modal state
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    if (
      userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant"
    ) {
      console.log(unitData);
      let obj = unitData[0];

      setUnitDetailsObj(obj);

      let attachedNonCondoUnitsData = [];

      
        attachedNonCondoUnitsData = attachedNonCondoUnitsData.concat(obj?.lockers, obj?.parkings);

        console.log(attachedNonCondoUnitsData);

        setAttachedUnitsData(attachedNonCondoUnitsData);
        setAttachedUnitsDataTemp(attachedNonCondoUnitsData);
      
      setLoader(false);
    } else {
      getUnitsDetailData();
      getAvailabeFacility();
    }
  }, []);

  const getUnitsDetailData = () => {
    axios
      .post(
        `${hostname}/api/property/UnitDetails`,
        { unit_id },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let obj = { ...res.data };

        delete obj.lockers;
        delete obj.parkings;

        setUnitDetailsObj(obj);

        let attachedNonCondoUnitsData = [
          ...res.data?.lockers,
          ...res.data?.parkings,
        ];

        console.log(attachedNonCondoUnitsData);

        setAttachedUnitsData(attachedNonCondoUnitsData);
        setAttachedUnitsDataTemp(attachedNonCondoUnitsData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAvailabeFacility = () => {
    axios
      .post(
        `${hostname}/api/property/AvailableFacility`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setAvailablefacility(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllData = () => {
    getUnitsDetailData();
    getAvailabeFacility();
  };

  const attachUnit = () => {
    let obj = {};
    obj.loadType = "Attach Unit ";
    obj.property_id = property_id;
    obj.unit_id = unit_id;
    obj.availabeFacility = [...availabeFacility];
    obj.unitDetailsObj = unitDetailsObj;
    setPayload({ ...obj });
    handleShow();
  };

  const detachUnit = (unit) => {
    unit.loadType = "Detach Unit ";
    unit.property_id = property_id;
    unit.unit_id = unit_id;
    unit.availabeFacility = [...availabeFacility];
    unit.unitDetailsObj = unitDetailsObj;
    console.log(unit);
    setPayload({ ...unit });
    handleShow();
  };

  const addUnitOwner = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Add Unit Owner";
    setPayload({ ...unit });
    handleShow();
  };

  const reinviteUnitOwner = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Reinvite Unit Owner";
    setPayload({ ...unit });
    handleShow();
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        {!fromOwner && (
          <Row className="g-3 mb-3">
            <Col>
              <CustomBreadCrumbs
                links={[
                  { path: "/dashboard", label: "Home" },
                  {
                    path: "/property/management/buildings",
                    label: "Properties",
                  },
                  {
                    path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                    label: startCase(property_name),
                  },
                  {
                    path: `/property/management/buildings/${property_id}/${property_name}/units/1`,
                    label: "Units",
                  },
                  { path: "", label: `${unit_number}` },
                ]}
              />
            </Col>
          </Row>
        )}
        {fromOwner && (
          <Row className="g-3 mb-3">
            <Col>
              <CustomBreadCrumbs
                links={[
                  { path: "/dashboard", label: "Home" },
                  { path: "", label: "Unit Details" },
                ]}
              />
            </Col>
          </Row>
        )}
        <Row className="g-3 mb-3">
          <Col>
            <UnitDetailsComp
              unit={fromOwner ? unitData[0] : unitDetailsObj}
              fromOwner={fromOwner}
              addUnitOwner={addUnitOwner}
              reinviteUnitOwner={reinviteUnitOwner}
            />
          </Col>
        </Row>
        <Card>
          <FalconCardHeader title="Attached Units" className="bg-light" />
          <Card.Body className="" style={{ minHeight: "50vh" }}>
            <Flex justifyContent={"end"} alignItems={"start"} className="">
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") && (
                <Button variant="primary" size="sm" onClick={attachUnit}>
                  + Attach Unit
                </Button>
              )}
            </Flex>
            <br />

            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomSearchbar
                  searchFlags={["unit_number", "unit_type"]}
                  dataListTemp={attachedUnitsDataTemp}
                  stateUpdator={setAttachedUnitsData}
                  placeholders={["type", "number"]}
                />
              </Col>
            </Row>

            <AttachedUnitsTable
              dataList={attachedUnitsData}
              detachUnit={detachUnit}
              userData={userData}
            />
            <br />

            <UnitsManagementModel
              show={show}
              handleClose={handleClose}
              payload={payload}
              setPayload={setPayload}
              userData={userData}
              getAllData={getAllData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default AttachedUnits;
