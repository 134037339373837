import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Fuse from "fuse.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { getHostName } from "../../../../../helpers/utils";
import Cookies from "universal-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function CustomSearchBarv2({
  apiEndpoint = "",
  apiInput = {},
  searchFlags = [],
  placeholders = [],
  haveLinkToDetail = "",
}) {
  const [dataList, setDataList] = React.useState([]);
  const [dataListTemp, setDataListTemp] = React.useState([]);

  const [loader, setLoader] = React.useState(false);

  const [payload, setPayload] = React.useState([]);

  const [showModel, setShowModel] = React.useState(false);

  const handleShow = () => setShowModel(true);
  const handleClose = () => {
    setPayload([]);
    setShowModel(false);
  };

  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");

  const navigate = useNavigate();

  const { property_id, property_name } = useParams();

  React.useEffect(() => {
    getDataList();
  }, [showModel]);

  const getDataList = () => {
    setLoader(true);
    axios
      .post(
        `${hostname}${apiEndpoint}`,
        {
          ...apiInput,
          limit: 100000,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let arrU = getUserFormation(res.data.data);
        setDataList(arrU);
        setDataListTemp(arrU);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handleSearchBarAction = (e) => {
    try {
      // console.log(dataList);
      // console.log(dataListTemp);

      const fuse = new Fuse([...dataListTemp], {
        keys: searchFlags,
        isCaseSensitive: false,
      });

      let res = fuse.search(e.target.value);

      res = res.map((v) => {
        return v.item;
      });

      console.log(res);

      if (res.length) setPayload([...res]);
      else setPayload([]);
    } catch (e) {
      console.log(e);
    }
  };

  const getPayloadResults = () => {
    if (payload.length < 1)
      return (
        <tr>
          <td colSpan={placeholders.length} className="text-center">
            Searches will appear here
          </td>
        </tr>
      );

    let res = [];

    res = payload.map((data) => {
      if (data.unit_type !== "condo") {
        if (
          Object.hasOwnProperty.call(data, "attachedToUnit") &&
          data.attachedToUnit !== null
        )
          return (
            <tr className="align-middle ">
              <td className="text-center ">{data.unit_number || "id"}</td>
              <td className="text-center">{data.unit_type || "name"}</td>
              <td className="text-center">{`${data.cef}%` || "address"}</td>
              <td className="text-center">-</td>
              <td className="text-center">
                {data.attachedToUnit.unit_number || "unit no"}
              </td>
            </tr>
          );
        else
          return (
            <tr
              className="align-middle "
              //   style={{ height: propLength === 1 ? "100px" : "" }}
            >
              <td className="text-center">{data.unit_number || "id"}</td>
              <td className="text-center">{data.unit_type || "name"}</td>
              <td className="text-center">{`${data.cef}%` || "address"}</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
            </tr>
          );
      } else
        return (
          <tr
            className="align-middle "
            // style={{ height: propLength === 1 ? "100px" : "" }}
          >
            <td className="text-center text-primary">
              <Link
                to={`/${data.p_id}/${data.p_name}/${data.unit_number}/${data._id}/attached-units`}
              >
                {data.unit_number || "id"}
              </Link>
            </td>
            <td className="text-center">{data.unit_type || "name"}</td>
            <td className="text-center">{`${data.cef}%` || "address"}</td>
            <td className="text-left">
              {Object.hasOwnProperty.call(data, "Inviteduser") ? (
                <>{data.Inviteduser} </>
              ) : Object.hasOwnProperty.call(data, "userinfo") ? (
                <>
                  {data.userinfo.firstname} {data.userinfo.lastname}{" "}
                </>
              ) : (
                <>Owner Not Linked </>
              )}
              {data.resident_status === "Active" && (
                <div className="badge bg-success">Active</div>
              )}
              {data.resident_status === "Not Invited" && (
                <div className="badge bg-primary">Pending</div>
              )}
              {data.resident_status === "Ignored" && (
                <div className="badge bg-secondary">Ignored</div>
              )}
              {data.resident_status === "Invited" && (
                <div className="badge bg-info">Invited</div>
              )}
            </td>
            <td className="text-center">-</td>
          </tr>
        );
    });

    return res;
  };

  const getUserFormation = (dataList = []) => {
    let arrU = dataList.map((u) => {
      let userinfo = {};
      if (Object.hasOwnProperty.call(u, userinfo)) {
        userinfo = u.userinfo;
        delete u.userinfo;
      }
      let obj = Object.assign(
        { p_id: property_id, p_name: property_name },
        { ...userinfo },
        u
      );
      return obj;
    });
    console.log(arrU);
    arrU = arrU.map((v) => {
      let obj = {};

      if (v.resident_status === "Not Invited") {
        obj = Object.assign(
          {
            status: {
              title: "Pending",
              type: "warning",
              icon: "check",
            },
            type: "no-owner",
          },
          v
        );
      }
      if (v.resident_status === "Invited") {
        obj = Object.assign(
          {
            status: {
              title: "Invited",
              type: "info",
              icon: "check",
            },
            type: "invited-owner",
          },
          v
        );
      }
      if (v.resident_status === "Ignored") {
        obj = Object.assign(
          {
            status: {
              title: "Ignored",
              type: "secondary",
              icon: "redo",
            },
            type: "no-owner",
          },
          v
        );
      }
      if (v.resident_status === "Active") {
        obj = Object.assign(
          {
            status: {
              title: "Active",
              type: "success",
              icon: "check",
            },
            type: "linked-owner",
          },
          v
        );
      }
      return obj;
    });

    console.log(arrU);

    arrU = arrU.sort((a, b) =>
      +a.unit_number > +b.unit_number
        ? 1
        : +b.unit_number > +a.unit_number
        ? -1
        : 0
    );

    return arrU;
  };

  const handlePagination = (n) => {
    console.log(n);

    navigate(
      `/property/management/buildings/${property_id}/${property_name}/units/${n}`
    );
  };

  return (
    <>
      <Form.Group className="mb-3">
        <div className="input-group mb-3">
          <span className="input-group-text bg-white" id="basic-addon2">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <input
            type="text"
            name="searchv"
            // onKeyUp={handleSearchBarAction}
            onClick={handleShow}
            className="form-control"
            placeholder={`Search by ${placeholders?.map((v) => v)} ...`}
            aria-label="search-units-mgr"
            aria-describedby="search-units-mgr"
          />
        </div>
      </Form.Group>

      <Modal
        show={showModel}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search here</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "600px" }}>
          <Form.Group className="mb-3">
            <div className="input-group mb-3">
              <span className="input-group-text bg-white" id="basic-addon2">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                type="text"
                name="searchv"
                onKeyUp={handleSearchBarAction}
                className="form-control"
                placeholder={`Search by${placeholders?.map(
                  (v) => ` ${v}`
                )} ...`}
                aria-label="search-units-mgr-1"
                aria-describedby="search-units-mgr-1"
                autoFocus
              />
            </div>
          </Form.Group>
          <br />

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                {placeholders?.map((v) => (
                  <th key={v}>{v}</th>
                ))}
              </tr>
            </thead>
            <tbody>{getPayloadResults()}</tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
