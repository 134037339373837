import React from "react";
// import AmenityBasic from '../../wizardRemastered/AmenityBasic';
import FalconCardHeader from "../../../../../../components/common/FalconCardHeader";
import { Button, Card, Form as BSForm } from "react-bootstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";

export default function Description({ amenityCurrent, updateAmenityBasic }) {
  try {
    return (
      <div>
        <Card style={{ minHeight: "430px" }} className="mb-3">
          <FalconCardHeader title="Amenity Details" />
          <Card.Body className="bg-light">
            <Formik
              initialValues={{
                amenity_name: amenityCurrent.amenityname || "",
                description: amenityCurrent.description || "",
              }}
              validate={(values) => {
                let errors = {};
                if (!values.amenity_name) {
                  errors.amenity_name = "Name is required!";
                }
                if (!values.description) {
                  errors.description = "Description is required!";
                }
                if (values.description && values.description.length > 100) {
                  errors.description = "Description is too long !";
                }
                console.log(errors);
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                updateAmenityBasic(values);
                setSubmitting(false);
                // }, 400);
              }}
            >
              {({ isSubmitting, values, handleChange, handleBlur }) => (
                <Form>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Amenity Name</BSForm.Label>
                    <Field
                      type="text"
                      name="amenity_name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="amenity_name"
                      component="div"
                      className="text-danger fs--1"
                    />
                  </BSForm.Group>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Description</BSForm.Label>
                    <textarea
                      rows={3}
                      type="text"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger fs--1"
                    />
                    <div
                      style={{
                        color:
                          values.description.length > 100 ? "red" : "black",
                        textAlign: "end",
                      }}
                    >
                      {values.description.length} / 100
                    </div>
                  </BSForm.Group>
                  <br />
                  <div className="text-end">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="info"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <p className="text-danger">{e.message}</p>;
  }
}
