import { useState, useEffect } from "react";

import Flex from "../../../../components/common/Flex";
import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import MailOutsTable from "./MailOutsTable";

import CreateNewMailModal from "./CreateNewMailModal";

import axios from "axios";
import PaginateComponentCustom from "../../../../components/common/PaginateComponentCustom";
import Loader from "../../../../components/common/Loader";
import { Link, useParams } from "react-router-dom";
import CustomSearchbar from "../../../../components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";
import CustomBreadCrumbs from "../../../common/CustomBreadCrumbs";

const VehicleInfo = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { unit_id } = useParams();

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getAllPropertiesData();
  }, []);

  const getAllPropertiesData = () => {
    console.log("working");
    // return;
    if (unit_id) {
      axios
        .post(
          `${hostname}/api/unit/GetAllVehicles`,
          {
            unit_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          // console.log(res.data);

          setPropertiesData(res.data.Data);
          setPropertiesDataTemp(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  // create  modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [payload, setPayload] = React.useState({});

  const addItem = () => {
    let obj = {};
    obj.loadType = "Add Vehicle";
    obj.unit_id = unit_id;
    setPayload({ ...obj });
    handleShow();
  };
  const updateItem = (entry = {}) => {
    entry.loadType = "Update Vehicle";
    entry.unit_id = unit_id;
    setPayload({ ...entry });
    handleShow();
  };
  const deleteItem = (entry = {}) => {
    entry.loadType = "Delete Vehicle";
    entry.unit_id = unit_id;
    setPayload({ ...entry });
    handleShow();
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <CustomBreadCrumbs
              links={[
                { path: "/dashboard", label: "Home" },

                { path: "", label: "Vehicles" },
              ]}
            />
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>Vehicles</h4>
              {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              <Button variant="primary" size="sm" onClick={addItem}>
                + Add Vehicle
              </Button>
            </Flex>
            <br />
            {/* <Row>
              <Col md="6">
                <p>long long text for property management</p>
              </Col>
            </Row> */}
            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomSearchbar
                  searchFlags={[
                    "car_make",
                    "car_model",
                    "color",
                    "license_plate",
                  ]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  placeholders={["car_make", "model", "type"]}
                />
              </Col>
            </Row>

            <PaginateComponentCustom
              dataList={propertiesData}
              itemsPerPage={20}
              TableElement={MailOutsTable}
              addItem={addItem}
              updateItem={updateItem}
              deleteItem={deleteItem}
            />
            <br />
            <CreateNewMailModal
              show={show}
              handleClose={handleClose}
              userData={userData}
              payload={payload}
              setPayload={setPayload}
              getAllData={getAllPropertiesData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default VehicleInfo;
