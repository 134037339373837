// import CustomFilterbar from "components/common/CustomFilterbar";
import CustomSearchbar from "../../../../components/common/CustomSearchbar";
import Flex from "../../../../components/common/Flex";
import React from "react";
import { Row, Col, Card, Breadcrumb, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
// import Cookies from "universal-cookie";
import VisitorParkingModal from "./VisitorParkingModal";
import VisitorParkingTable from "./VisitorParkingTable";
import axios from "axios";
import {
  getBase64Type,
  getTodayItemsFromList,
} from "../../../../helpers/utils";
import Loader from "../../../../components/common/Loader";
import CustomBreadCrumbs from "../../../../components/common/CustomBreadCrumbs";
import { startCase } from "lodash";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";

export default function VisitorParkingPermit({ from = "" }) {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_name, property_id, unit_id, unit_number } = useParams();

  const [loader, setLoader] = React.useState(false);

  const [
    VisitorParkingAdditionalSettings,
    setVisitorParkingAdditionalSettings,
  ] = React.useState({});
  const [visitorParkingList, setLisitorParkingList] = React.useState([]);
  const [visitorParkingListTemp, setLisitorParkingListTemp] = React.useState(
    []
  );

  const [showModal, setShowModal] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  const [parkingSettingData, setParkingSettingData] = React.useState({});
  const [propertyLogo, setPropertyLogo] = React.useState({});

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const getAllVisitorParkingData = () => {
    getParkingSettingData();
    console.log(userData.role);

    let link = "";

    let input = {
      p_id: "",
      unit_id: "",
      user_id: userData.data,
    };

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      // console.log("no unit id");
      // return;
      link = `${hostname}/api/property/getPropertyBookings`;
      input.p_id = property_id;
      // input.unit_id = unit_id;
    }
    if (
      userData.role === "unit_owner" ||
      userData.role === "supp_resident" ||
      userData.role === "supp_tenant"
    ) {
      link = `${hostname}/api/property/getUnitBookings`;
      input.p_id = userData.propid;
      input.unit_id = userData.UnitsOwner[0]._id;
    }

    console.log(input);
    // return;

    //
    // call api

    axios
      .post(
        link,
        { ...input },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setVisitorParkingAdditionalSettings({
          property_address: res.data.Property_Address,
          parking_setting_time: res.data.setting_time || {},
          property_manager: res.data.Property_Manager,
        });
        let arr = res.data.Data;
        arr.sort((a, b) =>
          a.start_date < b.start_date ? 1 : b.start_date < a.start_date ? -1 : 0
        );
        if (from === "singleUnit") {
          console.log("single unit");
          arr = arr?.filter((v) => v.unit_id.unit_number === unit_number);
        }
        setLisitorParkingList(arr);
        setLisitorParkingListTemp(arr);
        let logo = res.data.logo;
        // console.log(logo);
        if (logo) {
          let type = getBase64Type(res.data.logo);
          let obj = {};
          obj.logoType = type;
          if (type !== "link")
            obj.property_logo = `data:image/${type};base64,${logo}`;
          setPropertyLogo(obj);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getParkingSettingData = () => {
    axios
      .post(
        `${hostname}/api/property/GetVisitorParkingSettings`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.Data === null) setParkingSettingData({});
        else setParkingSettingData(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    setLoader(true);
    getAllVisitorParkingData();
    getParkingSettingData();
  }, []);

  const addSettingByPM = () => {
    setPayload({});
    let obj = {};
    obj.loadType = "Parking Settings";
    obj.property_id = property_id;
    obj.parkingSettingData = parkingSettingData;
    // obj.unit_id = unit_id;
    setPayload({ ...obj });
    handleShow();
  };

  const addNewPermitByOwner = () => {
    setPayload({});
    let obj = {};
    obj.loadType = "New Parking Permit";
    obj.property_id = property_id;
    obj.unit_id = unit_id;
    obj.userData = userData;
    setPayload({ ...obj });
    handleShow();
  };

  const viewPermitDetails = (obj = {}) => {
    console.log(obj);
    setPayload({});
    obj.loadType = "Parking Permit Details";
    obj.property_id = property_id;
    obj.property_name = property_name;
    // obj.unit_id = unit_id;
    obj.userData = userData;
    setPayload({ ...obj });
    handleShow();
  };
  const printPermit = (obj = {}) => {
    console.log(obj);
    setPayload({});
    obj.loadType = "Print Pass";
    obj.property_id = property_id;
    obj.property_name = property_name;
    // obj.unit_id = unit_id;
    setPayload({ ...obj });
    handleShow();
  };
  const generateReport = () => {
    let obj = {};
    setPayload({});

    let todayPassesArr = getTodayItemsFromList(visitorParkingList);
    // let todayPassesArr = visitorParkingList?.filter((v) => {
    //   let permitDate = new Date(v.createdAt).getDate();
    //   console.log(permitDate);
    //   var today = new Date().getDate();

    //   return permitDate == today;
    // });

    console.log(todayPassesArr);

    obj.loadType = "Generate Report";
    obj.property_id = property_id;
    obj.property_name = property_name;
    obj.userData = userData;
    obj.AllVisitorParkingList = visitorParkingList;
    obj.todayPassesArr = todayPassesArr;
    // obj.unit_id = unit_id;
    setPayload({ ...obj });
    handleShow();
  };

  if (loader) return <Loader />;
  return (
    <div>
      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") &&
        from === "singleUnit" && (
          <Row className="g-3 mb-3">
            <Col>
              <CustomBreadCrumbs
                links={[
                  { path: "/dashboard", label: "Home" },
                  {
                    path: "/property/management/buildings",
                    label: "Properties",
                  },
                  {
                    path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                    label: startCase(property_name),
                  },
                  {
                    path: `/property/management/buildings/${property_id}/${property_name}/units/1`,
                    label: "Units",
                  },
                  {
                    path: `/${property_id}/${property_name}/${unit_number}/${unit_id}/attached-units`,
                    label: `${unit_number}`,
                  },
                  { path: "", label: "Visitor Parking" },
                ]}
              />
              {/* <Card>
                <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                  <Link className="me-2 text-secondary" to="/dashboard">
                    Home
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to="/property/management/buildings"
                  >
                    Properties
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                  >
                    {property_name}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-secondary"
                    to={`/property/management/buildings/${property_id}/${property_name}/units`}
                  >
                    Units
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-primary"
                    // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/history`}
                    to="#!"
                  >
                    {unit_number}
                  </Link>
                  {"/"}
                  <Link
                    className="me-2 ms-2 text-primary"
                    // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                    to={"#!"}
                  >
                    Visitor Parking
                  </Link>
                </Card.Body>
              </Card> */}
            </Col>
          </Row>
        )}

      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") &&
        from !== "singleUnit" && (
          <Row className="g-3 mb-3">
            <Col>
              <CustomBreadCrumbs
                links={[
                  { path: "/dashboard", label: "Home" },
                  {
                    path: "/property/management/buildings",
                    label: "Properties",
                  },
                  {
                    path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                    label: startCase(property_name),
                  },

                  { path: "", label: "Visitor Parking" },
                ]}
              />
              {/* <Card>
                <Card.Body className="">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link className="text-secondary" to={"/"}>
                        Home
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        className="text-secondary"
                        to={"/property/management/buildings"}
                      >
                        Properties
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link className="text-secondary" to={"#!"}>
                        {property_name}
                      </Link>
                    </Breadcrumb.Item>{" "}
                    <Breadcrumb.Item active>
                      <Link to={"#!"}>Visitor Parking</Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Card.Body>
              </Card> */}
            </Col>
          </Row>
        )}
      {(userData.role === "unit_owner" ||
        userData.role === "supp_resident" ||
        userData.role === "supp_tenant") && (
        <Row className="g-3 mb-3">
          <Col>
            <CustomBreadCrumbs
              links={[
                { path: "/dashboard", label: "Home" },

                { path: "", label: "Visitor Parking" },
              ]}
            />
            {/* <Card>
              <Card.Body className="">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link className="text-secondary" to={"/"}>
                      Home
                    </Link>
                  </Breadcrumb.Item>{" "}
                  <Breadcrumb.Item active>
                    <Link to={"#!"}>Visitor Parking</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      )}
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Visitor Parking</h4>

            {(userData.role === "Site-Administrators" ||
              userData.role === "Condozak-Basic-Users") && (
              <Button variant="primary" size="sm" onClick={addSettingByPM}>
                Settings
              </Button>
            )}
            {(userData.role === "unit_owner" ||
              userData.role === "supp_resident" ||
              userData.role === "supp_tenant") && (
              <Button variant="primary" size="sm" onClick={addNewPermitByOwner}>
                New Parking Permit
              </Button>
            )}
          </Flex>
          <br />

          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") && (
                <div>
                  <Button
                    variant="falcon-primary"
                    className="me-3"
                    onClick={generateReport}
                  >
                    Generate Report
                  </Button>
                </div>
              )}
              {/* <CustomFilterbar
                filterFlags={["Parking Reserved", "Parking Expired"]}
                dataListTemp={visitorParkingListTemp}
                stateUpdator={setLisitorParkingList}
                from="Visitor Parking Table"
              /> */}

              <CustomSearchbar
                searchFlags={[
                  "unit_id.unit_number",
                  "_id",
                  "license_plate",
                  "status",
                ]}
                dataListTemp={visitorParkingListTemp}
                stateUpdator={setLisitorParkingList}
                placeholders={["permit #", "license #", "unit number"]}
              />
            </Col>
          </Row>

          <VisitorParkingTable
            list={visitorParkingList}
            viewPermitDetails={viewPermitDetails}
            printPermit={printPermit}
          />
          <br />

          <VisitorParkingModal
            show={showModal}
            handleClose={handleClose}
            payload={payload}
            setPayload={setPayload}
            getAllData={getAllVisitorParkingData}
            VisitorParkingAdditionalSettings={VisitorParkingAdditionalSettings}
            propertyLogo={propertyLogo}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
