//pdf-lib to attach letterhead or modify pdf
import { PDFDocument } from "pdf-lib";

// htmltopdfmake parse html
// import htmlToPdfmake from "html-to-pdfmake";

import { getHostName, getBase64Type } from "./utils";
import axios from "axios";
import Cookies from "universal-cookie";

// // create new pddf
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import pdfMake from "pdfmake/build/pdfmake";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// export const handleCreatePurePdf = async (
//   htmlContent = "<p>no content provided!</p>",
//   metaInfo = { title: "dummy title", author: "dummy author" },
//   attachLetterHead = false,
//   LetterHeadPDFFile = "",
//   actionType = "preview", // | preview | download | bulkDownload
//   pageSize = "A4",
//   pageMargins = { left: 40, top: 60, right: 40, bottom: 60 },
//   applyWatermark = false,
//   watermarkData = {
//     text: "test watermark",
//     color: "grey",
//     opacity: 0.2,
//     bold: true,
//     italics: false,
//     // angle: 45,
//   },
//   encryptDocument = false,
//   encryptDocData = {
//     userPassword: "123",
//     ownerPassword: "123456",
//     printing: "highResolution",
//     modifying: false,
//     copying: false,
//     annotating: true,
//     fillingForms: false,
//     contentAccessibility: true,
//     documentAssembly: true,
//   }
// ) => {
//   // define pdf basic tree
//   var docDefinition = {
//     // meta info
//     info: {
//       title: metaInfo.title,
//       author: metaInfo.author,
//       subject: "",
//       keywords: "",
//     },

//     // a string or { width: number, height: number }
//     pageSize: pageSize,

//     // by default we use portrait, you can change it to landscape if you wish
//     pageOrientation: "portrait",

//     // [left, top, right, bottom] or [horizontal, vertical] or just a number
//     // pageMargins: [40, 60, 40, 60],
//     pageMargins: [
//       pageMargins.left,
//       pageMargins.top,
//       pageMargins.right,
//       pageMargins.bottom,
//     ],

//     watermark: {
//       text: applyWatermark ? watermarkData.text : "",
//       color: applyWatermark ? watermarkData.color : "",
//       opacity: applyWatermark ? watermarkData.opacity : "",
//       bold: applyWatermark ? watermarkData.bold : "",
//       italics: applyWatermark ? watermarkData.italics : "",
//       // angle: applyWatermark ? watermarkData.angle : "",
//     },

//     userPassword: encryptDocument ? encryptDocData.userPassword : "",
//     ownerPassword: encryptDocument ? encryptDocData.ownerPassword : "",
//     permissions: {
//       printing: encryptDocument ? encryptDocData.printing : "",
//       modifying: encryptDocument ? encryptDocData.modifying : "",
//       copying: encryptDocument ? encryptDocData.copying : "",
//       annotating: encryptDocument ? encryptDocData.annotating : "",
//       fillingForms: encryptDocument ? encryptDocData.fillingForms : "",
//       contentAccessibility: encryptDocument
//         ? encryptDocData.contentAccessibility
//         : "",
//       documentAssembly: encryptDocument ? encryptDocData.documentAssembly : "",
//     },

//     content: "",
//   };

//   let content = []; // array of html objects in parsed form

//   console.log([htmlContent]);

//   // define content here

//   content = htmlToPdfmake(htmlContent, {
//     defaultStyles: { p: { margin: [0, 0, 0, 0] } },
//     removeExtraBlanks: true,
//     // imagesByReference
//   });

//   console.log(content);

//   docDefinition.content = content;

//   // if there is letterhead , reduce margins and attach letterhead .

//   if (attachLetterHead) {
//     docDefinition.pageMargins = [40, 160, 40, 160];

//     pdfMake.createPdf(docDefinition).getBase64((result) => {
//       // console.log(result);
//       embedPdfPages(result, LetterHeadPDFFile, actionType);
//     });
//   } else {
//     if (actionType === "preview") {
//       pdfMake.createPdf(docDefinition).open();
//     }
//     if (actionType === "return") {
//       let pdf = "";

//       return pdfMake.createPdf(docDefinition).getBase64((res) => res);
//     }
//   }
// };

// export async function embedPdfPages(pdf, letterhead, actionType) {
//   // console.log(pdf);

//   // const PdfBytes = await fetch(letterhead).then((res) => res.arrayBuffer());
//   const PdfBytes = letterhead;

//   const pdfDoc = await PDFDocument.load(pdf);

//   const [embeddedPdf] = await pdfDoc.embedPdf(PdfBytes);

//   // console.log(embeddedPdf);

//   const embeddedPdfDims = embeddedPdf.scale(1);

//   const pages = pdfDoc.getPages();

//   // console.log(pages);

//   pages?.forEach((v) => {
//     v.drawPage(embeddedPdf, {
//       ...embeddedPdfDims,
//       // x: v.getWidth() / 2 - embeddedPdfDims.width / 2,
//       // y: v.getHeight() - embeddedPdfDims.height - 150,
//       x: 0,
//     });
//   });

//   let pdfBytes = await pdfDoc.saveAsBase64();

//   console.log(pdfBytes);

//   if (actionType === "preview") {
//     // if (file.startsWith("data")) file = file?.split("base64,")[1];

//     let blob = dataURItoBlob(pdfBytes);

//     const url = URL.createObjectURL(blob);

//     // to open the PDF in a new window
//     window.open(url, "_blank");
//   }
//   if (actionType === "return") {
//     return pdfBytes;
//   }
//   if (actionType === "return-as-blob") {
//     let blob = dataURItoBlob(pdfBytes);

//     return blob;
//   }
// }

// function dataURItoBlob(dataURI) {
//   const byteString = window.atob(dataURI);
//   const arrayBuffer = new ArrayBuffer(byteString.length);
//   const int8Array = new Uint8Array(arrayBuffer);
//   for (let i = 0; i < byteString.length; i++) {
//     int8Array[i] = byteString.charCodeAt(i);
//   }
//   const blob = new Blob([int8Array], { type: "application/pdf" });
//   return blob;
// }

export const getHtmlWithImageActualData = async (htmlContent) => {
  let htmlContentTmp = htmlContent;
  let finalStr = "";
  // console.log(htmlContentTmp);
  let lengthOfImages = 0;
  lengthOfImages = htmlContentTmp?.split("src=").length - 1;
  let pointerIndex = 0;

  if (lengthOfImages) {
    console.log("images found. spliting...");
    let srcIndex = 0,
      linkIndex = 0,
      link = "";
    for (let index = 1; index <= lengthOfImages; index++) {
      srcIndex = htmlContentTmp?.indexOf("src=", pointerIndex);
      console.log(srcIndex);
      srcIndex += 5;
      linkIndex = htmlContentTmp?.indexOf(" alt=", srcIndex);
      linkIndex -= 1;
      link = htmlContentTmp?.substring(srcIndex, linkIndex);
      console.log(link);

      // return;
      let b64 = await getImageB64DataFromLink(link);
      let repLinkType = getBase64Type(b64);
      let repLink = `data:image/${repLinkType};base64,${b64}`;

      let start = htmlContentTmp?.substring(0, srcIndex);
      let end = htmlContentTmp?.slice(linkIndex, htmlContentTmp.length);

      finalStr = start + repLink + end;
      if (index > 1) {
        htmlContentTmp = finalStr;
        srcIndex = linkIndex;
        pointerIndex = linkIndex;
      }
    }
    return finalStr;
  } else {
    console.log("no images here. returing");
    return htmlContent;
  }
};

const getImageB64DataFromLink = async (link = "") => {
  link = link?.replace(/&amp;/g, "&");
  const cookies = new Cookies();
  let jwtToken = cookies.get("_expire_t");
  let hostname = getHostName();

  const res = await axios.post(
    `${hostname}/api/property/ImageToData`,
    { link },
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );
  // console.log(res.data);
  return res.data.Data;
};

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);

    reader.readAsArrayBuffer(file);
  });
};

export const getPageCount = async (file) => {
  const arrayBuffer = await readFile(file);

  const pdf = await PDFDocument.load(arrayBuffer);

  return pdf.getPageCount();
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const handlePreviewAndPrint = (blob) => {
  const url = window.URL.createObjectURL(blob);
  console.log(url);

  window.open(url);
  // window.URL.revokeObjectURL(url);
};

const handleDownload = (blob, name = "pdfs.zip") => {
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = name; // Name for the downloaded ZIP file
  a.click();
  window.URL.revokeObjectURL(url);
};

export const handleCreatePDF = async (
  actionType = "singlePDF",
  returnType = "previewAndPrint",
  htmlContent = "<div>no content</div>",
  letterhead = "",
  htmlContentBulk = [],
  bulk_name = "pdfs"
) => {
  //

  try {
    console.log(htmlContent);
    console.log(actionType);
    console.log(htmlContentBulk);

    // if (htmlContent === "" || typeof htmlContent !== "string") {
    //   console.log("nothing to print");
    //   return;
    // }

    if (
      actionType === "singlePDF" ||
      actionType === "singlePDFWithLetterhead"
    ) {
      if (typeof htmlContent !== "string" || htmlContent == "") {
        console.log("nothing to print");
        return;
      }
    }

    if (actionType === "BulkPDF" || actionType === "BulkPDFWithLetterhead") {
      if (typeof htmlContentBulk !== "object" || htmlContentBulk.length < 1) {
        console.log("nothing to print");
        return;
      }
    }

    if (typeof htmlContent === "string")
      htmlContent = await getHtmlWithImageActualData(htmlContent);

    let hostname = getHostName();

    if (actionType === "singlePDF") {
      //
      let res = await axios.post(
        `${hostname}/api/utils/generate-pdf`,
        {
          html: htmlContent,
        },
        { responseType: "blob" }
      );

      if (returnType === "previewAndPrint") {
        handlePreviewAndPrint(res.data);
        return;
      }

      if (returnType === "downlad") {
        handleDownload(res.data);
        return;
      }

      //
    }

    if (actionType === "singlePDFWithLetterhead") {
      //
      let res = await axios.post(
        `${hostname}/api/utils/generate-pdf-with-letterhead`,
        {
          html: htmlContent,
          letterhead,
        },
        {
          responseType: "blob",
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(res.data);

      if (returnType === "previewAndPrint") {
        handlePreviewAndPrint(res.data);
        return;
      }

      if (returnType === "downlad") {
        handleDownload(res.data);
        return;
      }
    }

    if (actionType === "BulkPDF") {
      let res = await axios.post(
        `${hostname}/api/utils/generate-bulk-pdf`,
        {
          pdfDataList: htmlContentBulk,
          bulk_name,
        },
        { responseType: "blob" }
      );

      console.log(res);

      handleDownload(res.data, bulk_name);
      return;
    }

    if (actionType === "BulkPDFWithLetterhead") {
      console.log("calling BulkPDFWithLetterhead");

      let res = await axios.post(
        `${hostname}/api/utils/generate-bulk-pdf-with-letterhead`,
        {
          pdfDataList: htmlContentBulk,
          letterhead: letterhead,
          bulk_name: bulk_name,
          listLength: htmlContentBulk.length,
        },
        {
          responseType: "blob",
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(res);

      handleDownload(res.data, bulk_name);

      return;
    }
  } catch (e) {
    console.log(e);
  }
};
