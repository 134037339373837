import React from "react";
import Flex from "../../../../components/common/Flex";
import { Button, Card, Col, Row } from "react-bootstrap";
import UnitOwnersTable from "./UnitOwnersPortalTable";

import axios from "axios";
import { toast } from "react-toastify";

import { Link, useParams } from "react-router-dom";

import Loader from "../../../../components/common/Loader";
import CustomFilterbar from "../../../../components/common/CustomFilterbar";

import OwnersManagementModel from "../../../../components/pages/sharedUI/OwnersManagementModel";
import PaginateComponentCustom from "../../../../components/common/PaginateComponentCustom";

import OwnerDetailComponent from "./userDetails/UserDetailsComponent";
import { capitalize } from "lodash";
import CustomSearchbar from "../../../../components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";
import CustomBreadCrumbs from "../../../common/CustomBreadCrumbs";

const UnitOwnersPortal = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { unit_id } = useParams();

  const [usersList, setUsersList] = React.useState([]);
  const [usersListTmp, setUsersListTemp] = React.useState([...usersList]);

  const [headerData, setHeaderData] = React.useState({
    adminCount: 0,
    userCount: 0,
    activeCount: 0,
    supCount: 0,
  });

  const [loader, setLoader] = React.useState(false);

  // show user details tab
  const [showOwnersDetailTab, setShowOwnersDetailTab] = React.useState(false);

  const handleShowOwnersDetailTabFun = () => setShowOwnersDetailTab(true);
  const handleCloseOwnersDetailTabFun = () => setShowOwnersDetailTab(false);

  const handleShowOwnersDetailTab = (user) => {
    setPayload(user);
    handleShowOwnersDetailTabFun();
  };

  // owners mgmt model
  const [showOwnersManagementModel, setShowOwnersManagementModel] =
    React.useState(false);

  const handleCloseOwnersManagementModel = () =>
    setShowOwnersManagementModel(false);
  const handleShowOwnersManagementModel = () =>
    setShowOwnersManagementModel(true);

  const [payload, setPayload] = React.useState({});

  const [eConsentData, setEconsentData] = React.useState({});

  // add resident

  const addResident = () => {
    let obj = {};
    obj.loadType = "Add Resident";
    obj.unit_id = unit_id;
    setPayload(obj);
    handleShowOwnersManagementModel();
  };

  // reinvite user

  const reinviteUser = (user) => {
    user.loadType = "Reinvite User";
    user.unit_id = unit_id;
    console.log(user);

    setPayload(user);
    handleShowOwnersManagementModel();
  };

  const changeOwner = (user) => {
    user.loadType = "Change Owner";
    user.unit_id = unit_id;
    setPayload(user);
    handleShowOwnersManagementModel();
  };

  const deactivateUser = (user) => {
    user.loadType = "Deactivate User";
    user.unit_id = unit_id;
    setPayload(user);
    handleShowOwnersManagementModel();
  };

  const activateUser = (user) => {
    user.loadType = "Activate User";
    user.unit_id = unit_id;
    setPayload(user);
    handleShowOwnersManagementModel();
  };

  const deleteInvitedUser = (user) => {
    user.loadType = "Delete Invited User";
    user.unit_id = unit_id;
    setPayload(user);
    handleShowOwnersManagementModel();
  };

  React.useEffect(() => {
    setLoader(true);
    getUsersData();
    getEConsentValueFromDB();
  }, []);

  const getUsersData = () => {
    if (unit_id) {
      axios
        .post(
          `${hostname}/api/owner/GetAllResidents`,
          {
            unit_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);

          // streamline data for last 3
          let namesOfUsers = [
            "SupplemantryOwners",
            "Tenantsinfo",
            "SupplemantryTenants",
          ];
          let listOfUsers = [];

          namesOfUsers.forEach((userGroup, i) => {
            res.data[userGroup]?.forEach((userObj) => {
              let user = { ...userObj };
              delete user._id;
              let user_id = { ...userObj.user_id };
              console.log(user);

              if (user.group === "Supplementary") {
                user.type = "sup_owner";
                user.role = { title: "Supplementary Owner", type: "info" };
              }
              if (user.group === "Supplementary Tenant") {
                user.type = "sup_owner";
                user.role = { title: "Supplementary Tenant", type: "success" };
              }
              // if (i === 1) {
              //   user.type = "primary_tenant";
              //   user.role = { title: "Primary tenant", type: "success" };
              // }
              // if (i === 2) {
              //   user.type = "sup_tenant";
              //   user.role = {
              //     title: "Supplementary Tenant",
              //     type: "bg-custom",
              //   };
              // }
              delete user.user_id;
              listOfUsers.push({ ...user_id, ...user });
            });
          });

          console.log(listOfUsers);
          let invitedData = res.data.Invited?.map((v) => {
            let name = v.email.split("@")[0];
            console.log(v);

            let role = { title: "Invited Owner", type: "danger" };

            // if (v.group === "Supplementary Tenant")
            //   role = { title: "Supplementary Owner", type: "info" };
            if (v.group === "Supplementary")
              role = { title: "Supplementary Owner", type: "info" };
            if (v.group === "Supplementary Tenant")
              role = { title: "Supplementary Tenant", type: "success" };
            // if (v.group === "Supplementary Tenant")
            //   role = { title: "Supplementary Tenant", type: "bg-custom" };

            return {
              type: "invited_user",
              ...v,
              firstname: capitalize(name),
              lastname: ".",
              role,
            };
          });

          // merge data
          let usersAll = [
            {
              ...res.data.PrimaryOwner,
              type: "primary_owner",
              role: { title: "Primary Owner", type: "Primary" },
            },
            ...invitedData,
            ...listOfUsers,
          ];

          usersAll = usersAll?.map((user) => {
            if (user.resident_status === "Invited")
              return {
                status: { type: "info", title: "Invited", icon: "redo" },
                ...user,
              };
            if (user.resident_status === "Active")
              return {
                status: { type: "success", title: "Active", icon: "check" },
                ...user,
              };
            if (user.resident_status === "Revoked")
              return {
                status: { type: "warning", title: "Revoked", icon: "ban" },
                ...user,
              };
            if (user.resident_status === "Ignored")
              return {
                status: { type: "secondary", title: "Ignored", icon: "redo" },
                ...user,
              };
            return {
              status: { type: "secondary", title: "Ignored", icon: "redo" },
              ...user,
            };
          });

          // console.log(usersAll);

          usersAll = usersAll?.map((v) => {
            let obj = { ...v };
            if (obj._id === userData.data) obj.isLoggedInUser = true;
            else obj.isLoggedInUser = false;
            return { ...obj };
          });

          setUsersList([...usersAll]);
          setUsersListTemp([...usersAll]);

          let totalUsers = usersAll.length;
          let active = usersAll.filter(
            (v) => v.status?.title === "Active"
          ).length;

          let sup = usersAll.filter((v) => v.status?.title === "Active").length;

          setHeaderData({
            userCount: totalUsers,
            activeCount: active,
            adminCount: 1,
            supCount: sup,
          });

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const inviteUser = (data_user = {}, group = "") => {
    // console.log(data_user);
    if (group) {
      let colors = [
        "purple",
        "black",
        "red",
        "blue",
        "orange",
        "crimson",
        "blueviolet",
        "brown",
        "slateblue",
        "chocolate",
        "coral",
        "cornflowerblue",
        "sienna",
        "rebeccapurple",
      ];

      let rn = Math.floor(Math.random() * colors.length);

      let data = {
        firstname: data_user.firstname,
        c_id: data_user.c_id,
        email: data_user.email,
        group: group,
        bgColor: colors[rn],
      };
      // console.log(data);
      // return;

      axios
        .post(
          `${hostname}/api/auth/inviteUser`,
          { data },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then(() => {
          // console.log(res.data);
          setTimeout(() => {
            toast.success(
              <span className="text-white">{`invite sent to ${data_user.email}.`}</span>
            );
          }, 500);
          getUsersData(userData.compid);
        })
        .catch((err) => {
          console.log(err.response.data.error);
          toast.error(
            <span className="text-white">
              {err.response.data.error || "Error occured!"}
            </span>
          );
        });
    }
  };

  const resendInvite = (data_user = {}) => {
    // console.log(data_user);
    let data = {
      firstname: data_user.firstname,
      c_id: data_user.c_id,
      email: data_user.email,
      group: data_user.groups[0],
      bgColor: data_user.bgColor,
      invitee_email: userData.email,
    };
    // console.log(data);
    // return;
    axios
      .post(
        `${hostname}/api/auth/resendinvite`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(() => {
        // console.log(res.data);
        setTimeout(() => {
          toast.success(
            <span className="text-white">{`invite sent to ${data_user.email}.`}</span>
          );
        }, 500);
        getUsersData(userData.compid);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        toast.error(
          <span className="text-white">
            {err.response.data.error || "Error occured!"}
          </span>
        );
      });
  };

  const getEConsentValueFromDB = () => {
    axios
      .post(
        `${hostname}/api/property/ShowEConsent`,
        {
          user_id: userData.data,
          unit_id: unit_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setEconsentData(res.data.data);
        // if (res.data.data.eConsentShown) handleShowEConsentModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loader) return <Loader />;

  if (showOwnersDetailTab)
    return (
      <OwnerDetailComponent
        handleCloseUserDetailTab={handleCloseOwnersDetailTabFun}
        currentUser={payload}
        payload={payload}
        setPayload={setPayload}
        getUsersData={getUsersData}
        userData={userData}
        eConsentData={eConsentData}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    );

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <CustomBreadCrumbs
            links={[
              { path: "/dashboard", label: "Home" },

              { path: "", label: "Residents" },
            ]}
          />
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Residents</h4>
            {/* <Button variant="primary" size="sm" onClick={handleShow}>
              test modal
            </Button> */}

            {userData.role === "unit_owner" && (
              <Button variant="primary" size="sm" onClick={addResident}>
                + Add Resident
              </Button>
            )}
          </Flex>

          <Row>
            <Col md="3">
              <Flex justifyContent={"between"} alignItems={"start"}>
                <h6>Total Residents</h6>
                <h6>Active Residents</h6>
                {/* <h6>PrimaryOwner</h6> */}
                {/* <h6>Supplementary </h6> */}
              </Flex>
              <Flex
                justifyContent={"between"}
                alignItems={"start"}
                className="px-5"
              >
                <h5 className="text-primary">{headerData.userCount || "0"}</h5>
                <h5 className="text-primary">
                  {headerData?.activeCount || "0"}
                </h5>
                {/* <h5 className="text-primary">{headerData.adminCount || "0"}</h5> */}
                {/* <h5 className="text-primary">{headerData.supCount || "0"}</h5> */}
              </Flex>
            </Col>
          </Row>

          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomFilterbar
                filterFlags={[
                  "Status Active",
                  "Status Invited",
                  "Status Ignored",
                  "Status Revoked",
                ]}
                dataListTemp={usersListTmp}
                stateUpdator={setUsersList}
                from="User Owners Table"
              />

              <CustomSearchbar
                searchFlags={[
                  "firstname",
                  "lastname",
                  "email",
                  "role.title",
                  "status.title",
                ]}
                dataListTemp={usersListTmp}
                stateUpdator={setUsersList}
                placeholders={["name", "email", "role"]}
              />
            </Col>
          </Row>

          <PaginateComponentCustom
            itemsPerPage={20}
            dataList={usersList}
            TableElement={UnitOwnersTable}
            inviteUser={inviteUser}
            resendInvite={resendInvite}
            adminCount={headerData.adminCount}
            reinviteUser={reinviteUser}
            changeOwner={changeOwner}
            deactivateUser={deactivateUser}
            activateUser={activateUser}
            handleShowOwnersDetailTab={handleShowOwnersDetailTab}
            deleteInvitedUser={deleteInvitedUser}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />

          <br />
          <OwnersManagementModel
            show={showOwnersManagementModel}
            handleClose={handleCloseOwnersManagementModel}
            userData={userData}
            getUsersData={getUsersData}
            payload={payload}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default UnitOwnersPortal;
