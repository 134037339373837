import React from "react";

import { Table, Dropdown } from "react-bootstrap";
import CardDropdown from "../../../../../../common/CardDropdown";

const TableRow = ({ data, detachUnit, userData }) => {
  return (
    <tr
      className="align-middle"
      // style={{ 'height': '100px' }}
    >
      <td className="text-center">{data.unit_number || "id"}</td>
      <td className="text-center">{data.unit_type || "type"}</td>

      {(userData.role === "Site-Administrators" ||
        userData.role === "Condozak-Basic-Users") && (
        <td className="text-center">
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                className="text-danger"
                onClick={() => detachUnit(data)}
              >
                Detach
              </Dropdown.Item>
            </div>
          </CardDropdown>
        </td>
      )}
    </tr>
  );
};

const EmptyRow = () => {
  return (
    <tr className="text-center text-info fw-bold fs-1">
      <td colSpan={4}>Nothing Found!</td>
    </tr>
  );
};

const AttachedUnitsTable = ({ dataList = [], detachUnit, userData = {} }) => {
  // if (userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") {
  //properties = properties?.filter((v) => v.unit_type !== "condo");
  // }

  return (
    <Table
      responsive
      striped
      hover
      bordered
      style={{ fontSize: "14px" }}
      className="pb-5"
    >
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Unit Number
          </th>
          <th scope="col" className="text-center">
            Unit Type
          </th>

          {userData.role === "Site-Administrators" && (
            <th scope="col" className="text-center">
              Action
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {dataList?.length ? (
          dataList?.map((property, index) => (
            <TableRow
              data={property}
              key={index}
              propLength={dataList.length}
              detachUnit={detachUnit}
              userData={userData}
            />
          ))
        ) : (
          <EmptyRow />
        )}
      </tbody>
    </Table>
  );
};

export default AttachedUnitsTable;
