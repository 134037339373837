import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import NavbarTop from "../components/navbar/top/NavbarTop";
import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
import Footer from "../components/footer/Footer";
// import ProductProvider from "../components/app/e-commerce/ProductProvider";
// import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const MainLayout = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  let redirect_link = cookies.get("_expire_l") || "/login";
  let jwtToken = cookies.get("_expire_t");
  // console.log(jwtToken);
  // console.log(redirect_link);

  const userData = jwtToken ? jwtDecode(jwtToken) : "";
  //console.log(userData);

  const { pathname } = useLocation();

  React.useEffect(() => {
    const checkTokenExpiration = () => {
      if (!jwtToken) {
        navigate(redirect_link);
        return;
      }

      const decoded_jwt = jwtDecode(jwtToken);
      
      const expirationTime = decoded_jwt.exp; // Expiration time in seconds
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      console.log(expirationTime);
      console.log(currentTime);

      // If the token has expired
      if (currentTime > expirationTime) {
        toast.warning("Session Disconnected!", { autoClose: false });
        cookies.remove("_expire_t", { path: "/" });
        cookies.remove("_expire_l", { path: "/" });

        setTimeout(() => {
          window.location.href = redirect_link;
        }, 400); // Small delay for showing the toast
      }
    };

    // Check the token immediately on component mount
    checkTokenExpiration();

    // Periodically check the token every minute
    const interval = setInterval(checkTokenExpiration, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [jwtToken, navigate]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (
    !userData ||
    userData === "undefined" ||
    userData === undefined ||
    userData === ""
  )
    return <Navigate to={redirect_link} />;
  else
    return (
      <div className="container-fluid">
        <NavbarVertical userData={userData} redirect_link={redirect_link} />

        {/* <ProductProvider> */}
        <div className="content">
          <NavbarTop userData={userData} />
          {/*------ Main Routes ------*/}
          <Outlet />
          <Footer />
        </div>
        {/* </ProductProvider> */}
      </div>
    );
};

export default MainLayout;

// import React from "react";
// import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
// import NavbarTop from "../components/navbar/top/NavbarTop";
// import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
// import Footer from "../components/footer/Footer";
// // import ProductProvider from "../components/app/e-commerce/ProductProvider";
// // import useAuthUser from "react-auth-kit/hooks/useAuthUser";
// import jwtDecode from "jwt-decode";
// import Cookies from "universal-cookie";
// import { toast } from "react-toastify";

// const MainLayout = () => {
//   const cookies = new Cookies();
//   const navigate = useNavigate();

//   let redirect_link = cookies.get("_expire_l") || "/login";
//   let jwtToken = cookies.get("_expire_t");
//   // console.log(jwtToken);
//   // console.log(redirect_link);

//   const userData = jwtToken ? jwtDecode(jwtToken) : "";
//   //console.log(userData);

//   const { pathname } = useLocation();

//   React.useEffect(() => {
//     const checkTokenExpiration = () => {
//       // console.log("validating token");

//       let redirect_link = cookies.get("_expire_l") || "/login";
//       const decoded_jwt = jwtToken ? jwtDecode(jwtToken) : "";

//       // Adjust this based on your authName
//       const expirationTime = decoded_jwt.exp;

//      console.log(expirationTime);

//       const iatTime = decoded_jwt.iat;
//       let tokenTime = expirationTime - iatTime;
//       // let minutesLeft = seconds / 60;

//       // console.log('minuts left',minutesLeft);

//      // console.log(expirationTime);

//       // Current time in seconds
//       const currentTime = Math.floor(Date.now() / 1000);

//       console.log(currentTime);

//       console.log(expirationTime - currentTime);

//       let totalTime = tokenTime + currentTime;

//       totalTime = +totalTime * 1000;

//      // console.log(totalTime);

//       // setTimeout(() => {
//       //   console.log('session expired! login again.');

//       // }, totalTime);

//       if (!jwtToken) {
//         navigate(redirect_link);
//         return;
//       }
//       // if (minutesLeft < 0) {
//       if (jwtToken && currentTime > expirationTime) {
//         toast.warning("Session Disconnected!", { autoClose: false });
//         cookies.remove("_expire_t", { path: "/" });
//         cookies.remove("_expire_l", { path: "/" });

//         setTimeout(() => {
//           window.location.href = redirect_link;
//         }, 400);
//       }
//     };

//     setTimeout(() => {
//       checkTokenExpiration();
//     }, 10000);

//     // Check every 60 seconds
//     const interval = setInterval(checkTokenExpiration, 60000);

//     return () => clearInterval(interval);
//   }, [jwtToken, navigate]);

//   React.useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   if (
//     !userData ||
//     userData === "undefined" ||
//     userData === undefined ||
//     userData === ""
//   )
//     return <Navigate to={redirect_link} />;
//   else
//     return (
//       <div className="container-fluid">
//         <NavbarVertical userData={userData} redirect_link={redirect_link} />

//         {/* <ProductProvider> */}
//         <div className="content">
//           <NavbarTop userData={userData} />
//           {/*------ Main Routes ------*/}
//           <Outlet />
//           <Footer />
//         </div>
//         {/* </ProductProvider> */}
//       </div>
//     );
// };

// export default MainLayout;

// import React from "react";
// import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
// import NavbarTop from "../components/navbar/top/NavbarTop";
// import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
// import Footer from "../components/footer/Footer";
// import jwtDecode from "jwt-decode";
// import Cookies from "universal-cookie";
// import { toast } from "react-toastify";

// const MainLayout = () => {
//   const cookies = new Cookies();
//   const navigate = useNavigate();
//   const { pathname } = useLocation();

//   let redirect_link = cookies.get("_expire_l") || "/login";
//   let jwtToken = cookies.get("_expire_t");
//   const userData = jwtToken ? jwtDecode(jwtToken) : null;

//   React.useEffect(() => {
//     const checkTokenExpiration = () => {
//       console.log("validating token");

//       // Decode token if it exists
//       const decoded_jwt = jwtToken ? jwtDecode(jwtToken) : null;

//       // Check if the token has expired
//       const expirationTime = decoded_jwt ? decoded_jwt.exp : 0;
//       const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//       if (!jwtToken || currentTime >= expirationTime) {
//         toast.warning("Session Disconnected!", { autoClose: false });
//         cookies.remove("_expire_t", { path: "/" });
//         cookies.remove("_expire_l", { path: "/" });

//         // Use navigate for redirection
//         setTimeout(() => {
//           navigate(redirect_link, { replace: true });
//         }, 400);
//       }
//     };

//     // Check immediately on load
//     checkTokenExpiration();

//     // Then check every 60 seconds
//     const interval = setInterval(checkTokenExpiration, 60000);

//     return () => clearInterval(interval);
//   }, [jwtToken, navigate, redirect_link]);

//   React.useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   if (!userData) return <Navigate to={redirect_link} replace />;

//   return (
//     <div className="container-fluid">
//       <NavbarVertical userData={userData} redirect_link={redirect_link} />
//       <div className="content">
//         <NavbarTop userData={userData} />
//         <Outlet />
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default MainLayout;
