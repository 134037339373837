import React from "react";
// import PropTypes from "prop-types";
import Flex from "../../../../../components/common/Flex";
// import Avatar from 'components/common/Avatar';
import { Link } from "react-router-dom";
// import createMarkup from "helpers/createMarkup";
import { Avatar } from "@mui/material";
import { timeAgo } from "../../../../../helpers/utils";
import { Button } from "react-bootstrap";

const Comments = ({
  comments = [],
  userData = {},
  updateComment,
  deleteComment,
  totalComments = 0,
  loadMoreComments,
  post_id = "",
}) => {
  return (
    <>
      {comments?.map(
        ({
          _id = "",
          user_id = { firstname: "", lastname: "" },
          text = "",
          createdAt = "",
          isEdited = false,
        }) => (
          <div key={_id}>
            <Flex className="mt-3">
              <Link to="/user/profile">
                <Avatar
                  sx={{
                    bgcolor: user_id?.bgColor || "silver",
                    fontSize: "14px",
                  }}
                  className="img-thumbnail"
                >
                  {user_id?.firstname[0] + user_id?.lastname[0]}
                </Avatar>
              </Link>
              <div className="flex-1 ms-2 fs--1">
                <p className="mb-1 bg-200 rounded-3 p-2">
                  <Link className="fw-semi-bold" to="/user/profile">
                    {user_id?.firstname + user_id?.lastname}
                  </Link>
                  <span className="ms-1">{text}</span>
                </p>
                <div className="px-2">
                  {userData.data === user_id?._id &&
                    (userData.role === "unit_owner" || userData.role === "supp_resident" || userData.role === "supp_tenant") && (
                      <>
                        <a
                          href="#!"
                          onClick={() =>
                            updateComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Edit
                        </a>{" "}
                        •{" "}
                        <a
                          href="#!"
                          onClick={() =>
                            deleteComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Delete
                        </a>{" "}
                        •{" "}
                      </>
                    )}
                  {userData.data === user_id._id &&
                    userData.role === "Site-Administrators" && (
                      <>
                        <a
                          href="#!"
                          onClick={() =>
                            updateComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Edit
                        </a>{" "}
                        •{" "}
                        <a
                          href="#!"
                          onClick={() =>
                            deleteComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Delete
                        </a>{" "}
                        •{" "}
                      </>
                    )}
                  {userData.data === user_id._id &&
                    userData.role === "Condozak-Basic-Users" && (
                      <>
                        <a
                          href="#!"
                          onClick={() =>
                            updateComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Edit
                        </a>{" "}
                        •{" "}
                        <a
                          href="#!"
                          onClick={() =>
                            deleteComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Delete
                        </a>{" "}
                        •{" "}
                      </>
                    )}
                  {userData.data !== user_id._id &&
                    userData.role === "Site-Administrators" && (
                      <>
                        <a
                          href="#!"
                          onClick={() =>
                            deleteComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Delete
                        </a>{" "}
                        •{" "}
                      </>
                    )}
                  {userData.data !== user_id._id &&
                    userData.role === "Condozak-Basic-Users" && (
                      <>
                        <a
                          href="#!"
                          onClick={() =>
                            deleteComment({ comment_id: _id, oldText: text },post_id)
                          }
                        >
                          Delete
                        </a>{" "}
                        •{" "}
                      </>
                    )}
                  {timeAgo(createdAt)}
                  {isEdited ? <> • (Edited)</> : ""}
                </div>
              </div>
            </Flex>
          </div>
        )
      )}
      {/* {comments.map(({ id, avatarSrc, name, content, postTime }) => (
        <div key={id}>
          <Flex className="mt-3">
            <Link to="/user/profile">
              <Avatar src={avatarSrc} size="xl" />
            </Link>
            <div className="flex-1 ms-2 fs--1">
              <p className="mb-1 bg-200 rounded-3 p-2">
                <Link className="fw-semi-bold" to="/user/profile">
                  {name}
                </Link>
                <span
                  className="ms-1"
                  dangerouslySetInnerHTML={createMarkup(content)}
                />
              </p>
              <div className="px-2">
                <a href="#!">Like</a> • <a href="#!">Reply</a> • {postTime}
              </div>
            </div>
          </Flex>
        </div>
      ))} */}
      {/* {totalComments > 4 && (
        <div className="text-center " onClick={() => loadMoreComments(post_id)}>
          <Button
            variant="falcon-default"
            className=" text-700 d-inline-block mt-2"
          >
            Load more comments
          </Button>
        </div>
      )} */}
    </>
  );
};

export default Comments;
