import { useState, useEffect } from "react";

import Flex from "../../../../../../../components/common/Flex";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import MailOutsTable from "./MailOutsTable";

import axios from "axios";

import { Link, useParams } from "react-router-dom";
import CustomSearchbar from "../../../../../../../components/common/CustomSearchbar";
import Loader from "../../../../../../../components/common/Loader";
import { getHostName } from "../../../../../../../helpers/utils";
import { startCase } from "lodash";
import CustomBreadCrumbs from "../../../../../../common/CustomBreadCrumbs";

const UnitHistory = () => {
  const { property_id, property_name, unit_id, unit_number } = useParams();

  let hostname = getHostName();

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesDataTemp, setPropertiesDataTemp] = useState([
    ...propertiesData,
  ]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getAllPropertiesData();
  }, []);

  const getAllPropertiesData = () => {
    axios
      .post(`${hostname}/api/property/UnitHistory`, {
        unit_id,
      })
      .then((res) => {
        setLoader(false);
        // console.log(res.data);
        setPropertiesData([...res.data.data]);
        setPropertiesDataTemp([...res.data.data]);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
          <CustomBreadCrumbs
              links={[
                { path: "/dashboard", label: "Home" },
                {
                  path: "/property/management/buildings",
                  label: "Properties",
                },
                {
                  path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                  label: startCase(property_name),
                },
                {
                  path: `/property/management/buildings/${property_id}/${property_name}/units/1`,
                  label: "Units",
                },
                {
                  path: `/${property_id}/${property_name}/${unit_number}/${unit_id}/attached-units`,
                  label: `${unit_number}`,
                },
                { path: "", label: "Unit History" },
              ]}
            />
            {/* <Card>
              <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to="/property/management/buildings"
                >
                  Properties
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {property_name}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/units`}
                >
                  Units
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-primary"
                  // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/history`}
                  to="#!"
                >
                  {unit_number}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-primary"
                  // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                  to={"#!"}
                >
                  Unit History
                </Link>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
        <Card>
          <Card.Body className="" style={{ minHeight: "90vh" }}>
            <Flex justifyContent={"between"} alignItems={"start"} className="">
              <h4>Unit History</h4>
              {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              {/* <Button variant="primary" size="sm" onClick={handleShow}>
                New Mail
              </Button> */}
            </Flex>
            <br />
            {/* <Row>
              <Col md="6">
                <p>long long text for property management</p>
              </Col>
            </Row> */}
            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomSearchbar
                  searchFlags={["action", "description", "invited_user_email"]}
                  dataListTemp={propertiesDataTemp}
                  stateUpdator={setPropertiesData}
                  placeholders={["action", "description", "invited_user_email"]}
                />
              </Col>
            </Row>

            <MailOutsTable list={propertiesData} />
            <br />
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default UnitHistory;
