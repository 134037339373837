import { Image, PostAdd } from "@mui/icons-material";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";

import React from "react";
import { Card, Col, Row, Form as BSForm, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import Cookies from "universal-cookie";
import classNames from "classnames";

import FeedCardHeader from "./FeedCardHeader";
import createMarkup from "../../../../../helpers/createMarkup";
// import av3 from "assets/img/team/2.jpg";
import Flex from "../../../../../components/common/Flex";
// import Avatar from "../../../../../components/common/Avatar";

import FeedActionButtons from "./FeedActionButtons";
import FeedReactions from "./FeedReactions";
import Comments from "./Comments";
import { Gallery } from "react-grid-gallery";

import axios from "axios";
import BulletinBoardModal from "./BulletinBoardModal";
import { toast } from "react-toastify";
import { sortByLatest } from "../../../../../helpers/utils";

import PreviewFileComp from "../../../../../components/common/PreviewFileComp";

import { OwnerEventsList } from "../../../../../components/dashboards/default/OwnerFiles";
import { Avatar } from "@mui/material";

import Loader from "../../../../../components/common/Loader";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../helpers/utils";
import CustomBreadCrumbs from "../../../../common/CustomBreadCrumbs";
import { startCase } from "lodash";

export default function BulletinBoard({ from = "owner" }) {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, post_id } = useParams();

  let p_id = "";
  if (
    userData.role === "Site-Administrators" ||
    userData.role === "Condozak-Basic-Users"
  ) {
    p_id = property_id;
  }
  if (
    userData.role === "unit_owner" ||
    userData.role === "supp_resident" ||
    userData.role === "supp_tenant"
  ) {
    p_id = userData.propid;
  }

  //main data
  const [postsList, setPostsList] = React.useState([]);
  const [postsListTemp, setPostsListTemp] = React.useState([]);

  // previewr state
  const [previewModel, setPreviewModel] = React.useState({
    state: false,
    file: "",
  });

  const handleShowPreviewComponent = (file) => {
    setPreviewModel({
      state: true,
      file: { mimetype: file.mimetype, data: file.data },
    });
  };

  const handleClosePreviewComponent = () => {
    setPreviewModel({ state: false, file: "" });
  };

  //main modal
  const [showModal, setShowModal] = React.useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  //main action
  const [loader, setLoader] = React.useState(true);
  const [payload, setPayload] = React.useState({});

  const [specificPostIdToScroll, setSpecificPostIdToScroll] =
    React.useState(null);

  const scrollRef = React.useRef(null);

  // skipper in db
  const [pagerCursor, setCurrentPagerCursor] = React.useState({
    frontValue: 0,
    dbValue: 0,
    loadMoreValue: 0,
  });

  React.useEffect(() => {
    getAllPostsData();
  }, []);

  const transformPostImages = (post) => {
    if (post.post_images?.length > 0) {
      return post.post_images.map((v) => {
        const src = v instanceof File ? URL.createObjectURL(v) : v.data;
        return {
          src, // Full-size image URL
          thumbnail: src, // Use the same URL for the thumbnail (can customize later)
          thumbnailWidth: 350, // Adjust based on your layout needs
          thumbnailHeight: 200, // Adjust based on your layout needs
        };
      });
    }
    return [];
  };

  const getAllPostsData = async () => {
    setLoader(true);
    console.log("Current Pager Cursor:", pagerCursor);

    try {
      const response = await axios.post(
        `${hostname}/api/property/readAllPosts`,
        {
          p_id,
          skiper: pagerCursor.frontValue, // initially latest 10 i.e. 0
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      const data = response.data.Data || [];
      const transformedPosts = data.map((post) => ({
        ...post,
        galleryImgs: transformPostImages(post),
        skiper: -14,
      }));

      const sortedPosts = sortByLatest(transformedPosts, "createdAt");

      setPostsList(sortedPosts);
      setCurrentPagerCursor({
        frontValue: response.data.skiper,
        dbValue: response.data.skiper,
        loadMoreValue: response.data.skiper,
      });
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoader(false);
    }
  };

  const removeDoublesAndSortAndUpdateState = (arr) => {
    const uniquePosts = removeDublicates(arr);
    const sortedPosts = sortByLatest(uniquePosts, "createdAt");

    const transformedPosts = sortedPosts.map((post) => ({
      ...post,
      galleryImgs: transformPostImages(post),
    }));

    setPostsList(transformedPosts);
  };

  // called only one time
  // const getAllPostsData = () => {
  //   //  setLoader(true);

  //   console.log(pagerCursor);

  //   axios
  //     .post(
  //       `${hostname}/api/property/readAllPosts`,
  //       {
  //         p_id,
  //         skiper: pagerCursor.frontValue, // initially is latest 10 i.e 0
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       let arr = [...res.data.Data] || [];

  //       arr = arr?.map((post, i) => {
  //         // console.log(post);

  //         let galleryImgs = post.post_images?.map((v) => {
  //           // console.log(v.data);
  //           return { src: v.data, height: 200, width: 350 };
  //         });
  //         arr[i].galleryImgs = galleryImgs;

  //         arr[i].skiper = -14;
  //       });

  //       // console.log(arr);
  //       let arr2 = sortByLatest(arr, "createdAt");
  //       // console.log(arr2);

  //       setPostsList(arr2);
  //       setCurrentPagerCursor({
  //         frontValue: res.data.skiper,
  //         dbValue: res.data.skiper,
  //         loadMoreValue: res.data.skiper,
  //       });
  //       setLoader(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoader(false);
  //     });
  // };

  const updatePostsDataByCursor = (
    action_type = "",
    post_id = "",
    post = {}
  ) => {
    // post_create

    // post_delete

    // comment_add comment_update like_unlike

    // comment_delete

    console.log(action_type);
    console.log(post_id);
    console.log(post);

    if (action_type === "post_create") {
      let posts = [...postsList];
      posts.unshift(post);
      removeDoublesAndSortAndUpdateState(posts);
      return;
    }
    if (action_type === "post_delete") {
      let arr = [...postsList];
      console.log(arr);
      console.log(post_id);

      arr = arr.filter((v) => v._id !== post_id);
      console.log(arr);

      removeDoublesAndSortAndUpdateState(arr);
      return;
    }
    if (
      action_type === "comment_add" ||
      action_type === "comment_update" ||
      action_type === "comment_delete" ||
      action_type === "like_unlike"
    ) {
      let arr = [...postsList];

      let index = 0;
      arr.forEach((v, i) => {
        if (v._id === post_id) index = i;
      });

      console.log(index);
      console.log(arr[index]);
      arr[index] = post;

      removeDoublesAndSortAndUpdateState(arr);

      return;
    }

    if (post_id) {
      setSpecificPostIdToScroll(post_id);
    }
  };

  const getPostsBySkiper = async (skiper) => {
    const res = await axios.post(
      `${hostname}/api/property/readAllPosts`,
      {
        p_id,
        skiper,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    console.log(res.data);
    let arr = [...res.data.Data] || [];
    arr?.forEach((post, i) => {
      // console.log(post);

      let galleryImgs = post.post_images?.map((v) => {
        console.log(v.data);
        return { src: v.data, height: 200, width: 350 };
      });
      arr[i].galleryImgs = galleryImgs;

      arr[i].skiper = -14;
    });
    return arr;
  };

  const getSinglePostData = async (post_id) => {
    const res = await axios.post(
      `${hostname}/api/property/readAllPosts`,
      {
        p_id,
        post_id,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    console.log(res.data);
    let arr = [...res.data.Data] || [];
    arr?.forEach((post, i) => {
      // console.log(post);

      let galleryImgs = post.post_images?.map((v) => {
        console.log(v.data);
        return { src: v.data, height: 200, width: 350 };
      });
      arr[i].galleryImgs = galleryImgs;

      arr[i].skiper = -14;
    });
    return arr;
  };

  // const removeDoublesAndSortAndUpdateState = (arr) => {
  //   let posts = [...arr];
  //   posts = removeDublicates(posts);
  //   let arr2 = sortByLatest(posts, "createdAt");
  //   console.log(arr2);

  //   arr2 = arr2?.map((post, i) => {
  //     if (post.post_images.length > 0) {
  //       // console.log(post);

  //       let galleryImgs = post.post_images?.map((v) => {
  //         // console.log(v.data);
  //         return { src: v.data, height: 200, width: 350 };
  //       });
  //       arr2[i].galleryImgs = galleryImgs;
  //     }

  //     //arr2[i].skiper = -14;
  //   });
  //   setPostsList(arr2);
  // };

  const removeDublicates = (arr) => {
    const ids = arr.map((o) => o._id);
    const filtered = arr.filter(
      ({ _id }, index) => !ids.includes(_id, index + 1)
    );

    // console.log(filtered);
    return filtered;
  };

  React.useEffect(() => {
    if (specificPostIdToScroll && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setSpecificPostIdToScroll(null); // Reset after scrolling
    }
  }, [specificPostIdToScroll, postsList]);

  const createPost = () => {
    setPayload({});

    let obj = {};
    obj.loadType = "Create Post";
    obj.p_id = p_id;
    setPayload(obj);
    handleShow();
  };

  const updatePost = (obj) => {
    setPayload({});
    obj.loadType = "Update Post";
    obj.p_id = p_id;
    setPayload(obj);
    handleShow();
  };

  const deletePost = (obj) => {
    setPayload({});
    console.log(obj);
    obj.loadType = "Delete Post";
    obj.p_id = p_id;
    setPayload(obj);
    handleShow();
  };

  const addComment = (post_id) => {
    setPayload({});
    let obj = {};
    obj.loadType = "Add Comment";
    obj.p_id = p_id;
    obj.post_id = post_id;
    setPayload(obj);
    handleShow();
  };

  const updateComment = (obj,post_id='') => {
    setPayload({});
    obj.loadType = "Update Comment";
    obj.p_id = p_id;
    obj.post_id = post_id;
    setPayload(obj);
    handleShow();
  };

  const deleteComment = (obj,post_id='') => {
    setPayload({});
    obj.loadType = "Delete Comment";
    obj.p_id = p_id;
    obj.post_id = post_id;
    setPayload(obj);
    handleShow();
  };

  const toggleLikeOrUnlike = (post_id = "") => {
    let user_id = userData.data;

    if (!user_id || !post_id) {
      toast.error("invalid entry id", { theme: "colored" });
      return;
    }

    axios
      .post(
        `${hostname}/api/property/LikeUnlikePost`,
        {
          user_id,
          post_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        updatePostsDataByCursor("like_unlike", post_id, res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadMore = () => {
    axios
      .post(
        `${hostname}/api/property/readAllPosts`,
        {
          p_id,
          skiper: pagerCursor.frontValue + 1,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let arr = [...res.data.Data] || [];

        if (arr.length === 0) {
          toast.info("no more posts found!");
          return;
        }

        arr?.forEach((post, i) => {
          let galleryImgs = post.post_images?.map((v) => {
            console.log(v.data);
            return { src: v.data, height: 200, width: 350 };
          });
          arr[i].galleryImgs = galleryImgs;
        });

        console.log(arr);
        let joinArr = [...postsList];
        joinArr = joinArr.concat(arr);
        let arr2 = sortByLatest(joinArr, "createdAt");
        arr2 = removeDublicates(arr2);
        setPostsList(arr2);
        setCurrentPagerCursor({
          frontValue: pagerCursor.frontValue + 1,
          dbValue: res.data.skiper,
          loadMoreValue: res.data.skiper + 1,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadMoreComments = (post_id) => {
    setLoader(true);

    let oldPosts = [...postsList];
    let postIndex = 0;
    let postToUpdate = oldPosts?.filter((v, i) => {
      postIndex = i;
      return v._id === post_id;
    })[0];

    // console.log(postIndex);

    postToUpdate.skiper = Math.abs((postToUpdate.skiper += 10));

    // console.log(postToUpdate);

    axios
      .post(
        `${hostname}/api/property/LoadAllComments`,
        {
          post_id,
          skiper: postToUpdate.skiper,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        postToUpdate.comments = postToUpdate.comments?.concat(res.data.Data);

        oldPosts[postIndex] = postToUpdate;

        setPostsList(oldPosts);
        setPostsListTemp(oldPosts);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  // const [scrollPosition, setScrollPosition] = React.useState(0);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  if (loader) return <Loader />;
  return (
    <div>
      {(userData?.role === "Site-Administrators" ||
        userData?.role === "Condozak-Basic-Users") && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="d-flex flex-column flex-md-row align-items-center justify-content-between ">
                <CustomBreadCrumbs
                  links={[
                    { path: "/dashboard", label: "Home" },
                    {
                      path: "/property/management/buildings",
                      label: "Properties",
                    },
                    {
                      path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                      label: startCase(property_name),
                    },
                    { path: "", label: "Bulletin Board" },
                  ]}
                  noBg
                />
                <div className="">
                  {postsList?.length > 0 && (
                    <>
                      <br className="d-md-none" />

                      <Button className="float-end" onClick={createPost}>
                        + Create Post
                      </Button>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {(userData?.role === "unit_owner" ||
        userData.role === "supp_resident" ||
        userData.role === "supp_tenant") && (
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <CustomBreadCrumbs
                  links={[
                    { path: "/dashboard", label: "Home" },

                    { path: "", label: "Bulletin Board" },
                  ]}
                  noBg
                />
                <div className="">
                  {postsList?.length > 0 && (
                    <Button className="float-end" onClick={createPost}>
                      + Create Post
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Row className="g-3 mb-3">
        <Col lg={8}>
          {postsList?.length < 1 && (
            <Card className="mb-3" onClick={createPost}>
              <FalconCardHeader title="Create Post" />
              <Card.Body className="bg-light" style={{ minHeight: "300px" }}>
                <BSForm.Control
                  as={"textarea"}
                  rows={8}
                  placeholder="What do you want to talk about?"
                />

                <br />
                <p className="text-info">
                  Note: “Your post will be live for 90 days.”
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <Button variant="falcon-primary" onClick={createPost}>
                    <Image /> Images
                  </Button>
                  <Button onClick={createPost}>
                    <PostAdd /> Post
                  </Button>
                </div>
              </Card.Body>
            </Card>
          )}
          {/* {postsList?.length < 1 && (
            <div>
              <p>No New Posts Added</p> 
            </div>
          )} */}

          {postsList?.map((post, index) => (
            <Card
              // ref={scrollRef}
              id={`post_${post._id}`} // Unique id for each post
              ref={post._id === specificPostIdToScroll ? scrollRef : null} // Attach ref to the specific post
              key={post?._id}
              className={classNames({
                "mb-3": index + 1 !== postsList.length,
                "mb-3 mb-lg-0": index + 1 === postsList.length,
              })}
            >
              <FeedCardHeader
                post={post}
                userData={userData}
                updatePost={updatePost}
                deletePost={deletePost}
                from={from}
              />
              <Card.Body className="">
                {!!post.post_text && (
                  <div>
                    <p dangerouslySetInnerHTML={createMarkup(post.post_text)} />
                  </div>
                )}
                {!!post.galleryImgs && post.galleryImgs.length > 0 && (
                  <div>
                    <Gallery images={post.galleryImgs} margin={8} />
                  </div>
                )}
              </Card.Body>

              {/* footer */}
              <Card.Footer className="bg-light pt-0">
                <FeedReactions post={post} />
                <FeedActionButtons
                  post={post}
                  toggleLikeOrUnlike={toggleLikeOrUnlike}
                  userData={userData}
                />
                <BSForm>
                  <Flex
                    alignItems="center"
                    className="border-top border-200 pt-3"
                  >
                    <Avatar
                      sx={{
                        bgcolor: userData.bgColor || "silver",
                        fontSize: "14px",
                      }}
                      className="img-thumbnail"
                    >
                      {userData.firstname[0] + userData.lastname[0]}
                    </Avatar>
                    <BSForm.Control
                      onClick={() => addComment(post._id)}
                      type="text"
                      className="rounded-pill ms-2 fs--1"
                      placeholder="Write a comment..."
                      // value={comment}
                      // onChange={(e) => setComment(e.target.value)}
                    />
                  </Flex>
                </BSForm>
                {!!post.comments && (
                  <Comments
                    comments={post.comments}
                    userData={userData}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                    totalComments={post.comment_count}
                    loadMoreComments={loadMoreComments}
                    post_id={post._id}
                  />
                )}
              </Card.Footer>
            </Card>
          ))}
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <OwnerEventsList
              from={from === "manager" ? "pm-b-board" : "owner-b-board"}
            />
          </div>
        </Col>
      </Row>
      {postsList?.length >= 10 && (
        <div className="text-center my-3">
          <br />
          <br />
          <Button onClick={() => handleLoadMore()}>Load More</Button>
        </div>
      )}
      <br />

      <PreviewFileComp
        file={previewModel.file}
        handleClose={handleClosePreviewComponent}
      />

      <br />
      <BulletinBoardModal
        show={showModal}
        handleClose={handleClose}
        payload={payload}
        userData={userData}
        property_id={p_id}
        // getAllData={getAllPostsData}
        getAllData={updatePostsDataByCursor}
        // updatePostsDataByCursor={updatePostsDataByCursor}
        hostname={hostname}
        jwtToken={jwtToken}
      />
    </div>
  );
}

// {!!post.post_images && (
//   <div>
//     <Gallery
//       images={post.galleryImgs}
//       margin={8}
//       // onClick={(index) =>
//       //   handleShowPreviewComponent(post.post_images[index])
//       // }
//     />
//     {/* {post.post_images?.map((v, i) => (
//       <div key={v.name + i}>{<img src={v.data} />}</div>
//     ))} */}
//   </div>
// )}
