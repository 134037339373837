import classNames from "classnames";
import FalconCardFooterLink from "../../common/FalconCardFooterLink";
import FalconCardHeader from "../../common/FalconCardHeader";
import Flex from "../../common/Flex";
// import PropTypes from "prop-types";
import React from "react";
import { Badge, Card, Col, ProgressBar, Row } from "react-bootstrap";
// import Cookies from "universal-cookie";
// import { uniq, uniqBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { words, startCase } from "lodash";
// import { Badge, Card, Col, Form, ProgressBar, Row } from 'react-bootstrap';

const Property = ({ property, isLast }) => {
  // const { color, progress, duration, title,n,i } = property;

  const navigate = useNavigate();

  const detectWords = (p_name) => {
    let n = words(p_name);

    if (n.length > 2) return true;

    return false;
  };

  const formatCompanyName = (p_name) => {
    let n = words(p_name);
    console.log(n.length);
    if (n.length > 2) {
      let p = `${n[0]} ${n[1]} ...`;
      console.log(p);
      
      return p;
    }

    return startCase(p_name);
  };

  return (
    <Row
      className={classNames("align-items-center py-2 cursor-pointer", {
        "border-bottom border-200 ": !isLast,
      })}
      onClick={() =>
        navigate(
          `/property/management/buildings/${property.property_info._id}/${property.property_info.property_name}/units/1`
        )
      }
    >
      <Col className="py-1" style={{ minWidth: "200px" }}>
        <Flex className="align-items-center">
          <div className="avatar avatar-xl me-3">
            <div className={`avatar-name rounded-circle bg-soft-${"success"}`}>
              <span
                className={`fs-0 text-${"primary".split("-")[1] || "primary"}`}
              >
                {property.property_info.property_name[0]}
              </span>
            </div>
          </div>
          <Flex className="position-relative" wrap="nowrap">
            <Flex align="start" className="mb-0">
              <a
                className="text-800 stretched-link"
                style={{ fontSize: "14px" }}
                href="#!"
              >
                {formatCompanyName(property.property_info.property_name)}
              </a>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto pe-5">
            <Badge pill bg="200" className=" text-primary">
              {property?.owner_linked}
            </Badge>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto pe-0">
            <div className="fs--1 fw-semi-bold">{property?.total_units}</div>
          </Col>
          <Col xs="5" className="pe-card">
            <ProgressBar now={property?.total_units} style={{ height: 5 }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RecentPropertiesTable = ({ recentProperties }) => {
  // console.log(recentProperties);

  return (
    <Card style={{ minHeight: "400px", borderRadius: "5px" }}>
      <div className="d-flex justify-content-between mt-1 px-3">
        <FalconCardHeader
          title="Recent Properties"
          titleTag="h6"
          // endEl={
          //   <Form.Select size="sm" className="me-2">
          //     <option>Working Time</option>
          //     <option>Estimated Time</option>
          //     <option>Billable Time</option>
          //   </Form.Select>
          // }
        />
        <FalconCardHeader title="Occupied" titleTag="h6" />
        <FalconCardHeader title="Total Units" titleTag="h6" />
      </div>

      <Card.Body className="py-0 overflow-x-scroll scrollbar">
        {recentProperties?.map((property, index) => (
          <Property
            property={property}
            isLast={index === recentProperties.length - 1}
            key={property.property_info._id}
          />
        ))}
        {!recentProperties?.length && (
          <div
            style={{ minHeight: "300px" }}
            className="d-flex justify-content-center align-items-center"
          >
            See your most recently viewed properties here.
          </div>
        )}
      </Card.Body>

      <FalconCardFooterLink
        title="Show all properties"
        className={"bg-white"}
        size="sm"
        to={"/property/management/buildings"}
      />
    </Card>
  );
};

export default RecentPropertiesTable;
