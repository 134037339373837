import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import React, { Fragment } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Editor } from "@tinymce/tinymce-react";
import AppContext from "../../../../../context/Context";
import { getColor } from "../../../../../helpers/utils";
import { CloudUpload } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../../../components/common/Loader";

let options = [
  "First_Name",
  "Last_Name",
  "Unit_no",
  "Monthly_Budget",
  "Start_fiscalDate",
  "End_fiscalDate",
  "Total_Budget",
  "Property_Name",
  "Property_Address",
  "Property_Manager",
];

export default function LetterCompose({
  setModelSize,
  // setShowLetterPage,
  mailOutState,
  setMailOutState,
  // mailOutErrors,
  // setMailOutErrors,
  handleFieldValueChange,
  payload,
  setPayload,
  hostname,
  jwtToken,
}) {
  // console.log(payload);

  const {
    config: { isDark },
  } = React.useContext(AppContext);
  const editorRef = React.useRef(null);

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current.dom.addStyle(
        `body{color: ${getColor("white")} !important;}`
      );
    }
  }, [isDark]);

  React.useEffect(() => {
    console.log("(((((((((((((");
  }, [payload]);

  const [editorLoader, setEditorLoader] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSetLetterContent = (value) => {
    console.log(value);
    setMailOutState({ ...mailOutState, letter_content: value });
    // setLetterContent(value);
  };

  const addVariableInBody = async (variableValue = "") => {
    editorRef.current.insertContent(variableValue);
  };

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  // const handleImageUpload = (blobInfo, progress, failure) => {
  const handleImageUpload = (blobInfo) => {
    let imgb64 = blobInfo.base64();
    let arrbfr = base64ToArrayBuffer(imgb64);

    // return new Promise((resolve, reject) => {
    return new Promise((resolve) => {
      axios
        .post(
          `${hostname}/api/property/ImageToLinkAws`,
          {
            p_id: payload.property_id,
            req_image: arrbfr,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          resolve(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleSubmit = () => {
    if (!mailOutState.letter_content) {
      toast.error("Letter body is required!");
      return;
    }

    setMailOutState({ ...mailOutState, step: mailOutState.step + 1 });
    setModelSize("xl");
  };

  const handleStepMinus = () => {
    setMailOutState({ ...mailOutState, step: mailOutState.step - 1 });
  };

  // obj.getCompanyLetterHead = getCompanyLetterHead;
  // obj.getPropertyLetterHead = getPropertyLetterHead;

  const [letterHeadFile, setLetterHeadFile] = React.useState([]);

  const handleFileChange = async (e, uploadCompanyLH = false) => {
    await setLetterHeadFile([]);
    console.log(e.target.files[0]);
    let file = e.target.files[0];

    if (file.type !== "application/pdf") {
      toast.error("Unsupported File Format. Use Pdf Format.", {
        theme: "colored",
        autoClose: "false",
      });
      return;
    }

    if (file.size > 1e6) {
      toast.error("Your file size is too large. Max file limit is 1MB.", {
        theme: "colored",
        autoClose: "false",
      });
      return;
    }

    // return;

    await setLetterHeadFile(file);

    let obj = { ...payload };

    const reader = new FileReader();
    await reader.readAsDataURL(file);
    reader.onload = await function () {
      console.log(reader.result);
      if (uploadCompanyLH)
        obj.companyLetterHead = reader.result?.split("base64,")[1];
      else obj.propertyLetterHead = reader.result?.split("base64,")[1];
    };

    //companyLetterHead
    //propertyLetterHead

    console.log(obj);
    e.target.value = "";
    //return;

    await setPayload(obj);
    handleUploadLetterHead(file, uploadCompanyLH);
  };

  const handleUploadLetterHead = (file, uploadCompanyLH) => {
    // toast.info('working, please wait...',{theme:'colored'});
    // return;
    let dataObj = {};
    console.log(file);
    if (uploadCompanyLH) {
      dataObj.link = "AddLetterHeadPdfCompany";
      dataObj.c_id = payload.userData.compid;
    } else {
      dataObj.link = "AddLetterHeadPdf";
      dataObj.p_id = payload.property_id;
    }
    console.log(dataObj);
    //return;
    axios
      .post(
        `${hostname}/api/property/${dataObj.link}`,
        { req_pdf: file, ...dataObj },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.info("Letterhead uploaded.", { theme: "colored" });
        if (uploadCompanyLH) payload.getCompanyLetterHead();
        else payload.getPropertyLetterHead();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="">
      <Row className="mb-3">
        <Col md={9} className="pe-lg-1">
          <Card>
            <FalconCardHeader title="Draft Letter" />
            <Card.Body style={{ minHeight: "100vh" }} className="bg-light p-0">
              <Row>
                {/* <Col md={6}>
                  <div className="border border-y-0 border-200">
                    <Form.Control
                      type="text"
                      placeholder="File Name"
                      className="border-0 outline-none px-card rounded-0"
                      name="letter_file_name"
                      value={mailOutState.letter_file_name}
                      onChange={handleFieldValueChange}
                    />
                  </div>
                </Col> */}
                <Col md={12}>
                  <div className="border border-y-0 border-200">
                    <Form.Select
                      placeholder="Page type"
                      className="border-0 outline-none px-card rounded-0"
                      name="page_type"
                      value={mailOutState.page_type}
                      onChange={handleFieldValueChange}
                    >
                      <option value={"Blank Page"} selected>
                        Blank Page
                      </option>
                      <option value={"Company LetterHead"}>
                        Company LetterHead
                      </option>
                      <option value={"Property LetterHead"}>
                        Property LetterHead
                      </option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>

              {mailOutState.page_type === "Blank Page" ||
              (mailOutState.page_type === "Company LetterHead" &&
                payload.companyLetterHead) ||
              (mailOutState.page_type === "Property LetterHead" &&
                payload.propertyLetterHead) ? (
                <div className="border border-0 border-200">
                  {editorLoader && <Loader />}
                  <Editor
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                      setEditorLoader(false);
                    }}
                    value={mailOutState.letter_content}
                    onEditorChange={handleSetLetterContent}
                    //  apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                    // initialValue={""}
                    init={{
                      license_key: "gpl",
                      promotion: false,
                      suffix: ".min", // Suffix to use for minified files
                      contextmenu: false,

                      height: "100vh",
                      menubar: false,
                      content_style: `body { color: ${getColor("black")} } 
                      p {margin: 0; padding: 0;} div {margin: 0; padding: 0;}
                      `,
                      // p {margin: 0; padding: 0;}
                      mobile: {
                        theme: "mobile",
                        toolbar: ["undo", "bold"],
                      },
                      statusbar: false,
                      plugins: "link lists table directionality",
                      toolbar:
                        "styleselect | bold italic link bullist numlist blockquote table undo redo",
                      // menubar: "insert",
                      // image_advtab: true,

                      directionality: "ltr",
                      // directionality: isRTL ? 'rtl' : 'ltr',
                      theme_advanced_toolbar_align: "center",
                      // images_upload_handler: handleImageUpload,
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {mailOutState.page_type === "Company LetterHead" &&
                !payload.companyLetterHead && (
                  <div>
                    <label
                      className="d-flex justify-content-center align-items-center fs-2 cursor-pointer"
                      style={{
                        display: "block",
                        borderRadius: "10px",
                        // backgroundColor:'silver',
                        border: "3px dashed #dee2ef",
                        width: "100%",
                        minHeight: "100vh",
                      }}
                    >
                      <input
                        onChange={(e) => handleFileChange(e, true)}
                        type="file"
                        // accept="image/*"
                        hidden
                        className="form-control"
                      />
                      <div className="d-flex flex-column justify-content-center align-items-center ">
                        <CloudUpload
                          fontSize="large"
                          className="text-primary"
                          style={{ fontSize: "60px" }}
                        />
                        <span className="ms-3">
                          company letterhead not found!
                        </span>

                        <span className="ms-3">click to upload here.</span>
                      </div>
                    </label>
                  </div>
                )}
              {mailOutState.page_type === "Property LetterHead" &&
                !payload.propertyLetterHead && (
                  <div>
                    <label
                      className="d-flex justify-content-center align-items-center fs-2 cursor-pointer"
                      style={{
                        display: "block",
                        borderRadius: "10px",
                        // backgroundColor:'silver',
                        border: "3px dashed #dee2ef",
                        width: "100%",
                        minHeight: "100vh",
                      }}
                    >
                      <input
                        onChange={handleFileChange}
                        type="file"
                        // accept="image/*"
                        hidden
                        className="form-control"
                      />
                      <div className="d-flex flex-column justify-content-center align-items-center ">
                        <CloudUpload
                          fontSize="large"
                          className="text-primary"
                          style={{ fontSize: "60px" }}
                        />
                        <span className="ms-3">
                          Property Letterhead not found!
                        </span>

                        <span className="ms-3">Click to upload here.</span>
                      </div>
                    </label>
                  </div>
                )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <FalconCardHeader title="Select Fields" />
            <Card.Body style={{ minHeight: "104vh" }} className="bg-light">
              {options?.map((v, i) => (
                <Fragment key={v + i + ";k"}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>click to add</Tooltip>
                      // <Tooltip>Calculated according to last week's sales</Tooltip>
                    }
                  >
                    <span
                      key={v}
                      // className="mb-4"
                      className="cursor-pointer mb-4"
                      onClick={() => addVariableInBody(`<span>[ ${v} ]</span>`)}
                      // onClick={() => {
                      //   toast.success(
                      //     "copied to clipboard, use CTRL + V to paste",
                      //     { theme: "colored" }
                      //   );
                      //   navigator.clipboard.writeText(` [ ${v} ]`);
                      // }}
                    >
                      [ {v} ]
                    </span>
                  </OverlayTrigger>
                  <br />
                  <br />
                </Fragment>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <br />
      <div>
        <Button variant="secondary" onClick={handleStepMinus}>
          Previous
        </Button>
        {/* {!mailOutState.letter_file_name && (
          <span className="float-end pt-2 text-danger">
            Letter filename is missing!
          </span>
        )} */}
        {/* {mailOutState.letter_file_name && ( */}
        <Button
          disabled={!mailOutState.letter_file_name}
          variant="primary"
          className="float-end"
          onClick={handleSubmit}
        >
          Next
        </Button>
        {/* )} */}
      </div>
    </div>
  );
}
