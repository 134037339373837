import { useState, useEffect } from "react";

import Flex from "../../../../components/common/Flex";
import React from "react";
import {
  Button,
  Card,
  Col,
  // Form,
  Row,
  Breadcrumb,
  Modal,
} from "react-bootstrap";

import MailOutsTable from "./MailOutsTable";

import CreateNewMailModal from "./CreateNewMailModal";

import axios from "axios";

import { Link, useNavigate, useParams } from "react-router-dom";

import Loader from "../../../../components/common/Loader";

import { toast } from "react-toastify";

import CustomFilterbar from "../../../../components/common/CustomFilterbar";

import CustomSearchbar from "../../../../components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";
import CustomBreadCrumbs from "../../../common/CustomBreadCrumbs";
import { startCase } from "lodash";

window.Buffer = window.Buffer || require("buffer").Buffer;

const MailOuts = () => {
  let hostname = getHostName();
  const navigate = useNavigate();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name } = useParams();

  const [mailOutsData, setMailOutsData] = useState([]);
  const [mailOutsDataTemp, setMailOutsDataTemp] = useState([]);
  const [loader, setLoader] = useState(false);

  const [companyLetterHead, setCompanyLetterHead] = useState("");
  const [propertyLetterHead, setPropertyLetterHead] = useState("");

  useEffect(() => {
    setLoader(true);
    getAllMailsData();
    getCompanyLetterHead();
    getPropertyLetterHead();
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);

  // useEffect(() => {
  //   const intervalid = setInterval(() => {
  //     getAllMailsData();
  //   }, 5000);

  //   return () => {
  //     clearInterval(intervalid);
  //   };
  // }, []);

  const navigateToDetails = (mail_id) => {
    navigate(
      `/property/features/${property_id}/${property_name}/mail-outs/${mail_id}/detail`
    );
  };

  const getAllMailsData = () => {
    axios
      .post(
        `${hostname}/api/property/AllMailOuts`,
        {
          p_id: property_id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let arr = res.data?.Data?.map((v) => {
          let obj = { ...v };
          if (obj.status === "In Draft")
            obj.status = { type: "info", icon: "redo", title: "Draft" };
          if (obj.status === "Sent")
            obj.status = { type: "success", icon: "check", title: "Sent" };
          if (obj.status === "Not Sent")
            obj.status = { type: "danger", icon: "ban", title: "Not Sent" };

          // console.log(obj);

          let fileArray = [];
          delete obj.attachments;
          obj.attachments = [];

          v.attachments?.forEach((j) => {
            // console.log(j);
            let file = "";
            let binary = "";
            if (typeof j.data !== "string") {
              const buf = Buffer.from(j.data, "base64");
              for (let i = 0; i < buf.length; i++) {
                binary += String.fromCharCode(buf[i]);
              }

              file = btoa(binary);
            } else {
              file = j.data;
            }
            const newFile = {
              id: j.name + 1 + Date.now(),
              name: j.name,
              size: j.size,
              type: j.mimetype?.split("/")[0],
              mimetype: j.mimetype,
              file: file,
              // data: j.data,
              file_id: j._id,
            };
            fileArray.push(newFile);

            // return btoa(binary);
          });

          // console.log(fileArray);

          obj.attachments = fileArray;

          return obj;
        });
        arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        console.log(arr);
        //console.log(arr);

        setMailOutsData(arr.reverse());
        setMailOutsDataTemp(arr.reverse());

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        console.log(err);
      });
  };

  const getCompanyLetterHead = () => {
    axios
      .post(
        `${hostname}/api/property/GetLetterHeadCompany`,
        {
          c_id: userData.compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCompanyLetterHead(res.data.Data || "");
        if (payload.loadType === "Create New Mail") {
          let obj = { ...payload };
          obj.companyLetterHead = res.data.Data || "";
          setPayload(obj);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPropertyLetterHead = () => {
    axios
      .post(
        `${hostname}/api/property/GetLetterHead`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setPropertyLetterHead(res.data.Data || "");
        if (payload.loadType === "Create New Mail") {
          let obj = { ...payload };
          obj.propertyLetterHead = res.data.Data || "";
          setPayload(obj);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // create mail details

  const [showDetailPage, setShowDetailPage] = useState(false);

  const handleCloseDetailPage = () => {
    console.log(showDetailPage);
    setShowDetailPage(false);
  };
  const handleShowDetailPage = () => setShowDetailPage(true);

  // create mail modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // delete mail
  const [showDeleteMailModal, setShowDeleteMailModal] = useState(false);

  const [payload, setPayload] = React.useState({});

  const createMail = () => {
    let obj = {};
    obj.loadType = "Create New Mail";
    obj.userData = userData;
    obj.userData.p_id = property_id;
    obj.property_id = property_id;
    obj.companyLetterHead = companyLetterHead;
    obj.propertyLetterHead = propertyLetterHead;
    obj.getCompanyLetterHead = getCompanyLetterHead;
    obj.getPropertyLetterHead = getPropertyLetterHead;
    setPayload(obj);
    handleShow();
  };

  const deleteMail = (obj = {}) => {
    setPayload({});
    obj.loadType = "Delete Mail";
    obj.userData = userData;
    obj.userData.p_id = property_id;
    obj.property_id = property_id;
    setPayload(obj);
    setShowDeleteMailModal(true);
  };

  const viewMail = (obj) => {
    if (obj.status.title === "Sent") {
      navigateToDetails(obj._id);
      return;
    }

    setPayload({});
    console.log(obj);
    console.log(obj.status.title);
    obj.loadType = "";
    if (obj.status.title === "Draft") obj.loadType = "Edit Mail Details";
    obj.userData = userData;
    obj.userData.p_id = property_id;
    obj.property_id = property_id;
    obj.property_name = property_name;
    obj.companyLetterHead = companyLetterHead;
    obj.propertyLetterHead = propertyLetterHead;
    obj.getCompanyLetterHead = getCompanyLetterHead;
    obj.getPropertyLetterHead = getPropertyLetterHead;
    obj.letter_drafted = obj.draft_letter;
    console.log(obj);
    setPayload({ ...obj });

    if (obj.status.title === "Draft") handleShow();
    if (obj.status.title === "Sent") handleShowDetailPage();
  };

  const deleteMailFinal = (id) => {
    axios
      .post(
        `${hostname}/api/property/DeleteMailout`,
        {
          mailout_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.warning("Mail Deleted", { theme: "colored" });
        setShowDeleteMailModal(false);
        getAllMailsData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong!", { theme: "colored" });
      });
  };

  if (loader) return <Loader />;
  // if (showDetailPage)
  //   return (
  //     <MailOutDetailsPage
  //       handleCloseDetailPage={handleCloseDetailPage}
  //       payload={payload}
  //       setPayload={setPayload}
  //       userData={userData}
  //       hostname={hostname}
  //       jwtToken={jwtToken}
  //     />
  //   );

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <CustomBreadCrumbs
            links={[
              { path: "/dashboard", label: "Home" },
              {
                path: "/property/management/buildings",
                label: "Properties",
              },
              {
                path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                label: startCase(property_name),
              },
              { path: "", label: "Mail Outs" },
            ]}
          />
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Mail-outs</h4>
            {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
            {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
            <Button variant="primary" size="sm" onClick={createMail}>
             + New Mail
            </Button>
          </Flex>
          <br />
          {/* <Row>
              <Col md="6">
                <p>long long text for property management</p>
              </Col>
            </Row> */}
          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomFilterbar
                filterFlags={["Status Draft", "Status Sent"]}
                dataListTemp={mailOutsDataTemp}
                stateUpdator={setMailOutsData}
                from="MailOuts Table"
              />

              <CustomSearchbar
                searchFlags={["mail_subject", "mail_type", "groups", "status"]}
                dataListTemp={mailOutsDataTemp}
                stateUpdator={setMailOutsData}
                placeholders={["subject", "type", "groups"]}
              />
            </Col>
          </Row>

          <MailOutsTable
            properties={mailOutsData}
            getAllMailsData={getAllMailsData}
            viewMail={viewMail}
            deleteMail={deleteMail}
          />
          <br />
          <CreateNewMailModal
            show={show}
            handleClose={handleClose}
            payload={payload}
            setPayload={setPayload}
            getAllData={getAllMailsData}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />

          <Modal
            show={showDeleteMailModal}
            onHide={() => setShowDeleteMailModal(false)}
            backdrop="static"
            keyboard={false}
            centered
            style={{ backdropFilter: "blur(3px)" }}
          >
            <Modal.Header closeButton className="fs-2">
              {payload.loadType}
            </Modal.Header>
            <Modal.Body>
              <p>Would you like to delete this Mail?</p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  onClick={() => deleteMailFinal(payload._id)}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  className="ms-3"
                  onClick={() => setShowDeleteMailModal(false)}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </Card.Body>
      </Card>
    </>
  );
};

export default MailOuts;
