import { useState, useEffect } from "react";
import Flex from "../../../../components/common/Flex";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import BookedAmenitiesTable from "./BookedAmenitiesTable";

import axios from "axios";

import { Link, useParams } from "react-router-dom";
import PaginateComponentCustom from "../../../../components/common/PaginateComponentCustom";

import CancelBookingModal from "./CancelAmenityModal";
import ReceivePaymentsModel from "../../../../components/pages/sharedUI/PayReceivePaymentsModel";

import BookingDetailsModel from "../../../../components/pages/sharedUI/BookingDetailsModel";
import InvoiceModel from "../../../../components/pages/sharedUI/InvoiceModel";
import CustomSearchbar from "../../../../components/common/CustomSearchbar";
import Loader from "../../../../components/common/Loader";

import CustomBreadCrumbs from "../../../../components/common/CustomBreadCrumbs";
import { startCase } from "lodash";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../helpers/utils";

const BookedAmenities = ({ from = "" }) => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name, unit_number, unit_id } = useParams();

  const [amenitiesList, setAmenitiesList] = useState([]);
  const [amenitiesListTmp, setAmenitiesListTemp] = useState([...amenitiesList]);

  // // invite user modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [booking_id, setBookingID] = useState("");
  const [booking, setBooking] = useState({});

  // for booking details at manager side

  const [showBookingDetailsModal, setShowBookingDetailsModal] =
    React.useState(false);
  const handleShowBookingDetailsModal = () => setShowBookingDetailsModal(true);
  const handleCloseBookingDetailsModal = () =>
    setShowBookingDetailsModal(false);

  // //show detail modal
  const [showReceivePaymentModal, setShowReceivePaymentModal] = useState(false);

  const handleCloseReceivePaymentModal = () =>
    setShowReceivePaymentModal(false);
  const handleShowReceivePaymentModal = () => setShowReceivePaymentModal(true);

  // show payment receipt
  const [showReceiptOfPaymentModal, setShowReceiptOfPaymentModal] =
    useState(false);

  const handleSetCloseReceivePaymentModal = () =>
    setShowReceiptOfPaymentModal(false);
  const handleSetShowReceivePaymentModal = () => {
    setBooking({});
    setShowReceiptOfPaymentModal(true);
  };

  const handleSetReceiptOfPayment = async (data) => {
    data.loadType = "Manager";
    console.log(data);
    await setBooking(data);
    await handleSetShowReceivePaymentModal();
  };

  const [loader, setLoader] = useState(false);
  const [amenity, setAmenity] = useState({});

  useEffect(() => {
    setLoader(true);
    getBookedAmenitiesData();
  }, []);

  const getBookedAmenitiesData = () => {
    setLoader(true);
    axios
      .post(
        `${hostname}/api/amenity/AllBookings`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let arr = res.data.data?.map((v) => {
          let obj = { ...v };
          delete obj.booking;
          let obj2 = { ...obj, ...v.booking };
          if (v.booking.status === "Confirmed")
            obj2.status = {
              type: "success",
              title: "Confirmed",
              icon: "check",
            };
          if (v.booking.status === "Payment Pending")
            obj2.status = {
              type: "warning",
              title: "Payment Pending",
              icon: "redo",
            };
          if (v.booking.status === "Disabled")
            obj2.status = {
              type: "danger",
              title: "Cancelled",
              icon: "ban",
            };
          if (v.booking.status === "Cancelled")
            obj2.status = {
              type: "danger",
              title: "Cancelled",
              icon: "ban",
            };
          return { ...obj2 };
        });
        console.log(arr);

        if (from === "singleUnit") {
          arr = arr?.filter((v) => v.unit_num === unit_number);
        }

        setAmenitiesList(arr);
        setAmenitiesListTemp(arr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const cancelBooking = (booking_id) => {
    console.log(booking_id);
    setBookingID(booking_id);
    handleShow();
  };

  const handleReceivePayment = (data) => {
    console.log(data);
    setAmenity(data);
    data.loadType = "Receive Payment Manager";
    handleShowReceivePaymentModal();
  };

  const functionHandleShowBookingDetailsModel = (data) => {
    setAmenity(data);
    handleShowBookingDetailsModal();
  };

  if (loader) return <Loader />;

  return (
    <>
      {from === "singleUnit" && (
        <Row className="g-3 mb-3">
          <Col>
            <CustomBreadCrumbs
              links={[
                { path: "/dashboard", label: "Home" },
                {
                  path: "/property/management/buildings",
                  label: "Properties",
                },
                {
                  path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                  label: startCase(property_name),
                },
                {
                  path: `/property/management/buildings/${property_id}/${property_name}/units/1`,
                  label: "Units",
                },
                {
                  path: `/${property_id}/${property_name}/${unit_number}/${unit_id}/attached-units`,
                  label: `${unit_number}`,
                },
                { path: "", label: "Amenity Bookings" },
              ]}
            />
            {/* <Card>
              <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to="/property/management/buildings"
                >
                  Properties
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {property_name}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/units`}
                >
                  Units
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-primary"
                  // to={`/property/management/buildings/${property_id}/${property_name}/${unit_number}/${unit_id}/units`}
                  to="#!"
                >
                  {unit_number}
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-primary"
                  // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                  to={"#!"}
                >
                  Amenity Bookings
                </Link>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      )}
      {from !== "singleUnit" && (
        <Row className="g-3 mb-3">
          <Col>
            <CustomBreadCrumbs
              links={[
                { path: "/dashboard", label: "Home" },
                {
                  path: "/property/management/buildings",
                  label: "Properties",
                },
                {
                  path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                  label: startCase(property_name),
                },

                { path: "", label: "Amenity Bookings" },
              ]}
            />
            {/* <Card>
              <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
                <Link className="me-2 text-secondary" to="/dashboard">
                  Home
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to="/property/management/buildings"
                >
                  Properties
                </Link>
                {"/"}
                <Link
                  className="me-2 ms-2 text-secondary"
                  to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                >
                  {property_name}
                </Link>

                {"/"}
                <Link
                  className="me-2 ms-2 text-primary"
                  // to={`/${property_id}/${property_name}/${unit_number}/${unit_id}/owners`}
                  to={"#!"}
                >
                  Booked Amenities
                </Link>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      )}

      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Amenity Bookings</h4>
          </Flex>
          <br />
          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomSearchbar
                searchFlags={[
                  "amenityName",
                  "amenityPrice",
                  "cash_description",
                  "check_description",
                  "payment_option",
                  "unit_num",
                  "userName",
                  "_id",
                  "status.title",
                ]}
                dataListTemp={amenitiesListTmp}
                stateUpdator={setAmenitiesList}
                placeholders={["name", " price", "booked by"]}
              />
            </Col>
          </Row>
          {/* <AmenityBookingsTable
              amenities={amenitiesList}
              removeAmenityImages={removeAmenityImages}
            /> */}
          <PaginateComponentCustom
            itemsPerPage={20}
            TableElement={BookedAmenitiesTable}
            dataList={amenitiesList}
            cancelBooking={cancelBooking}
            receivePayment={handleReceivePayment}
            receiptOfPayment={handleSetReceiptOfPayment}
            functionHandleShowBookingDetailsModel={
              functionHandleShowBookingDetailsModel
            }
          />

          <br />
          <CancelBookingModal
            show={show}
            handleClose={handleClose}
            getAmenitiesData={getBookedAmenitiesData}
            booking_id={booking_id}
            hostname={hostname}
            jwtToken={jwtToken}
          />
          <ReceivePaymentsModel
            show={showReceivePaymentModal}
            handleClose={handleCloseReceivePaymentModal}
            amenity={amenity}
            userData={userData}
            hostname={hostname}
            jwtToken={jwtToken}
          />

          <InvoiceModel
            userData={userData}
            booking={booking}
            show={showReceiptOfPaymentModal}
            handleClose={handleSetCloseReceivePaymentModal}
            getAmenitiesData={getBookedAmenitiesData}
            hostname={hostname}
            jwtToken={jwtToken}
          />
          <BookingDetailsModel
            show={showBookingDetailsModal}
            handleClose={handleCloseBookingDetailsModal}
            booking={amenity}
            hostname={hostname}
            jwtToken={jwtToken}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default BookedAmenities;
