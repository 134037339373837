import React from "react";
import Card from "react-bootstrap/Card";

import { Link } from "react-router-dom";

export default function CustomBreadCrumbs({ links = [], noBg = false }) {
  // let arr = [
  //   { path: "/dashboard", label: "Home" },
  //   {
  //     path: `/admin/management/company/profile`,
  //     label: "Company Profile",
  //   },
  //   {
  //     path: `/admin/management/company/profile`,
  //     label: "Company Profile",
  //   },
  //   {
  //     path: `/admin/management/company/profile`,
  //     label: "Company Profile",
  //   },
  // ];

  if (links.length < 1) return <span />;

  if (noBg)
    return (
      <div className="d-flex align-items-center overflow-scroll scrollbar">
        {links.map((v, i) => (
          <>
            <Link
              key={v.path + i}
              onClick={(e) => {
                links.length - 1 === i && e.preventDefault();
              }}
              className={
                links.length - 1 !== i
                  ? "text-primary"
                  : "text-secondary cursor-default text-decoration-none"
              }
              to={v.path}
            >
              {v.label}
            </Link>
            {links.length - 1 !== i && <span className="mx-2">/</span>}
          </>
        ))}
      </div>
    );

  return (
    <Card>
      <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
        {links.map((v, i) => (
          <>
            <Link
              key={v.path + i}
              onClick={(e) => {
                links.length - 1 === i && e.preventDefault();
              }}
              className={
                links.length - 1 !== i
                  ? "text-primary"
                  : "text-secondary cursor-default text-decoration-none"
              }
              to={v.path}
            >
              {v.label}
            </Link>
            {links.length - 1 !== i && <span className="mx-2">/</span>}
          </>
        ))}
      </Card.Body>
    </Card>
  );
}
