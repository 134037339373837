import React, { useContext, useState } from "react";
import { Row, Col, CloseButton } from "react-bootstrap";
import { AuthWizardContext } from "../../../../../context/Context";

import { ErrorMessage, Formik } from "formik";

import "./AmenityBasic.css";
import { v4 as uuidv4 } from "uuid";

export default function AmenityBasic() {
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  console.log(user);

  const [imgs, setImgs] = useState(
    "amenity_images" in user ? [...user.amenity_images] : []
  );
  const [imgsErrors, setImgsErrors] = useState({});

  const handleImgChangeCustom = (e) => {
    console.log(e.target.files);
    let files = [...e.target.files];

    let updatedFiles = [];

    files.forEach((v) => {
      let obj = {};
      obj.id = uuidv4();
      obj.img = v;
      updatedFiles.push(obj);
    });
    console.log(updatedFiles);
    setImgs(imgs.concat([...updatedFiles]));
  };

  const handleImgDeleteCustom = (id) => {
    console.log(id);
    let updatedFiles = imgs.filter((v) => v.id !== id);
    setImgs([...updatedFiles]);
  };

  const handleImgCustomValidation = () => {
    let errors = {};
    imgs?.forEach((v, i) => {
      if (v.img.size > 1000000) errors[`img__${i}`] = "Max file limit is 1MB !";
      if (
        v.img.type === "image/png" ||
        v.img.type === "image/jpg" ||
        v.img.type === "image/jpeg"
      ) {
        //do nothing
      } else {
        errors[`img__${i}`] = "only jpg/png files are accepted !";
      }
    });
    return errors;
  };

  return (
    <div>
      <Formik
        initialValues={{
          amenity_name: user.amenity_name || "",
          description: user.description || "",
        }}
        validate={(values) => {
          let errors = {};
          if (!values.amenity_name) {
            errors.amenity_name = "Name is required!";
          }
          if (!values.description) {
            errors.description = "Description is required!";
          }
          if (values.description && values.description.length > 100) {
            errors.description = "Description is too long !";
          }

          console.log(errors);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);

          let errors = handleImgCustomValidation();
          console.log(errors);
          if (Object.keys(errors).length > 0) {
            setImgsErrors(errors);
            return;
          }
          console.log(imgs);
          setUser(() =>
            Object.assign({ ...user, ...values, amenity_images: imgs })
          );
          setStep(step + 1);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          // isSubmitting,
          // setFieldValue
          /* and other goodies */
        }) => (
          <div className="row">
            {/* <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6"> */}
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Amenity Name</label>
                  <input
                    type="text"
                    name="amenity_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.amenity_name}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="amenity_name"
                    component={"div"}
                    className="text-danger fs--1"
                  />
                </div>
                <div className="mb-3">
                  <label>Amenity Description</label>
                  <textarea
                    rows={3}
                    type="text"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="description"
                    component={"div"}
                    className="text-danger fs--1"
                  />
                  <div
                    style={{
                      color: values.description.length > 100 ? "red" : "black",
                      textAlign: "end",
                    }}
                  >
                    {values.description.length} / 100
                  </div>
                </div>
                <div className="text-primary mb-3">
                  Upload some images of Amenity
                </div>
                <div className="">
                  <label className="my-dropzone">
                    <input
                      name={`amenity_img`}
                      accept="image/*"
                      type="file"
                      multiple={true}
                      hidden
                      onChange={handleImgChangeCustom}
                    />
                    <span className="my-dropzone-text">Click to Upload</span>
                  </label>
                </div>
                <br />

                <div>
                  <Row>
                    {imgs?.map((v, i) => (
                      <Col
                        key={i + "jks"}
                        md={6}
                        className="mb-3 position-relative"
                      >
                        <img
                          src={URL.createObjectURL(v.img)}
                          alt={i}
                          className="img-fluid rounded-3 img-backdrop"
                          name={`img__${i}`}
                        />
                        {/* <span className="my-dropzone-closebtn">x</span> */}
                        <CloseButton
                          className="my-dropzone-closebtn"
                          onClick={() => handleImgDeleteCustom(v.id)}
                        />
                        <p className=" ms-1 fs--1 text-danger">
                          {imgsErrors[`img__${i}`]}
                        </p>
                      </Col>
                    ))}
                  </Row>
                </div>

                <button
                  className="btn btn-primary float-end"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Next
                </button>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
