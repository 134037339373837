import React from "react";
import { Link } from "react-router-dom";
import Flex from "../../../components/common/Flex";
import LoginForm from "../../../components/authentication/LoginForm";
// import { useTranslation } from 'react-i18next';
import Divider from "../../../components/common/Divider";
// import AppContext from "../../../context/Context";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const [userType, setUserType] = React.useState("");

  console.log(userType);

  let linkToCreateCompany, linkToCreateAccount;
  // if (process.env.NODE_ENV === "development") {
  //   if (!window.location.hostname.startsWith("localhost")) {
  //     linkToCreateCompany = `http://localhost:${window.location.port}/company/register`;
  //     linkToCreateAccount = `http://localhost:${window.location.port}/register`;
  //   } else {
  //     linkToCreateCompany = `http://localhost:${window.location.port}/company/register`;
  //     linkToCreateAccount = `http://localhost:${window.location.port}/register`;
  //   }
  // }
  // if (process.env.NODE_ENV === "production") {
  linkToCreateCompany = "/company/register";
  linkToCreateAccount = "/register";
  // }

  const { t } = useTranslation();

  React.useEffect(() => {
    getUserWithoutLogin();
  }, []);

  const getUserWithoutLogin = () => {
    let user_type = "pure-user";

    let hostname = window.location.hostname;
    let env = process.env.NODE_ENV;
    let pathname = window.location.pathname;

    console.log(hostname);
    console.log(env);
    console.log(pathname);

    // local ,uat or aws user type https://truzakdemo.team/login
    if (
      (hostname === "localhost" ||
        hostname === "70.49.13.235" ||
        hostname === "truzakdemo.com" ||
        hostname === "truzakdemo.cloud") &&
      (pathname === "/" || pathname === "/login")
    ) {
      user_type = "user";
      setUserType(user_type);

      return;
    }

    // local ,uat or aws prop-mgmt type truzakdemo.team
    if (
      (hostname.includes("localhost") ||
        hostname.includes("70.49.13.235") ||
        hostname.includes("truzakdemo.com") ||
        hostname.includes("truzakdemo.cloud")) &&
      (hostname.length > "localhost".length ||
        hostname.length > "70.49.13.235".length ||
        hostname.length > "truzakdemo.com".length ||
        hostname.length > "truzakdemo.cloud".length) &&
      pathname === "/login"
    ) {
      user_type = "prop-mgmt";
      setUserType(user_type);

      return;
    }

    // local ,uat or aws unit-user type
    if (
      (hostname.includes("localhost") ||
        hostname.includes("70.49.13.235") ||
        hostname.includes("truzakdemo.com") ||
        hostname.includes("truzakdemo.cloud")) &&
      (hostname.length > "localhost".length ||
        hostname.length > "70.49.13.235".length ||
        hostname.length > "truzakdemo.com".length ||
        hostname.length > "truzakdemo.cloud".length) &&
      pathname === "/property/login"
    ) {
      user_type = "unit-user";
      setUserType(user_type);

      return;
    }

    //define systemuser here

    if (
      (hostname.includes("localhost") ||
        hostname.includes("70.49.13.235") ||
        hostname.includes("truzakdemo.com") ||
        hostname.includes("truzakdemo.cloud")) &&
      (hostname.length > "localhost".length ||
        hostname.length > "70.49.13.235".length ||
        hostname.length > "truzakdemo.com".length ||
        hostname.length > "truzakdemo.cloud".length) &&
      pathname === "/support/login"
    ) {
      user_type = "system-user";
      setUserType(user_type);

      return;
    }

    // return answer
  };

  if (userType === "user") {
    return (
      <>
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>{t("login.login")}</h5>
          {/* <p className=" mb-0 btn btn-link btn-sm">
            <Link
              to="#!"
              onClick={() => (window.location.href = linkToCreateAccount)}
            >
              {t("login.create_an_account")}
            </Link>
          </p> */}
        </Flex>
        <div className="mt-4"></div>
        <LoginForm /> <Divider className="mt-4">Or</Divider>
        <div className="d-flex justify-content-start align-items-center">
          <Link
            className="btn btn-link btn-sm"
            to={"#!"}
            onClick={() => (window.location.href = linkToCreateCompany)}
          >
            {t("login.register_as_a_company")}
          </Link>
          {/* <Link className="btn btn-link btn-sm" to="#!">
            Need Help
          </Link> */}
        </div>
      </>
    );
  }
  if (userType === "prop-mgmt") {
    return (
      <>
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>{t("login.login")}</h5>
          {/* <p className=" mb-0 btn btn-link btn-sm">
            <Link
              to="#!"
              onClick={() => (window.location.href = linkToCreateAccount)}
            >
              {t("login.create_an_account")}
            </Link>
          </p> */}
        </Flex>
        <div className="mt-4"></div>
        <LoginForm />
        {/* <Divider className="mt-4">Or</Divider> */}
        {/* <span className="">
          <LogoBottom width={'300px'} />
        </span> */}
        {/* <div className="d-flex justify-content-start align-items-center"> */}
        {/* <div className="">
          <Link
            className="btn btn-link btn-sm"
            to={`#!`}
            onClick={() => (window.location.href = linkToCreateAccount)}
          >
            {t("login.register_as_a_prop_mgmt")}
          </Link>
        </div> */}
      </>
    );
  }
  if (userType === "condo-corp") {
    return (
      <>
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>{t("login.login")}</h5>
          {/* <p className=" mb-0 btn btn-link btn-sm">
            <Link to="/register">{t("login.create_an_account")}</Link>
          </p> */}
        </Flex>
        <div className="mt-4"></div>
        <LoginForm />
        {/* <Divider className="mt-4">Or</Divider> */}

        {/* <div className="d-flex justify-content-between align-items-center">
          <Link
            className="btn btn-link btn-sm"
            to={`#!`}
            onClick={() => (window.location.href = linkToCreateAccount)}
          >
            {t("login.register_as_a_assoc")}
          </Link>
          
        </div> */}
      </>
    );
  }
  if (userType === "unit-user") {
    return (
      <>
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>{t("login.login")}</h5>
          {/* <p className=" mb-0 btn btn-link btn-sm">
            <Link to="/register">{t("login.create_an_account")}</Link>
          </p> */}
        </Flex>
        <div className="mt-4"></div>
        <LoginForm />
        {/* <Divider className="mt-4">Or</Divider> */}
        {/* <div className="d-flex justify-content-between align-items-center">
          <Link
            className="btn btn-link btn-sm"
            to={`#!`}
            onClick={() => (window.location.href = linkToCreateAccount)}
          >
            {t("login.register_as_a_owner")}
          </Link>
          
        </div> */}
        {/* 
        {userType !== 'user' && (
          <span className="">
            <LogoBottom width={'250px'} />
          </span>
        )} */}
      </>
    );
  }
  if (userType === "system-user") {
    return (
      <>
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>{t("login.login")}</h5>
          {/* <p className=" mb-0 btn btn-link btn-sm">
            <Link to="/register">{t("login.create_an_account")}</Link>
          </p> */}
        </Flex>
        <div className="mt-4"></div>
        <LoginForm />
        {/* <Divider className="mt-4">Or</Divider> */}

        {/* <div className="d-flex justify-content-start align-items-center">
          <Link
            className="btn btn-link btn-sm"
            to={`/authentication/company/register`}
          >
            {t("login.register_as_a_cst")}
          </Link>
          <Link className="btn btn-link btn-sm" to="#!">
            Need Help
          </Link>
        </div> */}
      </>
    );
  }
};

export default Login;
