import React from "react";
import { useState } from "react";

import Flex from "../../../../../components/common/Flex";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";

import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";

// import PaginateComponentCustom from "../../../../../components/common/PaginateComponentCustom";
import UnitInfoTable from "./UnitInfoTable";
import { capitalize, startCase } from "lodash";
import { RefreshOutlined } from "@mui/icons-material";
import Loader from "../../../../../components/common/Loader";
import UnitsManagementModel from "../../../sharedUI/UnitsManagementModel";
import CustomFilterbar from "../../../../../components/common/CustomFilterbar";
import CustomSearchbar from "../../../../../components/common/CustomSearchbar";
import CustomBreadCrumbs from "../../../../../components/common/CustomBreadCrumbs";
// import  Pagination  from "./Pagination";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../helpers/utils";
import CustomSearchBarv2 from "./CustomSearchBarv2";
import "./UnitInfo.css";

const UnitInfo = () => {
  let hostname = getHostName();

  const cookies = new Cookies();

  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name } = useParams();

  const [headerData, setHeaderData] = useState({
    units: 0,
    empty_units: 0,
    parkingsCount: 0,
    lockersCount: 0,
    cef: 0,
  });

  const [unitsData, setUnitsData] = React.useState([]);
  const [unitsDataTemp, setUnitsDataTemp] = React.useState([...unitsData]);

  const [unitsDataFull, setUnitsDataFull] = React.useState([]);

  const [loader, setLoader] = useState(true);

  const [pageOffset, setPageOffset] = React.useState([]);

  const [listLength, setListLength] = React.useState(0);

  const [showModel, setShowModel] = React.useState(false);

  const [payload, setPayload] = React.useState({});

  const handleShowModel = () => setShowModel(true);
  const handleCloseModel = () => setShowModel(false);

  const addUnit = () => {
    let obj = {};
    obj.property_id = property_id;
    obj.loadType = "Add Unit";
    setPayload({ ...obj });
    handleShowModel();
  };

  const updateUnit = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Update Unit";
    setPayload({ ...unit });
    handleShowModel();
  };

  const addUnitOwnerNew = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Add Unit Owner";
    setPayload({ ...unit });
    handleShowModel();
  };

  const changeUnitOwnerNew = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Change Unit Owner";
    setPayload({ ...unit });
    handleShowModel();
  };

  const reinviteUnitOwnerNew = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Reinvite Unit Owner";
    setPayload({ ...unit });
    handleShowModel();
  };

  const attachUnitWithCondoUnit = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Attach Unit";
    setPayload({ ...unit });
    console.log('Attach Unit');
    
    handleShowModel();
  };

  const detachUnitFromCondoUnit = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Detach Unit";
    setPayload({ ...unit });
    handleShowModel();
  };

  const { page_id } = useParams(); // Get page_id from URL params
  const navigate = useNavigate();

  // Make sure page_id is a valid number
  const currentPage = parseInt(page_id) || 1; // Default to 1 if page_id is invalid

  React.useEffect(() => {
    getUnitsData();
    getUnitsHeaderData();
    getUnitsDataFull(); // for linking non-conde units
  }, [currentPage]); // Re-run when currentPage changes

  const refreshTable = () => {
    // setLoader(true);
    getUnitsData();
    getUnitsHeaderData();
    getUnitsDataFull(); // for linking non-conde units
  };

  const getUnitsData = () => {
    let offset = (currentPage - 1) * 10; // Page offset is zero-indexed

    let p_id = property_id;
    if (p_id) {
      axios
        .post(
          `${hostname}/api/property/showunits`,
          {
            p_id,
            skipper: currentPage,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);

          let arrU = getUserFormation(res.data.data);

          let length = res.data.totalLength || 0; // Assuming total count is static
          setPageOffset(offset);
          setListLength(length);

          setUnitsData(arrU);
          setUnitsDataTemp(arrU);

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };
  const getUnitsDataFull = () => {
    let p_id = property_id;
    if (p_id) {
      axios
        .post(
          `${hostname}/api/property/showunits`,
          {
            p_id,
            skipper: 1,
            limit: 100000,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);

          let arrU = getUserFormation(res.data.data);
          console.log("calling units data full #########");

          setUnitsDataFull(arrU);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getUnitsHeaderData = () => {
    let p_id = property_id;
    if (p_id) {
      axios
        .post(
          `${hostname}/api/property/GetUnitBaseDetails`,
          {
            p_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);

          let unitBaseDetails = res.data.unitBaseDetails;

          setHeaderData({
            units: unitBaseDetails.Total_Units,
            empty_units: unitBaseDetails.Empty_Units,
            cef: parseFloat(unitBaseDetails.Total_Cef).toFixed(2),
            lockersCount: unitBaseDetails.Lockers,
            parkingsCount: unitBaseDetails.Parking_Spots,
          });

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getUserFormation = (dataList = []) => {
    let arrU = dataList.map((u) => {
      let userinfo = {};
      // if (u.userinfo !== undefined) {
      if (Object.hasOwnProperty.call(u, userinfo)) {
        // console.log(u.userinfo);
        userinfo = u.userinfo;
        delete u.userinfo;
      }
      let obj = Object.assign(
        { p_id: property_id, p_name: property_name },
        { ...userinfo },
        u
      );
      return obj;
    });
    console.log(arrU);
    arrU = arrU.map((v) => {
      let obj = {};
      // console.log(v.unit_type);
      // obj.unit_type = capitalize(v.unit_type);
      if (
        // Object.hasOwnProperty.call(v, "userinfo") === false &&
        // Object.hasOwnProperty.call(v, "Inviteduser") === false
        v.resident_status === "Not Invited"
      ) {
        obj = Object.assign(
          {
            status: {
              title: "Pending",
              type: "warning",
              icon: "check",
            },
            type: "no-owner",
          },
          v
        );
      }
      if (
        // Object.hasOwnProperty.call(v, "userinfo") === false &&
        // Object.hasOwnProperty.call(v, "Inviteduser") === true
        v.resident_status === "Invited"
      ) {
        obj = Object.assign(
          {
            status: {
              title: "Invited",
              type: "info",
              icon: "check",
            },
            type: "invited-owner",
          },
          v
        );
      }
      if (
        // Object.hasOwnProperty.call(v, "userinfo") === true &&
        // Object.hasOwnProperty.call(v, "Inviteduser") === false
        v.resident_status === "Ignored"
      ) {
        obj = Object.assign(
          {
            status: {
              title: "Ignored",
              type: "secondary",
              icon: "redo",
            },
            type: "no-owner",
          },
          v
        );
      }
      if (
        // Object.hasOwnProperty.call(v, "userinfo") === true &&
        // Object.hasOwnProperty.call(v, "Inviteduser") === false
        v.resident_status === "Active"
      ) {
        obj = Object.assign(
          {
            status: {
              title: "Active",
              type: "success",
              icon: "check",
            },
            type: "linked-owner",
          },
          v
        );
      }
      return obj;
    });
    // arrU = arrU.map(v=>{
    //   let obj = {...v};
    //   if(obj.unit_type === 'condo')
    //     obj.unit_type = 'Condo';
    //   return obj;
    // })
    console.log(arrU);

    // arrU = arrU?.sort((a, b) => (a.unit_number > b.unit_number ? 1 : -1));

    arrU = arrU.sort((a, b) =>
      +a.unit_number > +b.unit_number
        ? 1
        : +b.unit_number > +a.unit_number
        ? -1
        : 0
    );

    return arrU;
  };

  const startUnitUploadFile = () => {
    let obj = {};
    obj.property_id = property_id;
    obj.loadType = "Add Bulk Units";
    setPayload({ ...obj });
    handleShowModel();
  };

  const handlePagination = (n) => {
    console.log(n);

    navigate(
      `/property/management/buildings/${property_id}/${property_name}/units/${n}`
    );
  };

  const createArray = (length = 0) => {
    return length > 0
      ? Array.from({ length: Math.ceil(length / 10) }, (_, i) => i + 1)
      : [];
  };

  const formatPaginationMenu = () => {
    const totalPages = Math.ceil(listLength / 10);
    const pageRange = 5;

    // If there are fewer than 5 pages, just return all pages
    if (totalPages <= 5) {
      return createArray(listLength);
    }

    let pagesToShow = [];
    let startPage = Math.max(1, currentPage - pageRange);
    let endPage = Math.min(totalPages, currentPage + pageRange);

    if (currentPage - pageRange > 2) {
      pagesToShow.push(1);
      pagesToShow.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(i);
    }

    if (currentPage + pageRange < totalPages - 1) {
      pagesToShow.push("...");
      pagesToShow.push(totalPages);
    }

    return pagesToShow;
  };

  if (loader) return <Loader />;

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <CustomBreadCrumbs
            links={[
              { path: "/dashboard", label: "Home" },
              { path: "/property/management/buildings", label: "Properties" },
              {
                path: `/property/management/buildings/${property_id}/${property_name}/profile`,
                label: startCase(property_name),
              },
              { path: "", label: "Units List" },
            ]}
          />
          {/* <Card>
            <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
              <Link className="me-2 text-secondary" to="/dashboard">
                Home
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to="/property/management/buildings"
              >
                Properties
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/property/management/buildings/${property_id}/${property_name}/profile`}
              >
                {startCase(property_name)}
              </Link>
              {"/"}
              <Link className="me-2 ms-2 ">Units</Link>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Units List</h4>
            {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
            {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
            <div className="d-flex">
              <Button
                variant="light"
                size="sm"
                onClick={refreshTable}
                className="me-3"
              >
                Refresh <RefreshOutlined />
              </Button>

              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") &&
                headerData.cef >= 100 && (
                  <Button variant="primary" size="sm">
                    Units Full!
                  </Button>
                )}
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") &&
                headerData.cef < 100 && (
                  <Dropdown>
                    <Dropdown.Toggle size="sm">+ New </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={addUnit} className="text-center">
                        Add Unit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={startUnitUploadFile}
                        className="text-center"
                      >
                        Upload Bulk Units
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </div>
          </Flex>
          <Row className="mb-3 header-row">
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Total Condos</h6>
                <h5 className="text-primary">{headerData.units || "0"}</h5>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Parkings</h6>
                <h5 className="text-primary">
                  {headerData.parkingsCount || "0"}
                </h5>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Lockers</h6>
                <h5 className="text-primary">
                  {headerData.lockersCount || "0"}
                </h5>
              </div>
            </Col>
            <hr className="header-br-row" />
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Total %CEF</h6>
                <h5 className="text-primary">{headerData.cef || "0"}%</h5>
              </div>
            </Col>
            <Col className="d-flex justify-content-between">
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Vacant Units</h6>
                <h5 className="text-primary">
                  {headerData.empty_units || "0"}
                </h5>
              </div>
            </Col>
          </Row>
          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomFilterbar
                filterFlags={[
                  "Status Active",
                  "Status Ignored",
                  "Status Invited",
                  "Type Condo",
                  "Type Non-Condo",
                ]}
                dataListTemp={unitsDataTemp}
                stateUpdator={setUnitsData}
                from="Units Table Manager"
              />

              {/* <CustomSearchbar
                searchFlags={[
                  "unit_number",
                  "unit_type",
                  "cef",
                  "Inviteduser",
                  "userinfo.email",
                  "userinfo.firstname",
                  "userinfo.lastname",
                ]}
                dataListTemp={unitsDataTemp}
                stateUpdator={setUnitsData}
                placeholders={[
                  "unit_number",
                  "unit_type",
                  "Inviteduser",
                  "email",
                ]}
              /> */}

              <CustomSearchBarv2
                apiEndpoint="/api/property/showunits"
                apiInput={{ p_id: property_id, skipper: 1 }}
                searchFlags={[
                  "unit_number",
                  "unit_type",
                  "cef",
                  "Inviteduser",
                  "userinfo.email",
                  "userinfo.firstname",
                  "userinfo.lastname",
                ]}
                placeholders={[
                  "Unit Number",
                  "Unit Type",
                  "% CEF",
                  "Unit Owner",
                  "Attached To",
                ]}
                haveLinkToDetail
              />
            </Col>
          </Row>
          <div style={{ minHeight: "70vh" }}>
            <UnitInfoTable
              dataList={unitsData}
              updateUnit={updateUnit}
              addUnitOwnerNew={addUnitOwnerNew}
              changeUnitOwnerNew={changeUnitOwnerNew}
              reinviteUnitOwnerNew={reinviteUnitOwnerNew}
              attachUnitWithCondoUnit={attachUnitWithCondoUnit}
              detachUnitFromCondoUnit={detachUnitFromCondoUnit}
              userData={userData}
              jwtToken={jwtToken}
              hostname={hostname}
            />
          </div>

          <div
            style={{
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {currentPage > 1 && (
              <Button
                size="sm"
                style={{ minWidth: "80px" }}
                variant="secondary"
                className="me-2"
                onClick={() => handlePagination(currentPage - 1)}
              >
                Previous
              </Button>
            )}

            {formatPaginationMenu().map((page, idx) =>
              page === "..." ? (
                <Button
                  key={idx}
                  size="sm"
                  variant="light"
                  style={{ width: "40px" }}
                  disabled
                >
                  ...
                </Button>
              ) : (
                <Button
                  key={page}
                  size="sm"
                  variant={page === currentPage ? "primary" : "outline-primary"}
                  style={{ minWidth: "40px" }}
                  className="ms-3"
                  onClick={() => handlePagination(page)}
                >
                  {page}
                </Button>
              )
            )}

            {currentPage < Math.ceil(listLength / 10) && (
              <Button
                size="sm"
                variant="success"
                style={{ width: "80px" }}
                className="ms-3"
                onClick={() => handlePagination(currentPage + 1)}
              >
                Next
              </Button>
            )}
          </div>

          {/* <Pagination /> */}

          {/* <PaginateComponentCustom
            itemsPerPage={10}
            dataList={unitsData}
            TableElement={UnitInfoTable}
            updateUnit={updateUnit}
            addUnitOwnerNew={addUnitOwnerNew}
            changeUnitOwnerNew={changeUnitOwnerNew}
            reinviteUnitOwnerNew={reinviteUnitOwnerNew}
            attachUnitWithCondoUnit={attachUnitWithCondoUnit}
            detachUnitFromCondoUnit={detachUnitFromCondoUnit}
            userData={userData}
            jwtToken={jwtToken}
            hostname={hostname}
          /> */}

          <br />

          <UnitsManagementModel
            show={showModel}
            handleClose={handleCloseModel}
            payload={payload}
            setPayload={setPayload}
            userData={userData}
            getAllData={getUnitsData}
            unitsData={unitsDataFull}
            get
            directly
            from
            model
            headerData={headerData}
            hostname={hostname}
            jwtToken={jwtToken}
            property_id={property_id}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default UnitInfo;
