// import React from 'react';
import React from "react";
// import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import classNames from "classnames";
import { Link } from "react-router-dom";
// import logo from 'assets/img/illustrations/falcon.png';
// import logo from 'assets/img/illustrations/logo-condozak3.png';
import logo from "../../assets/img/illustrations/logo-condozak4.png";
// import logo from 'assets/img/illustrations/logo-condozak5.png';
import axios from "axios";
import { useEffect, useState } from "react";
import { words } from "lodash";
import { capitalize, getHostName } from "../../helpers/utils";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const Logo = ({
  at = "auth",
  width = 58,
  className,
  textClass,
  fromNavTop,
  ...rest
}) => {
  const cookies = new Cookies();

  // let userData = cookies.get('userData');

  let hostname = getHostName();

  let linkToHome = "#!";
  const [asd, setAsd] = useState(null);
  const formatCompanyName = (p_name, fromNavTop = false) => {
    let n = words(p_name);
    // console.log(n.length);
    if (n.length > 2) {
      p_name = `${n[0]} ...`;
    }
    if (fromNavTop) {
      // p_name = `${n[0]}...`;
      p_name = `${n.join(" ")}`;
    }

    return capitalize(p_name);
  };
  useEffect(() => {
    (async () => {
      const result = await handlePostRequest();
      setAsd(formatCompanyName(result, true));
      let jwtToken = cookies.get("_expire_t");
      let userData = jwtToken ? jwtDecode(jwtToken) : "";
      if (userData !== undefined) linkToHome = "/dashboard";
    })();
  }, []);

  const handlePostRequest = async () => {
    const loc = window.location.hostname;
    const result =
      loc.indexOf("localhost") === 0
        ? null
        : loc.substring(0, loc.indexOf("localhost"));

    if (result == null) {
      cookies.set("Windows", "Condozak", { path: "/" });
      return "Condozak";
    } else {
      const result2 = result.replace(/\.$/, "");

      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${hostname}/api/auth/showcompany`,

        {
          siteName: result2,
        },
        config
      );
      try {
        if (data.company_name != null) {
          cookies.set("Windows", data.company_name, { path: "/" });
          return data.company_name;
        }
        if (data.property_name != null) {
          cookies.set("Windows", data.property_name, { path: "/" });
          return data.property_name;
        }
      } catch (error) {
        cookies.set("Windows", "Condozak", { path: "/" });
        return "Condozak";
      }
    }
  };
  // console.log(userData);
  if (fromNavTop)
    return <h3 className="fw-bold text-primary text-center">{asd}</h3>;
  return (
    <span
      // style={{ width: '100%' }}
      // to={userData !== undefined || userData !== null ? '/dashboard' : '#!'}
      // to={userData.em ? '/dashboard' : ''}
      //to={linkToHome}
      className={classNames(
        "text-decoration-none text-primary",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center justify-content-around py-3":
              at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center fw-bolder fs-5 mb-4": at === "auth",
          },
          className
        )}
      >
        {window.location.hostname === "localhost" && (
          <img className="me-2" src={logo} alt="Logo" width={width} />
        )}
        {window.location.hostname !== "localhost" &&
          !window.location.pathname.includes("/not-invited") && (
            <span className={classNames("font-monospace", textClass)}>
              {formatCompanyName(asd, true)}
            </span>
          )}
        {window.location.pathname.includes("/not-invited") && (
          <img className="me-2" src={logo} alt="Logo" width={width} />
        )}

        {/* {!isNavbarVerticalCollapsed && (
          )} */}
        {/* <span className={classNames('font-monospace', textClass)}>{asd}</span> */}
      </div>
    </span>
  );
};

// Logo.propTypes = {
//   at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
//   width: PropTypes.number,
//   className: PropTypes.string,
//   textClass: PropTypes.string
// };

//Logo.defaultProps = { at: "auth", width: 58 };

export default Logo;
