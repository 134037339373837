import React from "react";

import { Card, Row, Col, Button } from "react-bootstrap";

import CustomSearchbar from "../../../../../components/common/CustomSearchbar";

import Flex from "../../../../../components/common/Flex";

import { Link, useNavigate } from "react-router-dom";

import InboxTable from "./InboxTable";

import InboxModal from "./InboxModal";

import Loader from "../../../../../components/common/Loader";

// import EmailDetail from "./EmailDetail";

import axios from "axios";

import { formatLogInfo } from "../../../../../helpers/utils";

import RefreshIcon from "@mui/icons-material/Refresh";

import { toast } from "react-toastify";

import { convert } from "html-to-text";

import { Delete, Download } from "@mui/icons-material";

import { handleCreatePDF } from "../../../../../helpers/utils-pdf";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../helpers/utils";
import CustomBreadCrumbs from "../../../../common/CustomBreadCrumbs";

export default function EmailInbox() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const navigate = useNavigate();

  //main data
  const [emailsList, setEmailsList] = React.useState([]);
  const [emailsListTemp, setEmailsListTemp] = React.useState([]);

  const [bulkMails, setBulkMails] = React.useState([]);
  console.log(bulkMails);

  //main modal
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //main action
  const [loader, setLoader] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  React.useEffect(() => {
    setLoader(true);
    getAllEmailsListData();
  }, []);

  const getAllEmailsListData = () => {
    axios
      .post(
        `${hostname}/api/property/ReadUserInbox`,
        {
          user_id: userData.data,
          p_id: userData.propid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let arr = res.data?.Data?.map((v, i) => {
          let mail_content_pure = v.mail_content;
          mail_content_pure = mail_content_pure?.replace(/(\r\n|\n|\r)/gm, "");

          let oldObj = {
            id: "mail-" + (i + 1),
            mail_id: v._id,
            user: "no-reply ",
            img: "",
            title: v.mail_subject,
            descriptionFull: convert(v.mail_content, { wordwrap: 0 }),
            descriptionFullHTML: mail_content_pure,
            attachments: v.attachments,
            time: formatLogInfo(v.updatedAt, true),
            timeFull: formatLogInfo(v.updatedAt),
            sender_name: v.sender_first_name + " " + v.sender_last_name,
            sender_email: v.sender_email,
            star: false,
            snooze: false,
            read: v.isRead,
            deleted: v.isDeleted,
            archived: v.isArchive,
            // createdAt: v.createdAt,
          };
          oldObj.description =
            oldObj.descriptionFull?.length > 95
              ? oldObj.descriptionFull?.slice(0, 95) + "..."
              : oldObj.descriptionFull;
          return oldObj;
        });

        console.log(arr);

        setEmailsList(arr);
        setEmailsListTemp(arr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //display single email detail
  const displaySingleEmailData = (obj = {}) => {
    navigate(`/owner-portal/inbox/${obj.mail_id}`);
  };

  //archive, delete or unread
  const callGivenOption = (optionName = "", optionValue, obj = {}) => {
    if (optionName === "delete") {
      let allMail = emailsList.filter((v) => v.mail_id !== obj.mail_id);
      return axios
        .post(
          `${hostname}/api/property/UpdateInbox`,
          {
            inbox_id: obj.mail_id,
            isArchive: obj.archived,
            isDeleted: true,
            isRead: obj.read,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          throw err; // Ensure errors propagate
        });
    }
  };

  const addBulkAction = (v) => {
    console.log(v);
    if (bulkMails.includes(v)) {
      let state = bulkMails?.filter((j) => j !== v);
      setBulkMails(state);
    } else {
      setBulkMails(bulkMails.concat(v));
    }
  };

  const applyBulkAction = async (actionType = "") => {
    if (bulkMails.length > 0) {
      console.log("call api here...");
      let selectedEmails = emailsList.filter((v) => bulkMails.includes(v.id));
      let remainingEmails = emailsList.filter((v) => !bulkMails.includes(v.id));

      if (actionType === "delete") {
        console.log("delete");

        // Wait for all delete operations to complete
        await Promise.all(
          selectedEmails.map(
            (v) => callGivenOption("delete", true, v) // Ensure `callGivenOption` is updated to return a promise
          )
        );

        // Update state after all deletions
        setEmailsList(remainingEmails);
        setEmailsListTemp(remainingEmails);

        toast.success("Bulk delete completed.", { theme: "colored" });
      }

      if (actionType === "download") {
        console.log("download");
        let mails = selectedEmails.map((v) => ({
          pdf_content: v.descriptionFullHTML,
          file_name: v.sender_name,
        }));

        await handleCreatePDF(
          "BulkPDF",
          "download",
          "",
          "",
          mails,
          userData.property_name || "property_inbox"
        );

        toast.success("Bulk download completed.", { theme: "colored" });
      }

      setBulkMails([]);
      setLoader(false); // Loader turned off after all operations are done
    } else {
      console.log("No emails selected.");
      toast.warning("No emails selected for the action.", { theme: "colored" });
    }
  };

  const refreshTable = () => {
    setLoader(true);
    getAllEmailsListData();
  };

  //preview file
  const previewAttachment = (obj = {}) => {
    console.log(obj);
    obj.loadType = "Preview File";
    obj.size = "xl";
    setPayload({ ...obj });
    handleShow();
  };

  if (loader) return <Loader />;

  return (
    <div>
      <Row className="g-3 mb-3">
        <Col>
        <CustomBreadCrumbs
              links={[
                { path: "/dashboard", label: "Home" },

                { path: "", label: "Inbox" },
              ]}
            />
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "95vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Inbox</h4>
          </Flex>

          <Row className="flex-end-center mb-1">
            <Col className=" d-flex justify-content-sm-end">
              {bulkMails.length > 0 && (
                <div className="me-3 hide-pdf-div">
                  <Button
                    variant="falcon-success"
                    onClick={() => applyBulkAction("download")}
                    disabled={bulkMails.length < 1}
                  >
                    <Download />
                  </Button>

                  <Button
                    variant="falcon-danger"
                    onClick={() => applyBulkAction("delete")}
                    disabled={bulkMails.length < 1}
                    className="ms-3"
                  >
                    <Delete />
                  </Button>
                </div>
              )}
              <div className="me-3">
                <Button variant="primary" type="button" onClick={refreshTable}>
                  Refresh <RefreshIcon />
                </Button>
              </div>

              <CustomSearchbar
                searchFlags={[
                  "sender_name",
                  "title",
                  "descriptionFull",
                  "time",
                  "timeFull",
                ]}
                dataListTemp={emailsListTemp}
                stateUpdator={setEmailsList}
                placeholders={["sender", "subject", "description", "date"]}
              />
            </Col>
          </Row>

          <InboxTable
            list={emailsList}
            displaySingleEmailData={displaySingleEmailData}
            bulkMails={bulkMails}
            addBulkAction={addBulkAction}
            previewAttachment={previewAttachment}
          />
          <br />
          <InboxModal show={show} handleClose={handleClose} payload={payload} />
        </Card.Body>
      </Card>
    </div>
  );
}

// const callGivenOption = (optionName = "", optionValue, obj = {}) => {
//     // close single mail detail if open

//     //processing
//     // setLoader(true);
//     let readIndex = 0;
//     let readObj = emailsList.filter((v, i) => {
//       if (v.id === obj.id || v.mail_id === obj.mail_id) {
//         readIndex = i;
//       }
//       return v.id === obj.id || v.mail_id === obj.mail_id;
//     });

//     console.log(readObj.mail_id);

//     // delete
//     if (optionName === "delete") {
//       let allMail = emailsList.filter((v) => v.mail_id !== obj.mail_id);
//       axios
//         .post(
//           `${hostname}/api/property/UpdateInbox`,
//           {
//             inbox_id: obj.mail_id,
//             isArchive: obj.archived,
//             isDeleted: true,
//             isRead: obj.read,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${jwtToken}`,
//             },
//           }
//         )
//         .then((res) => {
//           console.log(res.data);
//           setEmailsList(allMail);
//           setEmailsListTemp(allMail);
//           setLoader(false);
//         })
//         .catch((err) => {
//           console.log(err);
//         });

//       toast.warning("messages deleted", { theme: "colored" });
//     }

//     // unread
//     if (optionName === "unread") {
//       let allMail = [...emailsList];
//       allMail[readIndex].read = false;

//       axios
//         .post(
//           `${hostname}/api/property/UpdateInbox`,
//           {
//             inbox_id: obj.mail_id,
//             isArchive: obj.archived,
//             isDeleted: obj.deleted,
//             isRead: false,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${jwtToken}`,
//             },
//           }
//         )
//         .then((res) => {
//           console.log(res.data);
//           setEmailsList([...allMail]);
//           setEmailsListTemp([...allMail]);
//           setLoader(false);
//           toast.success("action applied.", { theme: "colored" });
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }

//     // archive + undo
//     if (optionName === "archive") {
//       let allMail = [...emailsList];
//       allMail[readIndex].archived = optionValue;

//       axios
//         .post(
//           `${hostname}/api/property/UpdateInbox`,
//           {
//             inbox_id: obj.mail_id,
//             isArchive: optionValue,
//             isDeleted: obj.deleted,
//             isRead: obj.read,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${jwtToken}`,
//             },
//           }
//         )
//         .then((res) => {
//           console.log(res.data);
//           setEmailsList([...allMail]);
//           setEmailsListTemp([...allMail]);
//           setLoader(false);
//           toast.success("action applied.", { theme: "colored" });
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   };

//   const addBulkAction = (v) => {
//     console.log(v);
//     if (bulkMails.includes(v)) {
//       let state = bulkMails?.filter((j) => j !== v);
//       setBulkMails(state);
//     } else {
//       setBulkMails(bulkMails.concat(v));
//     }
//   };

//   const applyBulkAction = async (actionType = "") => {
//     // setLoader(true);

//     // console.log(bulkMails);

//     if (bulkMails.length > 0) {
//       console.log("call api here..");
//       let arr = emailsList?.filter((v) => bulkMails.includes(v.id));

//       // console.log(arr);
//       // return;

//       if (actionType === "download") {
//         let mails = [];
//         arr?.forEach((v) => {
//           console.log(v);
//           let obj = {};
//           obj.pdf_content = v.descriptionFullHTML;
//           obj.file_name = v.sender_name;

//           mails.push(obj);
//         });

//         // console.log(mails);

//         await handleCreatePDF(
//           "BulkPDF",
//           "download",
//           "",
//           "",
//           mails,
//           userData.property_name || "property_inbox"
//         );
//       }
//       if (actionType === "delete") {
//         console.log("delete");
//         let arr = [];
//         let arrToDelete = [];
//         arr = emailsList.filter((v) => !bulkMails.includes(v.id));
//         arrToDelete = emailsList.filter((v) => bulkMails.includes(v.id));
//         console.log(arr);
//         setLoader(false);

//         arrToDelete?.forEach((v) => {
//           callGivenOption("delete", true, v);
//         });
//       }
//       setBulkMails([]);
//     } else {
//       console.log("no files to convert");
//     }
//   };

//   const applyBulkAction = async (actionType = "") => {
//     // setLoader(true);

//     // console.log(bulkMails);

//     if (bulkMails.length > 0) {
//       console.log("call api here..");
//       let arr = emailsList?.filter((v) => bulkMails.includes(v.id));

//       // console.log(arr);
//       // return;

//       if (actionType === "download") {
//         let mails = [];
//         arr?.forEach((v) => {
//           console.log(v);
//           let obj = {};
//           obj.pdf_content = v.descriptionFullHTML;
//           obj.file_name = v.sender_name;

//           mails.push(obj);
//         });

//         // console.log(mails);

//         await handleCreatePDF(
//           "BulkPDF",
//           "download",
//           "",
//           "",
//           mails,
//           userData.property_name || "property_inbox"
//         );
//       }
//       if (actionType === "delete") {
//         console.log("delete");
//         let arr = [];
//         let arrToDelete = [];
//         arr = emailsList.filter((v) => !bulkMails.includes(v.id));
//         arrToDelete = emailsList.filter((v) => bulkMails.includes(v.id));
//         console.log(arr);
//         setLoader(false);

//         arrToDelete?.forEach((v) => {
//           callGivenOption("delete", true, v);
//         });
//       }
//       setBulkMails([]);
//     } else {
//       console.log("no files to convert");
//     }
//   }
