import React, { useContext, useState } from "react";

import { Card, Nav, ProgressBar } from "react-bootstrap";

import classNames from "classnames";

import { useForm } from "react-hook-form";

import { AuthWizardContext } from "../../../../../../context/Context";

import WizardModal from "./WizardModal";

import Flex from "../../../../../../components/common/Flex";

import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import BookingDetails from "./BookingDetails";
import DefinedRules from "./DefinedRules";
import AmenityPayment from "./AmenityPayment";

const WizardLayout = ({
  variant,
  validation,
  progressBar,
  handleClose,
  selectedAmenity,
  getAmenitiesData,
  userData,
  hostname,
  jwtToken,
}) => {
  // const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  React.useEffect(() => {
    setUser(Object.assign({ ...selectedAmenity }, user));
  }, []);
  const {
    // register,
    handleSubmit,
    // formState: { errors },
    // watch,
    // setValue,
    reset,
    clearErrors,
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      // icon: 'book',
      icon: <PersonIcon />,
      label: "Booking Details",
    },
    {
      // icon: 'share-alt',
      icon: <ReceiptLongIcon />,
      label: "Rules & Review",
    },
    // {
    //   // icon: 'share-alt',
    //   icon: <AccessTimeIcon />,
    //   label: 'Review'
    // },
    {
      // icon: 'dollar-sign',
      icon: <CreditCardIcon />,
      label: "Payment & Done",
    },
    // {
    //   // icon: 'dollar-sign',
    //   icon: <ThumbUpIcon />,
    //   label: 'Done'
    // }
  ];

  const onSubmitData = (data) => {
    setUser({ ...user, ...data });
    setStep(step + 1);
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = (targetStep) => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        // as={Form}
        // noValidate
        // onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard"
        // style={{'height':'100vh'}}
      >
        <Card.Header
          className={classNames("bg-light", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === "pills"
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && (
          <ProgressBar now={step * 33.33} style={{ height: 2 }} />
        )}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <BookingDetails
              selectedAmenity={selectedAmenity}
              userData={userData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )}
          {step === 2 && <DefinedRules selectedAmenity={selectedAmenity} />}
          {/* {step === 3 && <BookingReview selectedAmenity={selectedAmenity} />} */}
          {step === 3 && (
            <AmenityPayment
              selectedAmenity={selectedAmenity}
              reset={reset}
              handleClose={handleClose}
              getAmenitiesData={getAmenitiesData}
              userData={userData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

// const NavItem = ({ index, step, handleNavs, icon, label }) => {
const NavItem = ({ index, step, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: index < 5 ? step > index : step > 4,
          active: step === index,
        })}
        // onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            {/* <FontAwesomeIcon icon={icon} /> */}
            <span className="d-flex justify-content-center align-items-center h-100">
              {icon}
            </span>
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, icon, label }) => {
  // const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: step > index,
          active: step === index,
        })}
        // onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          {/* <FontAwesomeIcon icon={icon} /> */}
          <span>{icon}</span>
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

// WizardLayout.propTypes = {
//   variant: PropTypes.oneOf(['pills']),
//   validation: PropTypes.bool,
//   progressBar: PropTypes.bool
// };

// NavItemPill.propTypes = {
//   index: PropTypes.number.isRequired,
//   step: PropTypes.number.isRequired,
//   handleNavs: PropTypes.func.isRequired,
//   icon: PropTypes.string.isRequired,
//   label: PropTypes.string.isRequired
// };

// NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
