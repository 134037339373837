import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ComposeAttachment from "../../../../../components/app/email/compose/ComposeAttachment";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import TinymceEditor from "../../../../../components/common/TinymceEditor";
import { AttachFile } from "@mui/icons-material";
import Flex from "../../../../../components/common/Flex";

import { Editor } from "@tinymce/tinymce-react";
import AppContext from "../../../../../context/Context";
import { getColor } from "../../../../../helpers/utils";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/common/Loader";
import { toast } from "react-toastify";

export default function EmailCompose({
  setModelSize,
  setShowLetterPage,
  // userData,
  mailOutState,
  setMailOutState,
  // mailOutErrors,
  // setMailOutErrors,
  handleFieldValueChange,
  payload,
  hostname,
  jwtToken,
}) {
  let options = [
    "First_Name",
    "Last_Name",
    "Unit_no",
    "Monthly_Budget",
    "Start_fiscalDate",
    "End_fiscalDate",
    "Total_Budget",
    "Property_Name",
    "Property_Address",
    "Property_Manager",
  ];

  const {
    config: { isDark },
  } = React.useContext(AppContext);
  const editorRef = React.useRef(null);

  const [editorLoader, setEditorLoader] = React.useState(true);

  const { property_id } = useParams();

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current.dom.addStyle(
        `body{color: ${getColor("white")} !important;}`
      );
    }
  }, [isDark]);

  // const { step, setStep, user, setUser } = React.useContext(AuthWizardContext);
  // console.log(user);

  // const [mailContent, setMailContent] = React.useState(user.mailContent || "");
  // const [mailSubject, setMailSubject] = React.useState(user.mailSubject || "");
  // const [mailFiles, setMailFiles] = React.useState(user.mailFiles || "");
  // const [attachments, setAttachments] = React.useState(user.attachments || []);

  // const [willDraftLetter, setWillDraftLetter] = React.useState(
  //   user.willDraftLetter || "No"
  // );

  const handleSetMailContent = (value) => {
    console.log(value);
    setMailOutState({ ...mailOutState, mail_content: value });
    // setMailContent(value);
  };
  // const editorRef = React.useRef(null);

  const addVariableInBody = (variableValue = "") => {
    let currentState = mailOutState.mail_content;
    // console.log(currentState);
    // console.log(typeof currentState);

    console.log(editorRef.current);

    editorRef.current.insertContent(variableValue);
  };

  function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  // const handleImageUpload = (blobInfo, progress, failure) => {
  const handleImageUpload = (blobInfo) => {
    let imgb64 = blobInfo.base64();
    let arrbfr = base64ToArrayBuffer(imgb64);

    // return new Promise((resolve, reject) => {
    return new Promise((resolve) => {
      axios
        .post(
          `${hostname}/api/property/ImageToLinkAws`,
          {
            p_id: property_id,
            req_image: arrbfr,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          resolve(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleSubmit = () => {
    console.log(mailOutState);

    if (!mailOutState.mail_subject) {
      toast.warning("Mail subject is missing!");
    }
    if (!mailOutState.mail_content) {
      toast.error("Mail body is required!");
      return;
    }

    if (mailOutState.will_draft_letter === "Yes") {
      // setShowLetterPage(true);
      // setStep(step + 1);
      setMailOutState({ ...mailOutState, step: mailOutState.step + 1 });
    } else {
      // setStep(step + 2);
      setMailOutState({ ...mailOutState, step: mailOutState.step + 2 });
      setModelSize("lg");
    }
  };

  const handleStepMinus = () => {
    // let email_data = { mailContent, mailSubject, willDraftLetter, attachments };
    // setUser(Object.assign({}, user, email_data));
    setModelSize("lg");
    setMailOutState({ ...mailOutState, step: mailOutState.step - 1 });

    // setStep(step - 1);
  };

  const handleAddAttachment = (files) => {
    if (files.length === 0) return;
    const fileArray = [];

    let array = Array.from(files);

    for (let i = 0; i < array.length; i++) {
      const file = array[i];
      const { name, size, type } = file;

      if (size > 1000000) {
        toast.error("File size too large! 1 MB is supported.");
        continue;
      }
      

      const newFile = {
        id: name + 1 + Date.now(),
        name,
        size,
        type,
        file: file,
      };
      fileArray.push(newFile);
    }

    // Array.from(files).forEach((file) => {
    //   const { name, size, type } = file;

    //   if(size > 1000000){
    //     toast.error('File size too large! 1 MB is supported.');

    //   }

    //   const newFile = {
    //     id: name + 1 + Date.now(),
    //     name,
    //     size,
    //     type,
    //     file: file,
    //   };
    //   fileArray.push(newFile);
    // });
    setMailOutState({
      ...mailOutState,
      mail_files: [...mailOutState.mail_files, ...fileArray],
    });
    // setAttachments([...attachments, ...fileArray]);
  };

  const handleDetachAttachment = (id) => {
    console.log(id);
    if (typeof id === "object") {
      let arr = [];
      arr = mailOutState.mail_files?.filter(
        (attachment) => attachment.file_id !== id.file_id
      );
      console.log(arr);
      // return;
      setMailOutState({
        ...mailOutState,
        mail_files: arr,
      });
      return;
    }
    if (typeof id !== "object")
      setMailOutState({
        ...mailOutState,
        mail_files: mailOutState.mail_files?.filter(
          (attachment) => attachment.id !== id
        ),
      });
    // setAttachments(attachments.filter((attachment) => attachment.id !== id));
  };

  return (
    <div className="">
      <Row className="mb-3">
        <Col md={9} className="pe-lg-1">
          <Card>
            <FalconCardHeader title="Subject" />
            <Card.Body style={{ minHeight: "100vh" }} className="bg-light p-0">
              <div className="border border-y-0 border-200">
                <Form.Control
                  type="text"
                  placeholder="Add subject here"
                  className="border-0 outline-none px-card rounded-0"
                  name="mail_subject"
                  value={mailOutState.mail_subject}
                  onChange={handleFieldValueChange}
                />
              </div>
              <div className="border border-0 border-200">
                {editorLoader && <Loader />}
                {/* {!editorLoader && ( */}
                <Editor
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                    //console.log('ok edotoooooor');
                    setEditorLoader(false);
                  }}
                  value={mailOutState.mail_content}
                  onEditorChange={handleSetMailContent}
                  //apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                  // initialValue={""}
                  init={{
                    license_key: "gpl",
                    promotion: false,
                    suffix: ".min", // Suffix to use for minified files
                    contextmenu: false,
                    height: "100vh",
                    menubar: false,
                    content_style: `body { color: ${getColor(
                      "black"
                    )} } p {margin: 0; padding: 0;} div {margin: 0; padding: 0;}
                    `,

                    mobile: {
                      theme: "mobile",
                      toolbar: ["undo", "bold"],
                    },
                    statusbar: false,
                    plugins: "link image lists table directionality",
                    toolbar:
                      "styleselect | bold italic link bullist numlist image blockquote table undo redo",

                    directionality: "ltr",
                    // directionality: isRTL ? 'rtl' : 'ltr',
                    theme_advanced_toolbar_align: "center",
                    images_upload_url: "",
                    automatic_uploads: true,
                    images_reuse_filename: true,
                    images_upload_handler: handleImageUpload,
                  }}
                />
                {/* )} */}
              </div>
              <div className="ms-3 mt-3">
                <label className="btn btn-primary">
                  <input
                    type="file"
                    multiple
                    hidden
                    onChange={({ target }) => handleAddAttachment(target.files)}
                  />
                  <AttachFile /> Attach Files
                </label>
              </div>
              {/* {mailOutState.mail_files?.length > 0 && (
              )} */}
              <div className="bg-light px-card py-3">
                <Flex direction="column" inline>
                  {[...mailOutState.mail_files]?.map((attachment) => (
                    <ComposeAttachment
                      {...attachment}
                      payload={payload}
                      key={attachment.id}
                      full_file={attachment}
                      handleDetachAttachment={handleDetachAttachment}
                    />
                    // <div
                    //   className="bg-white d-flex align-items-center p-2 ml-auto cursor-pointer border-1 border rounded"
                    //   id={`attachmentTooltip${attachment.id}`}
                    //   onClick={() => handleDetachAttachment(attachment.id)}
                    // >
                    //   <span className="me-3"> {attachment.name}</span>
                    //   <FontAwesomeIcon icon="times" className="text-danger" />
                    // </div>
                  ))}
                  {/* {payload.attachments?.map((attachment) => (
                    <ComposeAttachment
                      {...attachment}
                      full_file={attachment}
                      payload={payload}
                      key={attachment.id}
                      handleDetachAttachment={handleDetachAttachment}
                      from="db_files"
                    />
                   
                  ))} */}
                  {/* {payload.attachments?.map((attachment) => (
                    <div
                      className="bg-white d-flex align-items-center p-2 ml-auto cursor-pointer border-1 border rounded"
                      // id={`attachmentTooltip${attachment.id}`}
                      // onClick={() => handleDetachAttachment(attachment.id)}
                    >
                      <span className="me-3"> {attachment.name}</span>
                      <FontAwesomeIcon icon="times" className="text-danger" />
                    </div>
                  ))} */}
                </Flex>
              </div>
              <br />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <FalconCardHeader title="Select Fields" />
            <Card.Body
              style={{ minHeight: "120vh" }}
              className="bg-light d-flex flex-column"
            >
              {options?.map((v, i) => (
                <OverlayTrigger
                  key={i + v}
                  placement="top"
                  overlay={
                    <Tooltip>click to add</Tooltip>
                    // <Tooltip>Calculated according to last week's sales</Tooltip>
                  }
                >
                  <span
                    key={v}
                    // className="mb-4"
                    className="cursor-pointer mb-4"
                    onClick={() => addVariableInBody(`[ ${v} ]`)}
                  >
                    [ {v} ]
                  </span>
                </OverlayTrigger>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="">
        <Card>
          <Card.Body className="bg-light d-flex justify-content-center">
            <div>
              Would you like to draft a letter to attach ?
              <span className="ms-4"></span>
              Yes{" "}
              <Form.Check
                type="radio"
                inline
                name="will_draft_letter"
                value={mailOutState.will_draft_letter}
                onChange={(e) => {
                  setShowLetterPage(true);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setMailOutState({
                    ...mailOutState,
                    will_draft_letter: "Yes",
                  });
                }}
                checked={mailOutState.will_draft_letter === "Yes"}
              />{" "}
              No{" "}
              <Form.Check
                type="radio"
                inline
                name="will_draft_letter"
                value={mailOutState.will_draft_letter}
                onChange={(e) => {
                  setShowLetterPage(false);
                  setMailOutState({
                    ...mailOutState,
                    will_draft_letter: "No",
                  });
                  // setWillDraftLetter("No");
                }}
                checked={mailOutState.will_draft_letter === "No"}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      <br />
      <div>
        <Button variant="secondary" onClick={handleStepMinus}>
          Previous
        </Button>
        {/* {!mailOutState.mail_subject && (
          <span className="float-end pt-2 text-danger">
            Mail Subject is missing!
          </span>
        )} */}
        {/* {mailOutState.mail_subject && ( */}
          <Button
            // disabled={!mailOutState.mail_subject}
            variant="primary"
            className="float-end"
            onClick={handleSubmit}
          >
            Next
          </Button>
        {/* // )} */}
      </div>
    </div>
  );
}
