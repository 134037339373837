import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { Row, Col, Form as BSForm, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import axios from "axios";
import { isObject } from "lodash";

import { AuthWizardContext } from "../../../../../../context/Context";
import { CalendarToday } from "@mui/icons-material";

import "./BookingDetails.css";

let arrShort = [
  { day: "Sunday", short: "sun" },
  { day: "Monday", short: "mon" },
  { day: "Tuesday", short: "tue" },
  { day: "Wednesday", short: "wed" },
  { day: "Thursday", short: "thu" },
  { day: "Friday", short: "fri" },
  { day: "Saturday", short: "sat" },
];

const leadTimeMapping = {
  "No Restrictions": 0,
  "24 Hours": 1,
  "3 Days": 3,
  "5 Days": 5,
  "1 Week": 7,
  "1 Month": 30,
  "6 Months": 180,
};

export default function BookingDetails({
  selectedAmenity,
  userData,
  hostname,
  jwtToken,
}) {
  const { user, setStep, step, setUser } = React.useContext(AuthWizardContext);
  console.log(user);

  const [isSingleDate, setIsSingleDate] = React.useState(
    Object.hasOwnProperty.call(user, "isSingleDate") ? user.isSingleDate : true
  );
  const [singleDateValue, setSingleDateValue] = React.useState(
    user.singleDateValue || ""
  );

  const handleDateSelection = () => {
    console.log(isSingleDate);
    setIsSingleDate(!isSingleDate);
  };

  const forInputrefNotHS = React.useRef(null);

  const handeInputRefNotHS = () => {
    forInputrefNotHS.current?.setFocus();
  };
  const forInputrefHSS = React.useRef(null);

  const handeInputRefHSS = () => {
    forInputrefHSS.current?.setFocus();
  };
  const forInputrefHSE = React.useRef(null);

  const handeInputRefHSE = () => {
    forInputrefHSE.current?.setFocus();
  };

  const [options, setOptions] = React.useState(user.options);

  // for non hotel style
  const handleDateChangeAndgetTime = (
    date,
    setFieldValue,
    nameOfField = ""
  ) => {
    console.log(date);

    if (nameOfField) {
      setFieldValue(nameOfField, date);
    } else setFieldValue("start_date", date);

    axios
      .post(
        `${hostname}/api/amenity/GetAmenitySlot`,
        {
          amenity_id: selectedAmenity._id,
          Lookupdate: date,
          unit_id: userData.UnitsOwner[0]._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (isObject(res.data)) {
          arrShort.forEach((v) => {
            if (v.day in res.data) {
              console.log(res.data[v.day]);
              setOptions(res.data[v.day]);
            }
          });
        } else {
          console.log(res.data);
          setOptions([res.data]);
          // setFieldValue('time_slot',res.data);
          setSingleDateValue([res.data]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExcludedDates = (
    minLeadTime,
    maxLeadTime,
    unavailableDates = []
  ) => {
    const today = new Date();
    const today2 = new Date();

    let maxLeadTimeUpdated = maxLeadTime - 1; // remove 1 extra day

    let minDate = new Date(today.setDate(today.getDate() + minLeadTime));

    let maxDate = new Date(
      today2.setDate(today2.getDate() + maxLeadTimeUpdated)
    );

    const formattedUnavailableDates = unavailableDates.map(
      (date) => new Date(date)
    );

    if (minLeadTime === 0) {
      // no restrictions
      minDate = new Date();
    }

    if (maxLeadTime === 0) {
      // no restrictions
      maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 100);
    }

    if (minLeadTime === 0 && maxLeadTime === 0) {
      // no restrictions

      minDate = new Date();
      maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 100);
    }

    return { minDate, maxDate, unavailableDates: formattedUnavailableDates };

    // Generate an array of dates outside the valid range
    // const rangeExcludedDates = [];
    // for (let d = new Date(today); d <= maxDate; d.setDate(d.getDate() + 1)) {
    //   if (d < minDate || d > maxDate) {
    //     rangeExcludedDates.push(new Date(d));
    //   }
    // }
  };

  const excludedDates = getExcludedDates(
    leadTimeMapping[selectedAmenity.minbookinglead], // Min lead time in days (e.g., 24 hours = 1 day)
    leadTimeMapping[selectedAmenity.maxbookinglead], // Max lead time in days
    selectedAmenity.unavailabledates || [] // Array of unavailable dates
  );

  return (
    <div className="d-flex justify-content-center">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-4">
        <Formik
          initialValues={{
            start_date: user.start_date || "",
            end_date: user.end_date || "",
            time_slot: user.time_slot || "",
            // days_for_daily: user.days_for_daily || "1",
            max_guest_limit: user.max_guest_limit || "",
            email_remainder: user.email_remainder || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.start_date) {
              errors.start_date = "Date is required!";
            }
            if (selectedAmenity.type === "Hotel Style" && !values.end_date) {
              errors.end_date = "Date is required!";
            }
            // if (
            //   selectedAmenity.type === "Daily" &&
            //   isSingleDate &&
            //   !values.days_for_daily
            // ) {
            //   errors.days_for_daily = "days are required!";
            // }
            if (
              selectedAmenity.type === "Daily" &&
              !isSingleDate &&
              !values.end_date
            ) {
              errors.end_date = "end date is required!";
            }
            if (selectedAmenity.type === "Time Slots" && !values.time_slot) {
              errors.time_slot = "time is required!";
            }
            if (!values.max_guest_limit) {
              errors.max_guest_limit = "Enter Number of Guests!";
            }
            if (
              values.max_guest_limit &&
              +values.max_guest_limit >= +selectedAmenity.max_guest_limit + 1
            ) {
              errors.max_guest_limit = "Maximum Guests Limit Exceededs!";
            }
            if (!values.email_remainder) {
              errors.email_remainder = "Required Option!";
            }
            if (selectedAmenity.type === "Hotel Style") {
              if (values.end_date <= values.start_date) {
                errors.end_date = "invalid selection!";
              }

              if (
                (values.end_date - values.start_date) / 8.64e7 >
                selectedAmenity.max_stay_length
              ) {
                errors.end_date = "maximum stay length limit exceeds!";
              }
            }
            console.log(errors);
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            setUser({
              ...values,
              time_slot:
                selectedAmenity.type === "Daily"
                  ? selectedAmenity.checkin_time +
                    " " +
                    selectedAmenity.checkout_time
                  : values.time_slot,
              options: options.length ? options : singleDateValue,
              isSingleDate,
            });
            setStep(step + 1);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              {selectedAmenity.type === "Hotel Style" && (
                <>
                  <Row className="mb-4">
                    <Col md={6}>
                      <BSForm.Group className="mb-3">
                        <BSForm.Label>Check-In Date</BSForm.Label>
                        {/* <Field type="date" name="start_date" className="form-control" /> */}
                        <div className=" position-relative">
                          <DatePicker
                            ref={forInputrefHSS}
                            // showIcon
                            placeholderText="check in Date here"
                            selected={values.start_date}
                            onChange={(date) =>
                              handleDateChangeAndgetTime(
                                date,
                                setFieldValue,
                                "start_date"
                              )
                            }
                            className="form-control"
                            minDate={excludedDates.minDate}
                            maxDate={excludedDates.maxDate}
                            excludeDates={excludedDates.unavailableDates}
                          />
                          <span
                            className="position-absolute translate-middle top-50 end-0"
                            onClick={handeInputRefHSS}
                          >
                            <CalendarToday fontSize="small" />
                          </span>
                        </div>

                        <ErrorMessage
                          className="text-danger fs--1"
                          name="start_date"
                          component="div"
                        />
                      </BSForm.Group>
                    </Col>
                    <Col md={6}>
                      <BSForm.Group>
                        <BSForm.Label>Check-Out Date</BSForm.Label>
                        {/* <Field type="date" name="start_date" className="form-control" /> */}
                        <div className=" position-relative">
                          <DatePicker
                            ref={forInputrefHSE}
                            placeholderText="check out Date here"
                            // showIcon
                            selected={values.end_date}
                            onChange={(date) =>
                              handleDateChangeAndgetTime(
                                date,
                                setFieldValue,
                                "end_date"
                              )
                            }
                            className="form-control"
                            minDate={excludedDates.minDate}
                            maxDate={excludedDates.maxDate}
                            excludeDates={excludedDates.unavailableDates}
                          />
                          <span
                            className="position-absolute translate-middle top-50 end-0"
                            onClick={handeInputRefHSE}
                          >
                            <CalendarToday fontSize="small" />
                          </span>
                        </div>

                        <ErrorMessage
                          className="text-danger fs--1"
                          name="end_date"
                          component="div"
                        />
                      </BSForm.Group>
                    </Col>
                    <div>
                      <p className="text-danger fs--1">
                        {values.end_date &&
                          values.start_date &&
                          values.end_date <= values.start_date &&
                          "invalid date seletion for Check-Out Date!"}
                      </p>
                    </div>
                  </Row>

                  <BSForm.Group className="mb-3 mt-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p>Check-In Time</p>
                        <p>Check-Out Time</p>
                      </div>
                      <div>
                        <p className="fw-bold">
                          {selectedAmenity.checkin_time}
                        </p>
                        <p className="fw-bold">
                          {selectedAmenity.checkout_time}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between ">
                      <p> Max Stay Limit</p>
                      <p className="fw-bold">
                        {/* {user.time_slot || options || 'Select to view'} */}
                        {selectedAmenity.max_stay_length} Night(s)
                      </p>
                    </div>
                    {/* <Field
                      className="form-select"
                      component="select"
                      id="time_slot"
                      name="time_slot"
                      // multiple={true}
                    >
                      <option value="">Select Time</option>
                      {options?.map(v => (
                        <option value={v} key={v}>
                          {v}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="text-danger fs--1"
                      name="time_slot"
                      component="div"
                    />
                    {!values.start_date && (
                      <div className="text-info fs--1">
                        Please select date to view available Time
                      </div>
                    )} */}
                  </BSForm.Group>
                </>
              )}

              {selectedAmenity.type === "Time Slots" && (
                <>
                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Select Date</BSForm.Label>
                    {/* <Field type="date" name="start_date" className="form-control" /> */}

                    <div className="position-relative">
                      <DatePicker
                        ref={forInputrefNotHS}
                        placeholderText="click to select"
                        selected={values.start_date}
                        onChange={(date) =>
                          handleDateChangeAndgetTime(date, setFieldValue)
                        }
                        className="form-control"
                        minDate={excludedDates.minDate}
                        maxDate={excludedDates.maxDate}
                        excludeDates={excludedDates.unavailableDates}
                        // style={{padding : '50px', backgroundColor : 'red'}}
                      />
                      <span
                        className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                        onClick={handeInputRefNotHS}
                      >
                        <CalendarToday fontSize="small" />
                      </span>
                    </div>
                    <ErrorMessage
                      className="text-danger fs--1"
                      name="start_date"
                      component="div"
                    />
                  </BSForm.Group>

                  <BSForm.Group className="mb-3">
                    <BSForm.Label>Select Time</BSForm.Label>
                    <Field
                      className="form-select"
                      component="select"
                      id="time_slot"
                      name="time_slot"
                      // multiple={true}
                    >
                      <option value="">Select Time</option>
                      {options?.map((v) => (
                        <option value={v} key={v}>
                          {v}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="text-danger fs--1"
                      name="time_slot"
                      component="div"
                    />
                    {!values.start_date && (
                      <div className="text-info fs--1">
                        Please select date to view available Time
                      </div>
                    )}
                  </BSForm.Group>
                </>
              )}

              {selectedAmenity.type === "Daily" && (
                <>
                  <div className="d-flex justify-content-end align-items-end">
                    <span>{isSingleDate ? "One Day" : "Multiple Days"}</span>
                    <BSForm.Check
                      type="switch"
                      id="custom-switch"
                      // label={isSingleDate ? "Single Date" : "Multiple Dates"}
                      checked={!isSingleDate}
                      onChange={handleDateSelection}
                      className="ms-3"
                    />
                  </div>
                  {isSingleDate && (
                    <>
                      <BSForm.Group className="mb-4">
                        <BSForm.Label>Select Date</BSForm.Label>
                        {/* <Field type="date" name="start_date" className="form-control" /> */}

                        <div className="position-relative">
                          <DatePicker
                            ref={forInputrefNotHS}
                            placeholderText="click to select"
                            selected={values.start_date}
                            onChange={(date) =>
                              handleDateChangeAndgetTime(date, setFieldValue)
                            }
                            className="form-control"
                            minDate={excludedDates.minDate}
                            maxDate={excludedDates.maxDate}
                            excludeDates={excludedDates.unavailableDates}
                          />
                          <span
                            className="position-absolute translate-middle top-50 end-0 cursor-pointer"
                            onClick={handeInputRefNotHS}
                          >
                            <CalendarToday fontSize="small" />
                          </span>
                        </div>
                        <ErrorMessage
                          className="text-danger fs--1"
                          name="start_date"
                          component="div"
                        />
                      </BSForm.Group>
                      {/* <BSForm.Group className="mb-3 d-flex justify-content-between">
                        <BSForm.Label>Select Days for booking</BSForm.Label>

                        <Field
                          className="form-control"
                          type="text"
                          name="days_for_daily"
                          style={{width :'100px'}}
                        />

                        <ErrorMessage
                          className="text-danger fs--1"
                          name="days_for_daily"
                          component="div"
                        />
                      </BSForm.Group> */}
                    </>
                  )}
                  {!isSingleDate && (
                    <Row className="">
                      <Col md={6}>
                        <BSForm.Group className="mb-3">
                          <BSForm.Label>Start Date</BSForm.Label>
                          {/* <Field type="date" name="start_date" className="form-control" /> */}
                          <div className=" position-relative">
                            <DatePicker
                              ref={forInputrefHSS}
                              // showIcon
                              placeholderText="start Date here"
                              selected={values.start_date}
                              onChange={(date) =>
                                handleDateChangeAndgetTime(
                                  date,
                                  setFieldValue,
                                  "start_date"
                                )
                              }
                              className="form-control"
                              minDate={excludedDates.minDate}
                              maxDate={excludedDates.maxDate}
                              excludeDates={excludedDates.unavailableDates}
                            />
                            <span
                              className="position-absolute translate-middle top-50 end-0"
                              onClick={handeInputRefHSS}
                            >
                              <CalendarToday fontSize="small" />
                            </span>
                          </div>

                          <ErrorMessage
                            className="text-danger fs--1"
                            name="start_date"
                            component="div"
                          />
                        </BSForm.Group>
                      </Col>
                      <Col md={6}>
                        <BSForm.Group>
                          <BSForm.Label>End Date</BSForm.Label>
                          {/* <Field type="date" name="start_date" className="form-control" /> */}
                          <div className=" position-relative">
                            <DatePicker
                              ref={forInputrefHSE}
                              placeholderText="end Date here"
                              // showIcon
                              selected={values.end_date}
                              onChange={(date) =>
                                handleDateChangeAndgetTime(
                                  date,
                                  setFieldValue,
                                  "end_date"
                                )
                              }
                              minDate={excludedDates.minDate}
                              maxDate={excludedDates.maxDate}
                              excludeDates={excludedDates.unavailableDates}
                            />
                            <span
                              className="position-absolute translate-middle top-50 end-0"
                              onClick={handeInputRefHSE}
                            >
                              <CalendarToday fontSize="small" />
                            </span>
                          </div>

                          <ErrorMessage
                            className="text-danger fs--1"
                            name="end_date"
                            component="div"
                          />
                        </BSForm.Group>
                      </Col>
                      <div>
                        <p className="text-danger fs--1">
                          {values.end_date &&
                            values.start_date &&
                            values.end_date <= values.start_date &&
                            "invalid date seletion for ending Date!"}
                        </p>
                      </div>
                    </Row>
                  )}
                  <BSForm.Group className="">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p>Start Time</p>
                        <p>End Time</p>
                      </div>
                      <div>
                        <p className="fw-bold">
                          {selectedAmenity.checkin_time}
                        </p>
                        <p className="fw-bold">
                          {selectedAmenity.checkout_time}
                        </p>
                      </div>
                    </div>
                  </BSForm.Group>
                  <BSForm.Group className="mb-3 d-flex justify-content-between border-bottom-2">
                    {/* {!values.start_date && (
                      <div className="text-info fs--1">
                        Please select date to view available Time
                      </div>
                    )} */}

                    {/* <BSForm.Label className={!values.start_date && "d-none"}>
                      Time
                    </BSForm.Label>
                    <BSForm.Label className="fw-bold">
                      {options?.map((v, i) => (
                        <span key={v + i}>{v}</span>
                      ))}
                    </BSForm.Label> */}

                    {/* <Field
                      className="form-select"
                      component="select"
                      id="time_slot"
                      name="time_slot"
                      // multiple={true}
                    >
                      <option value="">Select Time</option>
                      {options?.map((v) => (
                        <option value={v} key={v}>
                          {v}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="text-danger fs--1"
                      name="time_slot"
                      component="div"
                    />*/}
                  </BSForm.Group>
                </>
              )}

              <BSForm.Group className="mb-3">
                <BSForm.Label>
                  No. of Guests (Maximum Limit :{" "}
                  {selectedAmenity.max_guest_limit || "0"})
                </BSForm.Label>
                <Field
                  name="max_guest_limit"
                  className="form-control"
                  max={selectedAmenity.max_guest_limit}
                />
                <ErrorMessage
                  name="max_guest_limit"
                  component={"div"}
                  className="text-danger fs--1"
                />
              </BSForm.Group>

              <BSForm.Group className="mb-3 mt-3">
                <label>Email Reminder</label>
                <label className="mx-3">
                  Yes{" "}
                  <Field
                    type="radio"
                    name="email_remainder"
                    value="Yes"
                    className="form-check-input ms-1"
                  />
                </label>
                <label className="mx-3">
                  {" "}
                  No{" "}
                  <Field
                    type="radio"
                    name="email_remainder"
                    value="No"
                    className="form-check-input ms-1"
                  />
                </label>
                <ErrorMessage
                  name="email_remainder"
                  component={"div"}
                  className="text-danger fs--1"
                />
              </BSForm.Group>

              {/* <div className="mb-3">
                <p>
                  Note: The cancellation period for this Amenity is{" "}
                  <span className="text-info">
                    {selectedAmenity.allow_cancel_lead || "Time here"}
                  </span>
                </p>
              </div> */}

              {/*  */}
              <div className="text-end">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

// const getExcludedDays = (days = []) => {
//     console.log(days);
//     days = days?.map((v) => {
//       let obj = new Date(v);
//       console.log(obj);
//       if (
//         selectedAmenity.type === "Hotel Style" ||
//         selectedAmenity.type === "Daily"
//       )
//         return new Date(
//           obj.getFullYear(),
//           obj.getMonth(),
//           obj.getDate() + 1,
//           0,
//           0,
//           0,
//           0
//         );
//       else
//         return new Date(
//           obj.getFullYear(),
//           obj.getMonth(),
//           obj.getDate() + 1,
//           0,
//           0,
//           0,
//           0
//         );
//     });
//     // days = days?.map((v) => v.toISO1String());
//     console.log(days);
//     return days;
//   };
